import { Input, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../axios/AxiosInstance";
const Activate = () => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const { token } = useParams();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState(null);

  const checkToken = () => {
    AxiosInstance.post("auth/check-token", { token })
      .then((res) => {
        setIsTokenValid(true);
        console.log(res.data);
        setUser(res.data);
      })
      .catch((e) => console.log(e));
  };

  const activatePassword = () => {
    if (password.length < 8) {
      message.error("Le mot de passe doit faire au moins 8 caractères !");
      return;
    }

    if (password !== confirmPassword) {
      message.error("Mot de passe differents ! ");
      return;
    }
    AxiosInstance.post("auth/set-password", {
      token,
      password,
      user: user.user,
    })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("isLogged", true);
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("refreshToken", res.data.refresh_token);
        localStorage.setItem("userEmail", res.data.email);
        localStorage.setItem("userRole", res.data.role);
        localStorage.setItem("name", res.data.name);
        setIsLoggedIn(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    checkToken();
  }, [token]);

  return (
    <div>
      {isTokenValid && (
        <div className="h-screen flex flex-col space-y-4 items-center justify-center">
          <p className="text-2xl mb-10">
            Activez votre compte en créant votre mot de passe
          </p>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-md border  w-2/5     border-gray-300 mb-2"
            type="password"
            placeholder="Mot de passe"
          />
          <Input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="rounded-md border  w-2/5    border-gray-300 mb-2"
            type="password"
            placeholder="Confirmer mot de passe"
          />
          <button
            onClick={activatePassword}
            className="bg-mountain-500   w-2/5  p-2 text-white font-semibold text-lg rounded-md"
          >
            creer mot de passe
          </button>
        </div>
      )}

      {!isTokenValid && (
        <div className="h-screen w-full flex items-center justify-center">
          <p className="text-2xl text-red-500">Le lien est invalide</p>
        </div>
      )}
    </div>
  );
};

export default Activate;
