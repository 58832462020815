import { useContext, useEffect, useState, useRef } from "react";
import LeftMenu from "../components/Menu/Menu";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Task from "../components/Task/Task";
import Client from "../components/Client/Client";
import Facture from "../components/Facture/Facture";
import Commandes from "../components/Commande/Commande";
import Candidate from "../components/Candidate/Candidate";
import MyAccount from "../components/MyAccount/MyAccount";
import Bibliotheque from "../components/Bibliotheque/Bibliotheque";
import Annonce from "../components/Annonce/Annonce";

const HomePage = () => {
  const [username, setUsername] = useState("");
  const [section, setSection] = useState("Dashboard");
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [isDashboard, setIsDashboard] = useState(true);
  const [selected, setSelected] = useState("Dashboard");

  const toggle = (section) => {
    setSection(section);
    localStorage.setItem("section", section);
  };

  const toggleDashboard = (i) => {
    setIsDashboard(false);
    setTimeout(() => {
      setIsDashboard(true);
    }, 3000);
    //setIsDashboard(true);
  };

  useEffect(() => {
    const username = localStorage.getItem("name");
    const section = localStorage.getItem("section");
    if (section) setSection(section);
    setUsername(username);
    setLoadedComponent(true);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen">
      {loadedComponent && (
        <div className="flex w-full pb-28 font-sf-pro">
          <LeftMenu
            toggle={toggle}
            toggleDashboard={toggleDashboard}
            selected={selected}
            setSelected={setSelected}
          />

          <div className="mt-20  grow ml-[300px]">
            {section === "Dashboard" && (
              <div className="px-6">
                <Dashboard
                  toggle={toggle}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            )}
            {section === "Task" && (
              <div className="px-6">
                <Task />
              </div>
            )}


{section === "annonce" && (
              <div className="px-6">
                <Annonce />
              </div>
            )}

            {section === "Commande" && (
              <div className="px-6">
                <Commandes />
              </div>
            )}

            {section === "bibliotheque" && (
              <div className="px-6">
                <Bibliotheque />
              </div>
            )}

            {section === "MyAccount" && (
              <div className="px-6">
                <MyAccount />
              </div>
            )}

            {section === "Client" && (
              <div className="px-6">
                <Client />
              </div>
            )}

            {section === "Candidate" && (
              <div className="px-6">
                <Candidate />
              </div>
            )}

            {section === "Utilisateur" && (
              <div className="px-6">
                <p>Annonces</p>
              </div>
            )}

            {section === "Facture" && (
              <div className="px-6">
                <Facture />
              </div>
            )}

            {section === "Bibliothèque" && (
              <div className="px-6">
                <p>Bibliothèque</p>
              </div>
            )}

            {section === "Switcher" && (
              <div className="px-6">
                <p>Switcher agence</p>
              </div>
            )}

            {section === "Mon compte" && (
              <div className="px-6">
                <p>Mon compte</p>
              </div>
            )}

            {section === "Deconnexion" && (
              <div className="px-6">
                <p>Deconnexion</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
