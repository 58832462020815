import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const CreateContractTask = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold text-center text-3xl">
          Quelles sont les tâches/missions ?
        </p>

        <div className="flex items-center my-6 space-x-10 justify-center">
          <TextArea
            showCount
            className="mb-4 h-24 "
            onChange={(e) => setContract({ ...contract, task: e.target.value })}
            value={contract.task ? contract.task : null}
            placeholder="Preciser..."
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContractTask;
