import { useEffect, useState } from "react";
import axios from "../../axios/AxiosInstance";
import { message } from "antd";

const CandidatContract = ({ user, isOpen, setIsOpen }) => {
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    loadContract();
  }, []);

  const formatJsDate = (inputDate) => {
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);

    return `${day}/${month}/${year}`;
  };

  const loadContract = () => {
    if (user.pld) {
      axios
        .get(`pld-contract/candidate-tempo/${user.pld}`)
        .then((res) => {
          console.log(res.data);

          setContracts(res.data.reverse());
        })
        .catch((e) => console.log(e));
    } else {
      message.error("Le candidat n'a pas de pld");
    }
  };

  return (
    <div className="mt-10">
      <div className="mx-6 mt-6 w-full">
        <div className="flex items-center space-x-1 bg-gray-600 p-3 text-white  rounded-t-md font-semibold uppercase text-sm mb-2">
          <p className="w-[10%] text-center">Contrat</p>
          <p className="w-[10%] text-center">Avenant</p>
          <p className="w-[10%] text-center">Date</p>
          <p className="w-[10%] text-center">Fin de mission</p>
          <p className="w-[10%] text-center">Client</p>
          <p className="w-[10%] text-center">Poste</p>
          <p className="w-[10%] text-center">Taux payé</p>
          <p className="w-[10%] text-center">Coeff</p>
          <p className="w-[10%] text-center">Taux Facturé</p>
          <p className="w-[10%] text-center">Statut</p>
        </div>
      </div>

      <div className=" mx-6 w-full flex flex-col space-y-1">
        {contracts.map((contract, idx) => {
          return (
            <div
              key={idx}
              className="flex border py-2 border-gray-300 rounded-md shadow-lg  items-center text-xs space-x-1"
            >
              <p className="w-[10%] text-center">{contract.NumeroContrat}</p>
              <p className="w-[10%] text-center">{contract.NumeroAvenant}</p>

              <div className="w-[10%] flex flex-col items-center justify-center">
                <p className="text-center">
                  Du {formatJsDate(contract.DateDebut)}
                </p>
                <p className="text-center">
                  Au {formatJsDate(contract.DateFin)}
                </p>
              </div>
              <p className="w-[10%] text-center">
                {contract.DateFinMission.length > 0 &&
                  formatJsDate(contract.DateFinMission)}
              </p>
              <p className="w-[10%] text-center">{contract.RaisonSociale}</p>
              <p className="w-[10%] text-center">{contract.TxtQualification}</p>
              <p className="w-[10%] text-center">{contract.TauxHorairePaye}</p>
              <p className="w-[10%] text-center">{contract.CoeffHeures}</p>
              <p className="w-[10%] text-center">
                {contract.TauxHoraireFacture}
              </p>
              {contract.EstNonLieu === "O" && (
                <div className="w-[10%] flex flex-col ">Non Lieu</div>
              )}
              {contract.EstNonDemarre === "O" && (
                <p className="w-[10%] text-center">Non Demarré</p>
              )}
              {contract.EstMissionPlante === "O" && (
                <p className="w-[10%] text-center">Mission Planté</p>
              )}
              {contract.EstMissionPlante !== "O" &&
                contract.EstNonLieu !== "O" &&
                contract.EstNonDemarre !== "O" && (
                  <p className="w-[10%] text-center"></p>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CandidatContract;
