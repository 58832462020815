import { Drawer, TreeSelect } from "antd";
import { useState } from "react";
import AxiosInstance from "../../axios/AxiosInstance";

const FilterOrder = ({ open, setIsOpen, setOrders, originalOrders }) => {
  const [statusFilter, setStatusFilter] = useState([]);

  const resetOrders = () => {
    setOrders(originalOrders);
    setStatusFilter([]);
  };

  const getFilteredOrders = () => {
    AxiosInstance.get("order/filter", {
      params: {
        status: statusFilter.join(","),
      },
    })
      .then((res) => {
        console.log(res.data);
        setOrders(res.data);
        setStatusFilter([]);
      })
      .catch((e) => console.log(e));
  };

  const statuses = [
    "Annulée",
    "Contrat à faire",
    "Candidat(s) contacté(s)",
    "Candidat à contacter",
    "Aucun candidat",
  ];

  const onChangeStatus = (newValue) => {
    console.log(newValue);
    setStatusFilter(newValue);
  };

  const statusData = statuses.map((status) => {
    return {
      title: status,
      value: status,
      key: status,
    };
  });

  const statusProps = {
    treeData: statusData,
    onChange: onChangeStatus,
    value: statusFilter,
    treeCheckable: true,
    placeholder: "statut",
  };

  return (
    <Drawer
      title="Filtrer Commande"
      open={open}
      onClose={() => setIsOpen(false)}
    >
      <div className=" flex h-full flex-col">
        <div className="grow flex flex-col justify-between">
          <div className="flex items-center flex-col hover:cursor-pointer px-10">
            <TreeSelect
              className="w-full rounded-full"
              size="large"
              {...statusProps}
            />

            <div>
              <p className="flex items-center space-x-2 justify-center mt-6">
                <button
                  className="text-black rounded-md p-2 m-2 text-xs  bg-purple-400"
                  onClick={getFilteredOrders}
                >
                  VALIDER
                </button>
                <button
                  className="text-white rounded-md p-2 m-2 text-xs bg-black"
                  onClick={resetOrders}
                >
                  REINITIALISER
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterOrder;
