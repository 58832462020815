import { Icon } from "@iconify/react";

export default function TextField({
  name,
  aria,
  type,
  onChange,
  placeholder,
  icon,
  width,
  icon_psw,
  onClick,
}) {
  return (
    <div className="flex justify-start items-center relative my-6">
      <input
        tabIndex={0}
        name={name}
        arial-label={aria}
        type={type}
        className="w-full text-base leading-none text-gray-600 font-light py-3 pl-10 focus:oultine-none bg-gray-100 border-0 rounded-md border-gray-200 placeholder-gray-300"
        placeholder={placeholder}
        onChange={onChange}
      />
      <Icon icon={icon} className="absolute w-10 text-blue-500" width={width} />
      {name === "password" ? (
        <Icon
          onClick={onClick}
          icon={icon_psw}
          className="cursor-pointer absolute right-1 w-10 text-gray-400 hover:text-gray-600"
          width={20}
        />
      ) : (
        " "
      )}
    </div>
  );
}
