import heroimg from "../assets/img/hero-img.svg";
import reseau from "../assets/img/reseau.gif";
import { useNavigate } from "react-router-dom";
import axios from "../axios/AxiosInstance";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import TextField from "../reusableComposant/TextField";
import { Input, message } from "antd";
import Lottie from "react-lottie";
import animation from "../assets/img/login-animation.json";
import { Modal } from "antd";
const LoginPage = () => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [code, setCode] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged) {
      navigate("/");
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    console.log("login");
    axios
      .post("/auth/freelance/login", { username: email, password })
      .then((res) => {
        localStorage.setItem("isLogged", true);
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("agencyName", res.data.agencyName);
        localStorage.setItem("refreshToken", res.data.refresh_token);
        localStorage.setItem("userEmail", res.data.username);
        localStorage.setItem("userRole", res.data.role);
        localStorage.setItem("userId", res.data._id);
        localStorage.setItem("firstname", res.data.firstname);
        localStorage.setItem("lastname", res.data.lastname);
        console.log(res.data);
        setIsLoggedIn(true);
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
        message.error("username ou  mot de passe erroné");
      });
  };

  return (
    <section className="">
      <div className="h-screen">
        <div className="h-screen flex flex-wrap items-center justify-center">
          {/* <!-- Left column container--> */}
          <div className="h-full w-full bg-white flex lg:w-6/12">
            <div className="border-2 rounded-lg shadow-xl  bg-blue-100 px-4 py-6 m-auto mx-4 w-full xl:mx-8 3xl:m-auto 3xl:w-2/3 text-center text-gray-400 md:p-12">
              <h4 className="mb-6 text-4xl flex flex-col items-center w-full justify-center text-gray-500 font-semibold">
                <span>
                  J'<span className="text-5xl mr-2">&hearts;</span> INTERIM
                </span>
                <span>FREELANCE</span>
              </h4>
              <div className="w-full mx-auto nav:w-2/3 py-12">
                <form>
                  {/* email input */}
                  <TextField
                    name="email"
                    className="text-blue-500"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-label="Input name"
                    type="email"
                    placeholder="Identifiant"
                    icon="bi:envelope-open"
                    width={14}
                  />

                  {/* password input */}
                  <TextField
                    name="password"
                    className="text-blue-500"
                    onChange={(e) => setPassword(e.target.value)}
                    arial-label="Input password"
                    type={isVisible ? "text" : "password"}
                    onClick={() => setIsVisible(!isVisible)}
                    icon_psw={
                      isVisible ? "mdi:eye-outline" : "mdi:eye-off-outline"
                    }
                    placeholder="Mot de passe"
                    icon={"ri:lock-2-line"}
                    // width={17}
                  />

                  {/* <!--Submit button--> */}
                  <div className="my-12 pb-1 pt-1 text-center">
                    <button
                      onClick={login}
                      className="rounded-full w-full mb-2 text-white hover:text-white bg-blue-500 hover:bg-[#394aa2]  py-3 px-10 text-lg font-medium leading-none"
                    >
                      Connexion
                    </button>
                    {/* <!--Forgot password link--> */}
                    <a href="/mot-de-passe-oublie">Mot de passe oublié ?</a>
                  </div>
                  {/* <!--Login button--> */}
                </form>
              </div>
            </div>
          </div>

          {/* <!-- Right column container with background and description--> */}
          <div className="h-full w-full order-first lg:order-last flex lg:w-6/12">
            <div className="px-4 py-6 w-full 2xl:w-2/3 m-auto text-white md:p-12">
              <h4 className="mb-10 text-4xl text-center font-semibold"></h4>
              <div className="w-full mt-20 flex items-center justify-center">
                <Lottie options={defaultOptions} className="w-2/4 h-2/5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
