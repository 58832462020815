import { Input } from "antd";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { AddOutlined } from "@mui/icons-material";

const Annonce = () => {

    const [isSearch,setIsSearch] = useState(false);


    return (
        <div>
           <div className="mb-10 mt-10 font-Inter flex items-center justify-between">
        <p className="text-2xl font-semibold uppercase">Annonces</p>

        <div className="flex justify-end ml-20">
          <div className="flex items-center space-x-2">
            {isSearch && (
              <Input
                className="w-80  rounded-md border border-gray-200 focus:outline-none"
                placeholder="Recherche"
                style={{ outline: "none" }}
              />
            )}
            <SearchIcon
              className="text-gray-500 text-3xl"
            />
            <FilterListOutlinedIcon
              className="text-gray-500 text-3xl"
            />
            <div
              className="border hover:cursor-pointer bg-purple-400 rounded-md flex items-center"
            >
              <AddOutlined className="hover:cursor-pointer text-black" />
              <p className="text-black p-2">Ajouter une annonce</p>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}

export default Annonce;