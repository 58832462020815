import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InactivityLogout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let inactivityTimeout;

    const resetTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(logout, 150 * 60 * 1000);
    };

    const logout = () => {
      // Clear localStorage
      localStorage.clear();
      // Redirect to login page
      navigate("login");
    };

    // Initial setup
    resetTimeout();

    // Event listeners to reset the timeout on user activity
    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    // Cleanup on component unmount
    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, []);

  return (
    // Your component JSX
    <div>{/* Your component content */}</div>
  );
};

export default InactivityLogout;
