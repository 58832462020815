import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function Index() {
  const loginStatus = localStorage.getItem("isLogged");

  const [isLoggedIn, setIsLoggedIn] = useState(loginStatus);

  return (
    <React.StrictMode>
      <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </AuthContext.Provider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(<Index />);
