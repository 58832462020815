import { Input, message } from "antd";
import { useContext, useState, useEffect } from "react";
import axios from "../../axios/AxiosInstance";
import settings from "../../assets/img/settings-01.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import infoImg from '../../assets/img/infoImg.png'
import passwordImg from '../../assets/img/passwordImg.png'
import logoutImg from '../../assets/img/logoutImg.png'

const MyAccount = () => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const [section, setSection] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const firstname = localStorage.getItem("firstname");
    const lastname = localStorage.getItem("lastname");
    setEmail(email);
    setFirstname(firstname);
    setLastname(lastname);
  }, []);
  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("isLogged");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userRole");
    navigate("/login");
  };

  const updatePassword = () => {
    if (newPassword !== confirmPassword) {
      message.error("Les mots de passe ne correspondent pas !");
      return;
    }
    axios
      .post("user/update-password", { password, newPassword })
      .then((res) => {
        message.success("Mot de passe mis à jour !");
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((e) => message.error("Mot de passe eronné"));
  };

  return (
    <div>
      <div className="my-20">
        <p className="font-semibold text-3xl">Mon compte</p>
      </div>


      <div className="mx-8 mb-6 grow  mt-6 ">
        <div className="flex grow items-start space-x-4">
          <div
            onClick={() => setSection("infos")}
            className="w-1/3 hover:cursor-pointer  bg-white py-6 px-4 flex flex-col space-y-6 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
            <img
                src={infoImg}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Mes informations</p>
            </div>
          </div>

          <div
            onClick={() => setSection("password")}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
            <img
                src={passwordImg}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Changer mot de passe</p>
            </div>
            
          </div>

          <div
           onClick={()=> logout()}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
            <img
                src={logoutImg}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Déconnexion</p>
            </div>
          </div>
        </div>
      </div>


     {/*  <div className="mt-10 flex flex-col space-y-3">
        <p className="hover:cursor-pointer" onClick={() => setSection("infos")}>
          Mes informations
        </p>
        <p
          className="hover:cursor-pointer"
          onClick={() => setSection("password")}
        >
          Changer mot de passe
        </p>

        <p
          onClick={() => {
            logout();
          }}
          className={`hover:cursor-pointer`}
        >
          <span>Deconnexion</span>
        </p>
      </div> */}

      {section === "infos" && (
        <div className="flex flex-col mx-8  items-start justify-start space-y-5">
          <div className="flex flex-col space-y-2">
            <p className="flex items-center space-x-1">
              <span className="font-semibold">Email :</span>
              <span>{email}</span>
            </p>
            <p className="flex items-center space-x-1">
              <span className="font-semibold">Prenom :</span>
              <span>{firstname}</span>
            </p>
            <p className="flex items-center space-x-1">
              <span className="font-semibold">Nom :</span>
              <span>{lastname}</span>
            </p>
          </div>
        </div>
      )}
      {section === "password" && (
        <div className="flex flex-col  items-center justify-center space-y-5">
          <Input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe actuel"
            className="w-96 rounded-md outline-none border border-gray-300"
          />
          <Input
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Nouveau mot de passe"
            className="w-96 rounded-md outline-none border border-gray-300"
          />
          <Input
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmer nouveau mot de passe"
            className="w-96 rounded-md outline-none border border-gray-300"
          />
          <button
            onClick={() => updatePassword()}
            className="w-96 bg-purple-500 p-2 text-white rounded-md"
          >
            Changer le mot de passe
          </button>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
