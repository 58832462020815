import { Input } from "antd";

const CreateContractPeriodeEssai = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold mt-6 text-center text-3xl">
          Periode d'essai
        </p>

        <div className="flex mt-6 flex-col space-y-2">
          <p className="font-semibold text-lg">
            Contrat de 1 mois maximum : 2 jours max
          </p>
          <p className="font-semibold text-lg">
            Contrat de mission de 1 à 2 mois : 3 jours max
          </p>
          <p className="font-semibold text-lg">
            Contrat de mission de plus de 2 mois : 5 jours max
          </p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, NbJourEssai: e.target.value })
            }
            value={contract.NbJourEssai ? contract.NbJourEssai : null}
            placeholder="Periode d'essai"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContractPeriodeEssai;
