import Avatar from "../../assets/img/avatar.jpg";
import bonjour from "../../assets/img/bonjour.png";
import clockRefresh from "../../assets/img/clock-refresh.png";
import devis from "../../assets/img/coins-hand.png";
import contrat from "../../assets/img/edit-04 (1).png";
import commande from "../../assets/img/hourglass-02.png";
import calendar from "../../assets/img/calendar-plus-01.png";
import document from "../../assets/img/file-question-02.png";
import avatar2 from "../../assets/img/avatar2.png";
import { useEffect, useState } from "react";
import briefcase from "../../assets/img/briefcase-01.png";
import client2 from "../../assets/img/client2.png";
import candidat2 from "../../assets/img/candidat2.png";
import commandeImg from "../../assets/img/commande.png";
import annonce from "../../assets/img/annonce.png";
import facture from "../../assets/img/facture2.png";

import axios from "../../axios/AxiosInstance";
const Dashboard = ({ toggle, selected, setSelected }) => {
  const [username, setUsername] = useState("");
  const [userImg, setUserImg] = useState(null);
  const [taskNumber, setTaskNumber] = useState(0);
  const [clientNumber, setClientNumber] = useState(0);
  const [candidateNumber, setCandidateNumber] = useState(0);
  const [commandeNumber, setCommandeNumber] = useState(0);
  const [annonceNumber, setAnnonceNumber] = useState(0);
  const [factureNumber, setFactureNumber] = useState(0);

  const loadFactureNonSoldee = () => {
    axios
      .get("facture/freelance/countNonSoldee")
      .then((res) => {
        setFactureNumber(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadTasksNumber = (date) => {
    axios
      .get(`/task/freelance/find-by-date?date=${date}`)
      .then((res) => {
        setTaskNumber(res.data.length);
      })
      .catch((e) => console.log(e));
  };

  const loadCommandeEncours = () => {
    axios
      .get(`/order/freelance/en-cours`)
      .then((res) => {
        console.log(res.data);
        setCommandeNumber(res.data);
      })
      .catch((e) => console.log(e));
  };

  const loadCandidateNumber = () => {
    axios
      .get(`/tempworker-candidate/freelance/tempworker-count`)
      .then((res) => {
        console.log(res.data);
        setCandidateNumber(res.data);
      })
      .catch((e) => console.log(e));
  };

  const loadClientNumber = () => {
    axios
      .get(`/client/freelance-count`)
      .then((res) => {
        console.log(res.data);
        setClientNumber(res.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const name = localStorage.getItem("firstname");
    const userImg = localStorage.getItem("userImg");
    setUserImg(userImg);
    setUsername(name);

    const date = new Date().toISOString();
    loadTasksNumber(date);
    loadClientNumber();
    loadCommandeEncours();
    loadCandidateNumber();
    loadFactureNonSoldee();
  }, []);
  return (
    <div className="flex items-center  flex-col  justify-center mt-10">
      <img
        src={`${userImg ? userImg : avatar2}`}
        className="w-28 h-28 object-cover mb-4 rounded-full"
      />
      <p className="flex items-center space-x-2">
        <span className="text-3xl mb-4 font-semibold capitalize">
          Bonjour {username}
        </span>
        <img src={bonjour} className="w-10" />
      </p>
      <p className="mb-10">
        Votre tableau de bord pour un accès rapide aux différentes rubriques
      </p>

      <div className="mx-8 mb-6 grow w-full mt-6 ">
        <div className="flex grow items-start space-x-4">
          <div
            onClick={() => {
              toggle("Task");
              setSelected("Task");
            }}
            className="w-1/3 hover:cursor-pointer  bg-white py-6 px-4 flex flex-col space-y-6 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={briefcase}
                className="w-10 rounded-md p-2 border  border-gray-300  bg-white"
              />
              <p className="text-2xl">Gestion des tâches</p>
            </div>
            <p className="text-2xl text-center font-semibold">{taskNumber}</p>
          </div>

          <div
            onClick={() => {
              toggle("Commande");
              setSelected("Commande");
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={commandeImg}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Commandes</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {commandeNumber}
            </p>
          </div>

          <div
            onClick={() => {
              toggle("Client");
              setSelected("Client");
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={client2}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Clients</p>
            </div>
            <p className="text-2xl text-center font-semibold">{clientNumber}</p>
          </div>
        </div>
      </div>

      <div className="mx-8 grow w-full ">
        <div className="flex grow items-start space-x-4">
          <div
            onClick={() => {
              toggle("Candidate");
              setSelected("Candidate");
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4  flex flex-col space-y-2 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={candidat2}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Candidats</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {candidateNumber}
            </p>
          </div>

          <div className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-2 border border-gray-300 shadow-md h-40  p-2 rounded-md">
            <div className="flex items-center space-x-2">
              <img
                src={annonce}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Annonces</p>
            </div>
            <p className="text-2xl text-center font-semibold"> 3</p>
          </div>

          <div
            onClick={() => {
              toggle("Facture");
              setSelected("Facture");
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 h-40 flex flex-col space-y-2  border border-gray-300 shadow-md  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={facture}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Factures impayées</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {factureNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
