import { Select } from "antd";
import qualifications from "../utils/qualifications";
import { useEffect, useState } from "react";

const CreateContractPoste = ({ contract, setContract, qualifications }) => {
  const [isEditTauxHoraire, setIsEditTauxHoraire] = useState(false);
  useEffect(() => {
    setContract({
      ...contract,
      isEditTauxHoraire: false,
      clientQualification: {
        IdQualification: "JDDJJD",
        TxtQualification: "Developpeur FullStack",
      },
    });
  }, []);

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">Poste</p>

        <div className="flex flex-col h-80 items-start my-6 overflow-y-scroll space-y-3">
          {qualifications.map((qualification, idx) => {
            return (
              <div
                className={`${
                  contract?.clientQualification?.IdQualification ===
                    qualification?.IdQualification &&
                  contract?.clientQualification?.Niveau ===
                    qualification?.Niveau
                    ? "bg-purple-200 text-purple-800"
                    : ""
                } p-2 w-full `}
                onClick={() =>
                  setContract({
                    ...contract,
                    clientQualification: qualification,
                  })
                }
                key={idx}
              >
                <p className="font-semibold">{qualification.IdQualification}</p>
                <p>{qualification.TxtQualification}</p>
                <p className="">Coefficient : {qualification.CoeffHeures}</p>
                {qualification.Niveau !== "" && (
                  <p>Niveau {qualification.Niveau}</p>
                )}
                {qualification.Niveau === "" && <p>Niveau N/C</p>}
                <p>{qualification.PCS}</p>
                <p>{qualification.TauxPaye}&euro;/h</p>
              </div>
            );
          })}
        </div>
        <p className="text-center">
          <button
            onClick={() => {
              setIsEditTauxHoraire(!isEditTauxHoraire);
              setContract({
                ...contract,
                isEditTauxHoraire: !isEditTauxHoraire,
              });
            }}
            className="bg-purple-500 text-white rounded-md p-2"
          >
            Editer le taux horaire
          </button>
        </p>

        {isEditTauxHoraire && (
          <div className="mt-6 flex items-center space-x-2 justify-center">
            <input
              onChange={(e) => {
                setContract({ ...contract, coefficient: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2 outline-none"
              placeholder="Coefficient"
            />
            <input
              onChange={(e) => {
                setContract({ ...contract, tauxHoraire: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2 outline-none"
              placeholder="Taux horaire"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateContractPoste;
