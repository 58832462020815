import {
  AppstoreOutlined,
  UserOutlined,
  SettingOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import logo from "../../assets/img/logo.png";
import { AuthContext } from "../../context/AuthContext";
import { Menu, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import dashboard from "../../assets/img/dashboard.png";
import star from "../../assets/img/star.png";
import axios from "../../axios/AxiosInstance";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import home from "../../assets/img/home-line.png";
import briefcase from "../../assets/img/briefcase-01.png";
import client2 from "../../assets/img/client2.png";
import candidat2 from "../../assets/img/candidat2.png";
import commande from "../../assets/img/commande.png";
import annonce from "../../assets/img/annonce.png";
import facture from "../../assets/img/facture2.png";
import switchImg from "../../assets/img/switch.png";
import users from "../../assets/img/user-01.png";
import folder from "../../assets/img/folder.png";
import file from "../../assets/img/file-05.png";
import clock from "../../assets/img/clock-check.png";
import edit from "../../assets/img/edit-04.png";
import AxiosInstance from "../../axios/AxiosInstance";
import SubMenu from "antd/es/menu/SubMenu";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

const LeftMenu = ({ toggle, toggleDashboard, selected, setSelected }) => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isReloaded, setIsReloaded] = useState(["a"]);
  const [agencyName, setAgencyName] = useState("");
  const [openedGestion, setOpenedGestion] = useState(false);

  useEffect(() => {
    const section = localStorage.getItem("section");
    setAgencyName(agencyName);
    console.log(section);
    if (section) setSelected(section);
  }, []);

  const switchAgency = (agencyName, agencyId) => {
    AxiosInstance.post("agency/switch", { agency: agencyId })
      .then((res) => {
        setAgencyName(agencyName);
        click("Dashboard");
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          message.error("Vous n'etes pas autorisé à changer d'agence !");
        }
      });
  };

  const click = (item) => {
    toggle(item);
    setSelected(item);
    console.log(item);
  };

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    };
  }
  const items = [
    getItem("Commande", "commande", <DocumentScannerOutlined />, click),
    getItem(
      "Signatures des contrats",
      "signature",
      <DocumentScannerOutlined />,
      click,
    ),
    getItem(
      "Prolongations des contracts",
      "prolongation",
      <DocumentScannerOutlined />,
      click,
    ),
    getItem("Saisie des heures", "saisie", <DocumentScannerOutlined />, click),
    getItem(
      "Proposition de candidats",
      "proposition",
      <DocumentScannerOutlined />,
      click,
    ),
    getItem("Documents", "documents", <DocumentScannerOutlined />, click),

    ,
  ];

  return (
    <>
      {/* <Spin spinning={true} fullscreen indicator={<LoadingOutlined />} size='large' /> */}
      <div className="text-base bg-[#f9fafb] border-r border-r-gray-300  flex flex-col  overflow-y-auto text-black fixed top-0 left-0 h-full">
        <img className="w-24 mx-auto mt-8 mb-8" src={logo} />
        <p className="text-center -mt-6 mb-6 text-lg italic">{agencyName}</p>

        {/*  <Menu
            className="grow pb-10 text-lg"
            style={{
              width: 256,
              backgroundColor: '#F7F9FC',
            }}
            mode="inline"
            defaultOpenKeys={['sub3']}
          >
            <SubMenu>
              <p>ooo</p>
              <p>ooo</p>
              <p>ooo</p>
              <p>ooo</p>
            </SubMenu>
            </Menu>  */}
        <div className="w-[256px] flex flex-col items-center font-medium justify-center space-y-6">
          <p
            onClick={() => {
              click("Dashboard");
            }}
            className={`${
              selected === "Dashboard" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={home} className="w-5" />
            <span>Tableau de bord</span>
          </p>

          <p
            onClick={() => {
              click("Task");
            }}
            className={`${
              selected === "Task" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={briefcase} className="w-5" />
            <span>Gestion des tâches</span>
          </p>
          <p
            onClick={() => {
              click("Commande");
            }}
            className={`${
              selected === "Commande" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={commande} className="w-6" />
            <span>Commandes</span>
          </p>

          <p
            onClick={() => {
              click("Client");
            }}
            className={`${
              selected === "Client" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={client2} className="w-6" />
            <span>Clients</span>
          </p>

          <p
            onClick={() => {
              click("Candidate");
            }}
            className={`${
              selected === "Candidate" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={candidat2} className="w-6" />
            <span>Candidats</span>
          </p>

          <p
            onClick={() => {
              click("annonce");
            }}
            className={`${
              selected === "annonce" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={annonce} className="w-6" />
            <span>Annonces (En cours de developpement )</span>
          </p>
          <p
            onClick={() => {
              click("Facture");
            }}
            className={`${
              selected === "Facture" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={facture} className="w-6" />
            <span>Factures</span>
          </p>
          <p
            onClick={() => {
              click("bibliotheque");
            }}
            className={`${
              selected === "bibliotheque" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={file} className="w-5" />
            <span>Bibliothèque</span>
          </p>
          <p
            onClick={() => {
              click("switch");
            }}
            className={`${
              selected === "switch" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={switchImg} className="w-6" />
            <span>Switcher agence</span>
          </p>
          {selected === "switch" && (
            <div className="flex flex-col space-y-2">
              <p
                onClick={() =>
                  switchAgency("Asnieres", "650adee7951dd697c7535e3e")
                }
                className="hover:cursor-pointer"
              >
                Asnieres sur seine
              </p>
              <p
                onClick={() =>
                  switchAgency("Brive", "6533fe25099978decf532a59")
                }
                className="hover:cursor-pointer"
              >
                Brive
              </p>
              <p
                onClick={() =>
                  switchAgency("Toulouse", "654a3d82874a059df3f98653")
                }
                className="hover:cursor-pointer"
              >
                Toulouse
              </p>
            </div>
          )}
          <p
            onClick={() => {
              click("MyAccount");
            }}
            className={`${
              selected === "MyAccount" ? "bg-[#EBE9FE]" : ""
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={users} className="w-5" />
            <span>Mon compte</span>
          </p>
        </div>
      </div>
    </>
  );
};
export default LeftMenu;
