import { DatePicker, Drawer, Select, Spin } from "antd";
import axios from "../../axios/AxiosInstance";
import { useState } from "react";
import locale from "antd/es/date-picker/locale/fr_FR";

const FilterBill = ({ open, setIsOpen, bills, setBills }) => {
  const [loading, setLoading] = useState(false);
  const [dateEcheance, setDateEcheance] = useState(null);
  const [factureRegle, setFactureRegle] = useState("");

  const selectFactureRegle = (e) => {
    const factureRegle = e === "Soldée" ? "OUI" : "NON";
    setFactureRegle(factureRegle);
  };

  const filter = (e) => {
    setLoading(true);
    axios
      .post("facture/filter", {
        FactureRegle: factureRegle,
        DateEcheance: dateEcheance,
      })
      .then((res) => {
        res.data.sort((a, b) => {
          const dateA = a.DateEcheance; // Assuming 'dateField' is the name of the date field
          const dateB = b.DateEcheance; // Assuming 'dateField' is the name of the date field

          // Compare the dates as strings
          if (dateA < dateB) {
            return 1; // 'a' comes before 'b'
          }
          if (dateA > dateB) {
            return -1; // 'b' comes before 'a'
          }
          return 0; // Dates are equal
        });
        setBills(res.data);
        setIsOpen(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const reset = () => {
    setBills(bills);
  };

  return (
    <Drawer
      title="Filtrer facture"
      open={open}
      className="h-1/2 rounded-md"
      onClose={() => setIsOpen(false)}
    >
      <div className=" flex rounded-md h-full flex-col">
        <div className="grow flex flex-col justify-between">
          <div className="flex items-center flex-col hover:cursor-pointer px-10">
            <div className="w-full flex flex-col items-center justify-center space-y-6">
              <div className="flex items-center justify-center space-x-4">
                <Select
                  className=" px-2 w-40 py-1 outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                  style={{ minHeight: 33 }}
                  showSearch
                  placeholder="statut"
                  optionFilterProp="children"
                  onChange={(e) => selectFactureRegle(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      label: "Soldée",
                      value: "Soldée",
                      key: "Soldée",
                    },
                    {
                      label: "Non Soldée",
                      value: "Non Soldée",
                      key: "Non Soldée",
                    },
                  ]}
                />
                <DatePicker
                  className="w-40"
                  onChange={(date, dateString) => setDateEcheance(date.$d)}
                  format={"DD/MM/YYYY"}
                  placeholder="Date d'echeance"
                  locale={locale}
                />
              </div>
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="rounded-md p-2 w-40 bg-black text-white"
                  onClick={reset}
                >
                  Reinitialiser
                </button>
                <button
                  className="rounded-md p-2 w-40 bg-mountain-500 text-white"
                  onClick={filter}
                >
                  Filtrer
                </button>
              </div>
              {loading && (
                <p className="flex justify-center">
                  <Spin />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterBill;
