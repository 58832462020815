import * as moment from "moment";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import Column from "antd/es/table/Column";
import { DownloadOutlined, PlagiarismOutlined } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../axios/AxiosInstance";
import { habilitationsList } from "../utils/habilitationsList";
import { documentsList } from "../utils/documentsList";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function CandidatDocuments({ user }) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [type, setType] = useState("");
  const [habilitations, setHabilitations] = useState("");
  const [userDocuments, setUserDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFileRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [accidentDate, setAccidentDate] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [agreementAskDate, setAgreementAskDate] = useState(null);
  const [agreementAmount, setAgreementAmount] = useState(null);
  const [deliveryCountry, setDeliveryCountry] = useState(null);
  const [inspectionDate, setInspectionDate] = useState(null);
  const [inspectedNumber, setInspectedNumber] = useState(null);
  const [subType, setSubType] = useState(null);
  const [payRollDate, setPayrollDate] = useState(null);
  const [vaccinType, setVaccinType] = useState(null);
  const [level, setLevel] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [documentData, setDocumentData] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [numPages, setNumPages] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDrawer, setIsDrawer] = useState(false);
  const [loadingId, setLoadingId] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    loadDocuments();
    console.log("dododood");
  }, []);

  const loadDocuments = () => {
    axiosInstance
      .get(`/document/candidate/${user._id}`)
      .then((res) => {
        setUserDocuments(
          res.data.map((document, idx) => {
            return { ...document, key: idx };
          }),
        );
        console.log(res.data);
      })
      .catch((e) => message.error("Une erreur s'est produite!"));
  };
  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const documentArray = documentsList.map((document) => {
    return {
      value: document,
      label: document,
    };
  });

  const habilitationsData = habilitationsList.map((habilitation) => {
    return {
      label: habilitation,
      value: habilitation,
    };
  });

  const onChangeHabilitation = (newValue) => {
    setHabilitations(newValue);
  };

  const change = (e) => {
    setType(e);
  };

  const deleteDocument = (documentId) => {
    setLoadingDocument(true);
    axiosInstance
      .delete(`/document/${documentId}`)
      .then((res) => {
        const documents = userDocuments.filter((doc) => doc._id !== documentId);
        setUserDocuments(documents);
        message.success("Document supprimé!");
        setLoadingDocument(false);
      })
      .catch((e) => message.error("Une erreur s'est produite"));
  };

  const handleDownloadPdf = (documentId) => {
    setLoadingDocument(true);
    setLoadingId(documentId);
    const link = document.createElement("a");
    axiosInstance
      .get(`/document/${documentId}`)
      .then((res) => {
        let documentType = res.data.name.split(".")[1];
        if (documentType === "pdf") {
          link.href = `data:application/pdf;base64,${res.data.data}`;
          link.download = `${res.data.name}`;
        } else {
          link.href = `data:image/png;base64,${res.data.data}`;
          link.download = `${res.data.name}`;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => message.error("Une erreur s'est produite"))
      .finally(() => {
        setLoadingDocument(false);
        setLoadingId("");
      });

    // Simulate a click event to trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDocument = (documentId) => {
    setLoadingDocument(true);
    setLoadingId(documentId);
    axiosInstance
      .get(`/document/${documentId}`)
      .then((res) => {
        setDocumentType(res.data.name.split(".")[1]);
        setDocumentData(res.data.data);
        setShowDocument(true);
        setLoadingDocument(false);
      })
      .catch((e) => message.error("Une erreur s'est produite"))
      .finally(() => setLoadingId(""));
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1);
    setNumPages(newArray);
  };

  const selectDocument = (document) => {
    setSelectedDocument(document);
  };

  const addDocument = () => {
    console.log("dididididid");
    console.log(cardNumber);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("type", type);
    formData.append("candidateId", user._id);

    const fields = {
      endDate,
      startDate,
      accidentDate,
      deliveryDate,
      expirationDate,
      cardNumber,
      agreementAskDate,
      agreementAmount,
      deliveryCountry,
      inspectionDate,
      inspectedNumber,
      subType,
      payRollDate,
      vaccinType,
      level,
      hourlyRate,
    };

    Object.entries(fields).forEach(([fieldName, fieldValue]) => {
      if (fieldValue) {
        formData.append(fieldName, fieldValue);
        console.log(fieldName, fieldValue);
      }
    });
    console.log(formData);
    axiosInstance
      .post("/document", formData)
      .then((res) => {
        console.log(res.data);
        setUserDocuments([...userDocuments, res.data]);
        setOpenAddModal(false);
        setSelectedFile(null);
      })
      .catch((e) => {
        setSelectedFile(null);
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <div className="text-right  bg-orange-50 h-full">
      <Modal
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        footer={[
          <Button key="back" onClick={() => setShowDocument(false)}>
            fermer
          </Button>,
        ]}
        width={1000}
        title="Document"
        open={showDocument}
        onCancel={() => setShowDocument(false)}
      >
        <div>
          {documentType === "pdf" && (
            <Document
              file={`data:application/pdf;base64,${documentData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={5 / 3} pageNumber={idx + 1} />;
              })}
            </Document>
          )}

          {documentType !== "pdf" && (
            <img src={`data:image/png;base64,${documentData}`} />
          )}
        </div>
      </Modal>

      <Modal
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        footer={[
          <Button key="add" onClick={addDocument}>
            Ajouter
          </Button>,
          <Button key="back" onClick={() => setOpenAddModal(false)}>
            fermer
          </Button>,
        ]}
        width={800}
        title="Ajout de documents"
        open={openAddModal}
        onCancel={() => setOpenAddModal(false)}
      >
        <div>
          <Select
            className="w-96"
            showSearch
            onChange={(e) => change(e)}
            optionFilterProp="children"
            placeholder="Selectionner type de document"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={documentArray}
          />

          {type === "Carte d'identité" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setDeliveryDate(date.$d)}
                placeholder="Date de delivrance"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
              <Input
                onChange={(e) => setCardNumber(e.target.value)}
                className="rounded-md border border-gray-300 mb-2"
                placeholder="Numero de la carte"
              />
            </div>
          )}

          {type === "Accident de travail" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setAccidentDate(date.$d)}
                placeholder="Date de l'accident"
              />
            </div>
          )}

          {type === "Accident de trajet" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setAccidentDate(date.$d)}
                placeholder="Date de l'accident"
              />
            </div>
          )}

          {type === "Arret maladie" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setStartDate(date.$d)}
                placeholder="Date de début"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                size="large"
                onChange={(date, dateString) => setEndDate(date.$d)}
                placeholder="Date de fin"
              />
            </div>
          )}

          {type === "Atradius" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setAgreementAskDate(date.$d)}
                placeholder="Date de la demande"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setAgreementAmount(e.target.value)}
                placeholder="Montant de l'accord"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setEndDate(date.$d)}
                placeholder="Date de fin de l'accord"
              />
            </div>
          )}

          {type === "Autorisation provisoire de séjour" && (
            <div className="mt-4 flex flex-col">
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setDeliveryDate(date.$d)}
                placeholder="Date de delivrance"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setCardNumber(e.target.value)}
                placeholder="Numero de la carte"
              />
            </div>
          )}

          {type === "Habilitation" && (
            <div className="mt-4 flex flex-col">
              <Select
                className="w-96"
                showSearch
                onChange={(e) => onChangeHabilitation(e)}
                optionFilterProp="children"
                placeholder="Selectionner type d'habilitation"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={habilitationsData}
              />
            </div>
          )}

          {type === "Carte de séjour" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => {
                  setDeliveryDate(date.$d);
                }}
                placeholder="Date de delivrance"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setCardNumber(e.target.value)}
                placeholder="Numero de la carte"
              />
            </div>
          )}

          {type === "Carte de résident" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setDeliveryDate(date.$d)}
                placeholder="Date de delivrance"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setCardNumber(e.target.value)}
                placeholder="Numero de la carte"
              />
            </div>
          )}

          {type === "CACES" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setDeliveryDate(date.$d)}
                placeholder="Date de delivrance"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
            </div>
          )}

          {type === "Carte européenne" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setDeliveryDate(date.$d)}
                placeholder="Date de delivrance"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2 "
                onChange={(date, dateString) => setExpirationDate(date.$d)}
                placeholder="Date d'expiration"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setCardNumber(e.target.value)}
                placeholder="Numero de la carte"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setDeliveryCountry(e.target.value)}
                placeholder="Pays de délivrance"
              />
            </div>
          )}

          {type === "Contrôle préfecture" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setInspectionDate(date.$d)}
                placeholder="Date du controle"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setInspectedNumber(e.target.value)}
                placeholder="Numero de la piece controlée"
              />
            </div>
          )}

          {type === "Document client" && (
            <div className="mt-4 flex flex-col">
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setSubType(e.target.value)}
                placeholder="Type de document"
              />
            </div>
          )}

          {type === "Fiche de paie" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setPayrollDate(date.$d)}
                placeholder="MM/YYYY"
              />
            </div>
          )}
          {type === "Vaccination" && (
            <div className="mt-4 flex flex-col">
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setVaccinType(e.target.value)}
                placeholder="Type de vaccin"
              />
            </div>
          )}

          {type === "Travailleur handicapé ( RQTH )" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setStartDate(date.$d)}
                placeholder="Date de début"
              />
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setEndDate(date.$d)}
                placeholder="Date de fin"
              />
            </div>
          )}

          {type === "Autre" && (
            <div className="mt-4 flex flex-col">
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setSubType(e.target.value)}
                placeholder="Type de document"
              />
            </div>
          )}

          {type === "Fiche de paie (controle taux et niveaux)" && (
            <div className="mt-4 flex flex-col">
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-md border border-gray-300 mb-2"
                onChange={(date, dateString) => setPayrollDate(date.$d)}
                placeholder="Date de la fiche de paie"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setLevel(e.target.value)}
                placeholder="Niveau"
              />
              <Input
                className="rounded-md border border-gray-300 mb-2"
                onChange={(e) => setHourlyRate(e.target.value)}
                placeholder="Taux horaire"
              />
            </div>
          )}

          <div
            onClick={handleClick}
            className="border border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
          >
            <CloudUploadIcon />
            <p className="mt-2">Ajouter document</p>
            <p className="text-black">{selectedFile?.name}</p>
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              className="hidden"
              ref={inputFileRef}
            />
          </div>
        </div>
      </Modal>
      <button
        onClick={setOpenAddModal}
        className="bg-orange-500 p-2 mt-10 rounded-md mr-6 mb-4 text-white"
      >
        Inserer un document
      </button>
      {userDocuments.length > 0 && (
        <div>
          <Table pagination={{ pageSize: 50 }} dataSource={userDocuments}>
            <Column
              className="text-center bg-orange-50"
              title="Type"
              dataIndex="type"
              key="type"
              render={(type, record) => <p>{type}</p>}
            />
            <Column
              className="text-center bg-orange-50"
              title="Date de delivrance"
              dataIndex="deliveryDate"
              key="deliveryDate"
              render={(deliveryDate, record) => (
                <p>
                  {deliveryDate
                    ? moment(deliveryDate).format("DD/MM/YYYY")
                    : ""}
                </p>
              )}
            />
            <Column
              className="text-center bg-orange-50"
              title="Date d'expiration"
              dataIndex="expirationDate"
              key="expirationDate"
              render={(expirationDate, record) => (
                <p>
                  {expirationDate
                    ? moment(expirationDate).format("DD/MM/YYYY")
                    : ""}
                </p>
              )}
            />
            <Column
              className="text-center bg-orange-50"
              title="Action"
              dataIndex="_id"
              key="_id"
              render={(data, record) => (
                <div>
                  {loadingId != data && (
                    <p className="hover:cursor-pointer flex items-center justify-center space-x-2">
                      <DownloadOutlined
                        onClick={() => handleDownloadPdf(data)}
                      />

                      <PlagiarismOutlined
                        className="text-blue-500 "
                        onClick={() => getDocument(data)}
                      />
                      <DeleteFilled
                        onClick={() => deleteDocument(data)}
                        className="text-red-500 text-xl "
                      />
                      <EditFilled
                        onClick={() => {
                          selectDocument(record);
                          setIsDrawer(true);
                        }}
                        className="text-green-500 text-xl "
                      />
                    </p>
                  )}

                  {loadingDocument && loadingId === data && <Spin />}
                </div>
              )}
            />
          </Table>
          <Drawer
            title="Editer le document"
            placement="right"
            onClose={() => setIsDrawer(false)}
            open={isDrawer}
          >
            <p>{selectedDocument?.name}</p>
            <p>{selectedDocument?.type}</p>
            <p>{selectedDocument?.deliveryDate}</p>
            <p>{selectedDocument?.expirationDate}</p>
            <DatePicker
              format={"DD/MM/YYYY"}
              value={dayjs(selectedDocument?.expirationDate)}
            />
          </Drawer>
        </div>
      )}

      {userDocuments.length === 0 && (
        <div className="flex flex-col items-center justify-center h-60">
          <p>Aucun document</p>
        </div>
      )}
    </div>
  );
}
