import { useEffect,useState } from "react";
import axios from '../../axios/AxiosInstance'
import { useNavigate } from "react-router-dom";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaymentsIcon from "@mui/icons-material/Payments";
import { AddCircleOutlineOutlined, CalendarMonth } from "@mui/icons-material";
import { message, Modal, Select } from "antd";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const FactureRelance = () => {

    const [bills, setBills] = useState([]);
    const navigate = useNavigate();
    const [originalBills, setOriginalBills] = useState([]);
    const [isRelance, setIsRelance] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const [typeRelance, setTypeRelance] = useState("");
    const [contactEmail, setContactEmail] = useState("");

    useEffect(()=> {
        loadBills();

    },[])

    const sendRelance = () => {
      if (typeRelance.length === 0) {
        message.error("Selectionnez un type de relance");
        return;
      }
  
      if (contactEmail.length === 0) {
        message.error("Selectionnez un contact");
        return;
      }
  
      const relance = [
        ...selectedBill.relance,
        { message: typeRelance, date: Date.now(), contactEmail },
      ];
      axios.post(`facture/relance/${selectedBill._id}`, {
        relance,
        typeRelance,
        contactEmail,
      })
        .then((res) => {
          const newBills = [...bills];
          newBills[bills.findIndex((bill) => bill._id === res.data._id)] =
            res.data;
          setBills(newBills);
          setIsRelance(false);
          setTypeRelance("");
          message.success("Relance envoyée !");
        })
        .catch((e) => {
          if (e.response.data.statusCode === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    };
  

    const onChangeTypeRelance = (e) => {
      setTypeRelance(e);
    };

    function formatNumber(number) {
      const parts = number.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    }

    const formatDate = (inputDate) => {
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);
  
      return `${day}/${month}/${year}`;
    };

    const handleDownloadPdf = (facture) => {
        axios.get(`facture/${facture._id}`)
          .then((res) => {
            console.log(res.data);
            const binaryData = Uint8Array.from(window.atob(res.data.data), (char) =>
              char.charCodeAt(0),
            );
            const pdfBlob = new Blob([binaryData], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, "_blank");
          })
          .catch((e) => console.log(e));
        // Generate a temporary anchor element and set the PDF file as its href
      };

    const formatJsDate = (date) => {
        const newDate = new Date(date);
        const day = String(newDate.getDate()).padStart(2, "0");
        const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
        const year = newDate.getFullYear();
    
        return `${day}/${month}/${year}`;
      };


      const formatJsHour = (date) => {
        const newDate = new Date(date);
        const hours = String(newDate.getHours()).padStart(2, "0");
        const minutes = String(newDate.getMinutes()).padStart(2, "0"); // Months are 0-based, so we add 1
    
        return `${hours}:${minutes}`;
      };


      const relance = (e, facture) => {
        e.stopPropagation();
        if (!facture.client) {
          message.error("le client n'a pas de numero pld (en attente)");
          return;
        }
    
        setIsRelance(true);
        setSelectedBill(facture);
        setContactEmail("");
      };

    const loadBills = () => {
        axios.get("facture/freelance/non-soldee")
          .then((data) => {
            console.log(data.data);
            setBills(data.data);
            setOriginalBills(data.data);
          })
          .catch((e) => {
            if (e.response.data.statusCode === 403) {
              localStorage.clear();
              navigate("/login");
            }
          });
      };

      return (
        <>
        {isRelance && (
        <Modal
          width={1000}
          footer={null}
          open={isRelance}
          onCancel={() => {
            setIsRelance(false);
            setSelectedBill(null);
          }}
        >
          <div className="flex flex-col my-10 space-y-6 items-center justify-center ">
            <Select
              className="w-96"
              showSearch
              onChange={(e) => onChangeTypeRelance(e)}
              optionFilterProp="children"
              placeholder="Selectionner type de relance"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "Echéance prochaine",
                  label: "Echéance prochaine (NIV 1)",
                  key: "Echéance prochaine",
                },
                {
                  value: "Facture impayée",
                  label: "Facture impayée (NIV 2)",
                  key: "Facture impayée",
                },
                {
                  value: "Mise en demeure",
                  label: "Mise en demeure (NIV 3)",
                  key: "Mise en demeure",
                },
              ]}
            />

            <Select
              className="w-96"
              showSearch
              onChange={(e) => setContactEmail(e)}
              value={contactEmail.length > 0 ? contactEmail : null}
              optionFilterProp="children"
              placeholder="Selectionner interlocuteur"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={selectedBill.client.contacts?.map((contact, idx) => {
                return {
                  value: contact.email,
                  label: contact.email,
                  key: idx,
                };
              })}
            />
            {typeRelance === "Facture impayée" && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher Client,<br></br>
                  <br></br>
                  Nous vous informons que la facture numéro{" "}
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>
                  ,d'un montant de{" "}
                  <span className="font-semibold">
                    {selectedBill.TotalTTC}&euro;
                  </span>
                  ,<br></br>
                  émise le{" "}
                  <span className="font-semibold">
                    {formatJsDate(selectedBill.createdAt)}
                  </span>
                  , demeure actuellement impayée.<br></br>
                  <br></br>
                  Nous sollicitons votre prompte attention pour régler cette
                  facture dans les meilleurs délais.<br></br>
                  <br></br>
                  Veuillez trouver ci-dessous nos coordonnées bancaires pour
                  faciliter le règlement : [Coordonnées bancaires].<br></br>
                  <br></br>
                  Nous restons à votre disposition pour toute clarification ou
                  information supplémentaire.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {typeRelance === "Echéance prochaine" && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher client,<br></br>
                  <br></br>
                  Nous tenons à vous rappeler que la date d'échéance de votre
                  facture n°
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>{" "}
                  approche à grands pas{" "}
                  <span className="font-semibold">
                    ({formatDate(selectedBill.DateEcheance)})
                  </span>
                  . <br></br>
                  <br></br>
                  Pour faciliter le processus de paiement et éviter tout retard,
                  nous souhaitons vous informer de l'importance de régler vos
                  factures à temps.<br></br>
                  <br></br>
                  Si vous avez des questions concernant le montant ou les
                  modalités de paiement, n'hésitez pas à contacter notre équipe.
                  <br></br>
                  <br></br>
                  Nous vous remercions par avance de votre diligence dans le
                  règlement de vos factures et de votre confiance en notre
                  entreprise.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {typeRelance === "Mise en demeure" && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher client,<br></br>
                  <br></br>
                  Je prends l'initiative de vous écrire en tant que représentant
                  de {selectedBill.agency.name} concernant la facture impayée à
                  votre nom, émises conformément à nos termes contractuels.
                  <br></br>
                  <br></br>
                  Malgré tous nos appels antérieurs et nos efforts pour résoudre
                  cette situation à l'amiable, nous constatons que le règlement
                  de la facture suivante demeure en souffrance :<br></br>
                  <br></br>- facture n°
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>{" "}
                  :{" "}
                  <span className="font-semibold">{selectedBill.TotalTTC}</span>
                  € TTC<br></br>
                  <br></br>
                  Conformément aux termes de l’article L. 441-6 du Code de
                  commerce, le montant des factures est augmenté de pénalités de
                  retard au taux d'intérêt légal.<br></br>
                  <br></br>
                  Le montant est également augmenté de la clause pénale, d’un
                  montant forfaitaire de 40€.<br></br>
                  <br></br>
                  Nous vous mettons en demeure de régler la somme de{" "}
                  <span className="font-semibold">{selectedBill.TotalTTC}</span>
                  €, toutes taxes comprises (TTC), augmenté des pénalités de
                  retard précitées, sous{" "}
                  <span className="font-semibold">48h</span> à compter de la
                  réception du présent e-mail.<br></br>
                  <br></br>
                  Pour régulariser votre situation et payer votre dette sans
                  frais supplémentaires, il convient d’effectuer un virement sur
                  le compte bancaire de notre société, ou de nous adresser un
                  chèque.<br></br>
                  <br></br>
                  Voici nos coordonnées bancaires : IBAN :{" "}
                  {selectedBill.agency.iban} BIC : {selectedBill.agency.BIC}
                  <br></br>
                  <br></br>A défaut, nous serons dans l’obligation de saisir les
                  juridictions compétentes d’une demande de condamnation à votre
                  encontre, laquelle sera augmentée des frais notamment de
                  justice et de procédure exposés inutilement pour se défendre.
                  Une déclaration pour impayé sera aussi faite auprès de notre
                  assurance crédit.<br></br>
                  <br></br>
                  Vous devez considérer le présent comme une mise en demeure de
                  nature à faire courir tout droit et intérêt que la loi et les
                  tribunaux attachent aux mises en demeure.<br></br>
                  <br></br>
                  Dans l’attente de votre règlement, recevez, Cher client,
                  l’expression de nos sentiments distingués.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {/* <button
              onClick={sendRelance}
              className="bg-mountain-500 w-96 font-semibold p-2 rounded-md text-white"
            >
              Modifier le message
            </button> */}

            <button
              onClick={sendRelance}
              className="bg-mountain-500 w-96 font-semibold p-2 rounded-md text-white"
            >
              Envoyer la relance
            </button>
          </div>
        </Modal>
      )}
        <div className="flex flex-col space-y-2">
            {bills.map((bill, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center drop-shadow-2xl text-sm justify-between p-1 bg-white border border-gray-200 rounded-md  hover:scale-105 hover:cursor-pointer transition-all duration-300"
                >
                  <div
                    onClick={() => handleDownloadPdf(bill)}
                    className="w-1/6 flex items-center  space-x-2"
                  >
                    <ApartmentIcon className="text-3xl text-gray-300" />

                    <p className="text-xs">{bill.RaisonSociale}</p>
                  </div>

                  <div
                    onClick={() => handleDownloadPdf(bill)}
                    className="w-1/6 flex items-center justify-center space-x-2"
                  >
                    <DescriptionOutlinedIcon
                      className={`text-3xl  ${
                        bill.addedData ? "text-green-500" : "text-red-500"
                      }`}
                    />
                    <p className=" text-center">{bill.IdFacture}</p>
                  </div>

                  <div
                    onClick={() => handleDownloadPdf(bill)}
                    className="w-1/6 flex items-center space-x-2 justify-center"
                  >
                    <div>
                      <p>
                        <CalendarMonth className="text-3xl text-gray-300" />
                      </p>
                    </div>
                    <div>
                      <p className=" text-right">
                        {formatDate(bill.DateEcheance)}
                      </p>
                    </div>
                  </div>

                  <div
                    onClick={() => handleDownloadPdf(bill)}
                    className="w-1/6 flex justify-center items-center space-x-1 "
                  >
                    <PaymentsIcon className="text-3xl text-gray-300" />
                    <p className="font-bold ">
                      {formatNumber(bill.TotalTTC)}&euro;
                    </p>
                  </div>

                  <div
                    onClick={() => handleDownloadPdf(bill)}
                    className="w-1/6 flex justify-center items-center "
                  >
                    {bill.FactureRegle === "OUI" && (
                      <p className="text-white bg-green-500 px-2 py-1 rounded-md font-semibold">
                        Soldée
                      </p>
                    )}

                    {bill.FactureRegle !== "OUI" && (
                      <p className="text-white bg-orange-500 px-2 py-1 rounded-md font-semibold">
                        Non soldée
                      </p>
                    )}
                  </div>

                  <div className="w-1/6 flex flex-col space-y-2 justify-center items-center ">
                    <div>
                      <AttachEmailIcon
                        onClick={(e) => relance(e, bill)}
                        className={`text-3xl text-gray-300  ${
                          bill.FactureRegle === "NON" ? "text-green-500" : ""
                        }`}
                      />
                    </div>
                    <div>
                      {bill.relance.map((rel, idx) => {
                        return (
                          <div key={idx}>
                            <p className="text-xs italic">
                              Relance {rel.message}
                            </p>
                            <p className="text-xs italic font-semibold">
                              le {formatJsDate(rel.date)} à{" "}
                              {formatJsHour(rel.date)}{" "}
                            </p>
                            <p className="text-xs italic">
                              à {rel.contactEmail}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )

}

export default FactureRelance;