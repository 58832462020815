import { useEffect, useState } from "react";
import axios from "../../axios/AxiosInstance";
import DownloadingIcon from "@mui/icons-material/Downloading";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Popover } from "antd";
import modifier from "../../assets/img/modifier.png";
import supprimer from "../../assets/img/supprimer.png";
import refuser from "../../assets/img/refuser.png";
import signer from "../../assets/img/signer.png";
import envoyer from "../../assets/img/envoyer.png";
import telecharger from "../../assets/img/telecharger.png";

const Devis = () => {
  const [devisData, setDevisData] = useState([]);

  useEffect(() => {
    axios
      .get("quotation/freelance")
      .then((res) => {
        setDevisData(res.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <div className="border border-gray-300 mt-6 rounded-md">
        <div className="flex items-center space-x-4 px-4 text-md py-3">
          <p className="w-[12%] text-center">NUMERO DE DEVIS</p>
          <p className="w-[12%] text-center">TYPE</p>
          <p className="w-[12%] text-center">CLIENT</p>
          <p className="w-[12%] text-center">POSTE</p>
          <p className="w-[12%] text-center">COEFFICIENT</p>
          <p className="w-[12%] text-center">TAUX HORAIRE</p>
          <p className="w-[12%] text-center">STATUT</p>
          <p className="w-[12%] text-center">ACTION</p>
        </div>
      </div>
      <div>
        {devisData.map((quotation, idx) => {
          return (
            <div
              key={idx}
              className=" py-4 flex items-center drop-shadow-2xl text-sm justify-between  bg-white border border-gray-200 rounded-md p-4 hover:scale-105 hover:cursor-pointer transition-all duration-300"
            >
              <div className="w-[12%]">
                {quotation.isDraft && (
                  <p className="flex justify-center">
                    <span className="bg-gray-200 px-2 py-1 rounded-md text-center">
                      Brouillon
                    </span>
                  </p>
                )}
                {!quotation.isDraft && (
                  <p className="text-center">
                    {quotation.quotationNumber.toString().padStart(5, "0")}
                  </p>
                )}
              </div>

              <div className="w-[12%]">
                {quotation.products.map((product) => {
                  if (product.product === "Délégation")
                    return (
                      <p className="font-bold text-center text-blue-500">
                        {product.product}
                      </p>
                    );

                  if (product.product === "Vacation")
                    return (
                      <p className="font-bold text-center text-green-500">
                        {product.product}
                      </p>
                    );

                  if (product.product === "Placement")
                    return (
                      <p className="font-bold text-center text-red-500">
                        {product.product}
                      </p>
                    );

                  if (product.product === "Gestion")
                    return (
                      <p className="font-bold text-center text-orange-500">
                        {product.product}
                      </p>
                    );
                })}
              </div>

              <div className="w-[12%]">
                <p className="font-bold text-center">
                  {quotation.client.companyName}
                </p>
              </div>

              <div className="w-[12%]">
                {quotation.products.map((product, idx) => {
                  return (
                    <>
                      <div key={idx}>
                        {product.type === "Prime" && (
                          <p className="font-bold text-center">
                            Prime : {product.product}{" "}
                          </p>
                        )}
                      </div>

                      <div>
                        {product.type !== "Prime" && (
                          <p className="font-bold text-center">
                            {product.poste}{" "}
                          </p>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="w-[12%]">
                {quotation.products.map((product) => {
                  return (
                    <p className="font-bold text-center">
                      {product.coefficient}
                    </p>
                  );
                })}
              </div>

              <div className="w-[12%]">
                {quotation.products.map((product) => {
                  return (
                    <p className="font-bold text-center">
                      {product.taux} &euro;
                    </p>
                  );
                })}
              </div>

              <div className="w-[12%] flex justify-center items-center ">
                {quotation.isDraft && (
                  <p className="text-center font-bold">Brouillon</p>
                )}
                {/*   {!quotation.isDraft&& <p className="text-center font-bold">Signé</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">Refusé</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">En attente d'envoi</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">En attente de signature</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">A relancer (1 jour)</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">A relancer (5 jours)</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">A relancer (30 jours)</p>}
          {!quotation.isDraft&& <p className="text-center font-bold">Annulé</p>} */}
              </div>

              <div className="w-[12%] flex justify-center items-center ">
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-base space-y-3">
                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={telecharger} />

                        <span>Télécharger</span>
                      </p>
                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={modifier} />
                        <span>Modifier</span>
                      </p>
                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={envoyer} />

                        <span>Envoyer</span>
                      </p>

                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={signer} />

                        <span>Signer</span>
                      </p>

                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={refuser} />

                        <span>Refuser</span>
                      </p>

                      <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                        <img className="w-4" src={supprimer} />
                        <span>Annuler</span>
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <Button>...</Button>
                </Popover>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Devis;
