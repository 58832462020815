import { useState } from "react";
import axiosInstance from "../../axios/AxiosInstance";
import { Input, Modal } from "antd";

const CreateContractCandidate = ({ contract, setContract }) => {
  const [timer, setTimer] = useState(null);

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isMissingModal, setIsMissingModal] = useState(false);
  const [isValidId, setIsValidId] = useState(true);
  const [missingParts, setMissingParts] = useState([]);

  const selectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
    const newMissingParts = [];
    if (!candidate.title) {
      newMissingParts.push("Civilité");
    }
    if (!candidate.firstname) {
      newMissingParts.push("Prénom");
    }
    if (!candidate.lastname) {
      newMissingParts.push("Nom");
    }
    if (!candidate.dob) {
      newMissingParts.push("Date de naissance");
    }
    if (!candidate.email) {
      newMissingParts.push("Email");
    }
    if (!candidate.phone) {
      newMissingParts.push("Téléphone");
    }
    if (!candidate.birthTown) {
      newMissingParts.push("Ville de naissance");
    }
    if (!candidate.birthDepartment) {
      newMissingParts.push("Département de naissance");
    }
    if (!candidate.birthCountry) {
      newMissingParts.push("Pays de naissance");
    }
    if (!candidate.securityNumber) {
      newMissingParts.push("Numero de sécurité sociale");
    }
    if (!candidate.nationality) {
      newMissingParts.push("Nationalité");
    }
    if (!candidate.address) {
      newMissingParts.push("Adresse");
    }
    if (!candidate.postCode) {
      newMissingParts.push("Code postal");
    }
    if (!candidate.town) {
      newMissingParts.push("Ville");
    }

    setContract({ ...contract, candidate });
    axiosInstance
      .get(`document/id/${candidate._id}`)
      .then((res) => {
        console.log(res.data);
        if (!res.data.length) {
          newMissingParts.push("Document d'identité");
        }
        if (newMissingParts.length && !candidate.pld) {
          setMissingParts(newMissingParts);
          setIsMissingModal(true);
        }
      })
      .catch((e) => console.log(e));
  };
  const search = (word) => {
    if (word.length === 0) {
      setSelectedCandidates([]);
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer);
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer);

      const timer2 = setTimeout(() => {
        axiosInstance
          .post("tempworker-candidate/find-by-text", { query: word })
          .then((res) => {
            setSelectedCandidates(res.data);
            console.log(res.data);
          })
          .catch((e) => console.log(e));
      }, 700);
      setTimer(timer2);
    }
  };

  return (
    <div className="mt-10 w-full ">
      {isMissingModal && (
        <Modal
          open={isMissingModal}
          footer={null}
          onCancel={() => setIsMissingModal(false)}
        >
          <div className="h-[400px]">
            <p className="font-semibold text-2xl">Informations manquantes</p>
            {missingParts.map((part) => {
              return <p className="text-red-600 mt-4 font-semibold">{part}</p>;
            })}
          </div>
        </Modal>
      )}
      <p className="font-semibold text-xl my-3 text-center text-red-600">
        Attention : La fiche du candidat doit avoir toutes les informations
        remplies pour créer le contrat
      </p>
      <p className="text-lg font-semibold">Candidat</p>
      <Input
        className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
        placeholder="Candidat"
        onChange={(e) => search(e.target.value)}
      />
      {selectedCandidates.length > 0 && (
        <div className="flex flex-col space-y-1  rounded-md mt-4 p-2 max-h-[400px] scrollbar-thin scrollbar-thumb-purple-700 scrollbar-track-purple-300 overflow-y-scroll">
          {selectedCandidates.map((candidate, idx) => {
            return (
              <div
                key={idx}
                onClick={() => selectCandidate(candidate)}
                className={`border px-4 py-1 rounded-md border-gray-300 pb-2 ${
                  contract.candidate?._id === candidate._id
                    ? "bg-purple-400"
                    : ""
                }`}
              >
                <div className="flex items-start space-x-3 justify-between mx-2">
                  <div>
                    <p className="text-lg font-semibold capitalize">
                      {candidate.firstname.toLowerCase()}{" "}
                      {candidate.lastname.toLowerCase()}
                    </p>
                    <p>{candidate.email}</p>
                    <p>{candidate.phone}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {contract.candidate && selectedCandidates.length === 0 && (
        <div
          className={`border px-4 py-1 rounded-md border-gray-300 pb-2 bg-green-400`}
        >
          <p className="text-lg font-semibold capitalize">
            {contract.candidate.firstname.toLowerCase()}{" "}
            {contract.candidate.lastname.toLowerCase()}
          </p>
          <p>{contract.candidate.email}</p>
          <p>{contract.candidate.phone}</p>
        </div>
      )}
    </div>
  );
};

export default CreateContractCandidate;
