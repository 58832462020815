import { useEffect, useState } from "react";
import foryou from "../../assets/img/foryou.png";
import avatar from "../../assets/img/avatar.jpg";
const ClientTask = ({ tasks }) => {
  const [userId, setUserId] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);

  const formatJsDate = (dated) => {
    const date = new Date(dated);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUserId(userId);
  }, []);

  return (
    <>
      <div className="border border-gray-300 rounded-md">
        <div className="flex items-center space-x-4 px-4 text-md py-3">
          <p className="w-[11%] uppercase text-center  font-semibold">Date</p>
          <p className="w-[11%] uppercase text-center font-semibold">Type</p>
          <p className="w-[11%] uppercase text-center  font-semibold">
            Attribuée à
          </p>
          <p className="w-[11%] uppercase text-center  font-semibold">Par</p>
          <p className="w-[11%] uppercase text-center font-semibold">Client</p>
          <p className="w-[11%] uppercase text-center font-semibold">
            Candidat
          </p>
          <p className="w-[11%] uppercase text-center font-semibold">
            Commentaire
          </p>
          <p className="w-[11%] uppercase text-center font-semibold">
            Action menée
          </p>
          <p className="w-[11%] uppercase text-center font-semibold">statut</p>
        </div>

        <div className="flex flex-col ">
          {tasks.map((task, idx) => {
            return (
              <div
                className="flex space-x-4  items-center drop-shadow-2xl text-xs   bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
                key={idx}
                onClick={() => {
                  setSelectedTask(task);
                }}
              >
                <p className="w-[11%] flex flex-col text-center text-sm capitalize">
                  <span>{formatJsDate(task.date)}</span>
                </p>
                {task.type === "Appel" && (
                  <p className="w-[11%] text-blue-500 text-center  text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === "Taches" && (
                  <p className="w-[11%] text-yellow-500   text-sm text-center capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === "Autre" && (
                  <p className="w-[11%] text-yellow-500 text-center  text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === "Deplacement" && (
                  <p className="w-[11%] text-yellow-500 text-center  text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type !== "Deplacement" &&
                  task.type !== "Autre" &&
                  task.type !== "Appel" &&
                  task.type !== "Taches" && (
                    <p className="w-[11%] text-yellow-500 text-center text-sm capitalize"></p>
                  )}

                {(!task.taskManager || task.taskManager?._id === userId) && (
                  <p className="w-[11%]  flex items-start justify-center space-x-2">
                    {" "}
                    <img className="w-10" src={foryou} />
                  </p>
                )}
                {task.taskManager && task.taskManager?._id !== userId && (
                  <p className="w-[11%]  flex items-start space-x-2 text-sm capitalize">
                    <span>
                      <img src={avatar} className="w-10 rounded-full" />
                      {/* {task.taskManager.image &&<img src={'data:image/png;base64, '+task.taskManager.image}  className='w-10 rounded-full' />} */}
                    </span>
                    <span>
                      {task.taskManager?.firstname} {task.taskManager?.lastname}
                    </span>
                  </p>
                )}
                <p className="w-[11%]  flex items-start space-x-2 text-sm capitalize">
                  <span>
                    {!task.taskCreator.image && (
                      <img src={avatar} className="w-10 rounded-full" />
                    )}
                    {task.taskCreator.image && (
                      <img
                        src={"data:image/png;base64," + task.taskCreator.image}
                        className="w-10 rounded-full"
                      />
                    )}
                  </span>
                  <span>
                    {task.taskCreator?.firstname} {task.taskCreator?.lastname}
                  </span>
                </p>
                
                <p className="w-[11%] flex flex-col text-center text-sm capitalize">
                  <span>{task.client?.companyName}</span>
                  <span>{task.client?.contactPhone}</span>
                </p>
                <p className="w-[11%] flex flex-col text-center  text-sm capitalize">
                  <span>
                    {task.candidate?.firstname} {task.candidate?.lastname}
                  </span>
                  <span>{task.candidate?.phone}</span>
                </p>
                <p className="w-[11%] text-center  text-sm ">
                  {" "}
                  <span>{`${task.comment?.substring(0, 10)}...`}</span>
                </p>
                {task.status !== "annulée" && (
                  <p className="w-[11%] text-center  text-sm capitalize">
                    {task.doneReason && (
                      <span>{`${task.doneReason?.substring(0, 10)}...`}</span>
                    )}
                  </p>
                )}
                {task.status === "annulée" && (
                  <p className="w-[11%] text-center text-sm capitalize">
                    {task.cancelReason && (
                      <span>{`${task.cancelReason?.substring(0, 10)}...`}</span>
                    )}
                  </p>
                )}
                {task.status === "A faire" && (
                  <p className="w-[11%] text-center   text-sm capitalize">
                    {" "}
                    <span className="bg-yellow-200 text-yellow-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}

                {task.status === "Effectuée" && (
                  <p className="w-[11%] text-center   text-sm capitalize">
                    {" "}
                    <span className="bg-green-200 text-green-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}

                {task.status === "annulée" && (
                  <p className="w-[11%] text-center  text-sm capitalize">
                    {" "}
                    <span className="bg-red-200 text-red-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ClientTask;
