import { Button, Input, Modal, message } from "antd";
import { useState, useRef, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axiosInstance from "../../axios/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { AddOutlined } from "@mui/icons-material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DeleteForeverOutlined, SearchOffRounded } from "@mui/icons-material";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Bibliotheque = () => {
  const [isAddDocument, setIsAddDocument] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFileRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [showDocument, setShowDocument] = useState(false);
  const [documentData, setDocumentData] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [numPages, setNumPages] = useState([]);

  const navigate = useNavigate();

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const base64toBlob = (base64String, contentType = "application/pdf") => {
    const byteCharacters = window.atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const formatJsDate = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getDocument = (documentId) => {
    axiosInstance
      .get(`/global-doc/${documentId}`)
      .then((res) => {
        const pdfBlob = base64toBlob(res.data.data);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      })
      .catch((e) => message.error("Une erreur s'est produite"));
  };

  const deleteDocument = (documentId, idx) => {
    axiosInstance
      .delete(`/global-doc/${documentId}`)
      .then((res) => {
        const newDocuments = [...documents];
        newDocuments.splice(idx, 1);
        setDocuments(newDocuments);
        message.success("Document supprimé !");
      })
      .catch((e) => message.error("Une erreur s'est produite"));
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1);
    setNumPages(newArray);
  };

  const findByName = (query) => {
    axiosInstance
      .post("global-doc/find-by-name", { query })
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((e) => console.log(e));
  };

  const addDocument = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", documentName);

    axiosInstance
      .post("/global-doc", formData)
      .then((res) => {
        message.success("Document ajouté !");
        const newDocuments = [...documents, res.data];
        setDocuments(newDocuments);
        setIsAddDocument(false);
      })
      .catch((e) => {
        setSelectedFile(null);
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const loadDocuments = () => {
    axiosInstance
      .get("global-doc/freelance")
      .then((res) => {
        setDocuments(res.data);
        console.log(res.data);
      })
      .catch((e) => {
        message.error("une erreur s'est produite");
      });
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      setSelectedFile(event.target.files[0]);
    } else {
      message.error("Veuillez ajouter un fichier pdf !");
    }
  };

  return (
    <div>
      <div className="mb-10 mt-10 font-Inter">
        <p className="text-2xl font-semibold uppercase">Bibliothèque</p>
      </div>
      {showDocument && (
        <Modal
          bodyStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          footer={[
            <Button key="back" onClick={() => setShowDocument(false)}>
              fermer
            </Button>,
          ]}
          width={1000}
          title="Document"
          open={showDocument}
          onCancel={() => setShowDocument(false)}
        >
          <div>
            {documentType === "pdf" && (
              <Document
                file={`data:application/pdf;base64,${documentData}`}
                onLoadSuccess={handleDocumentLoadSuccess}
              >
                {numPages.map((x, idx) => {
                  return <Page scale={5 / 3} pageNumber={idx + 1} />;
                })}
              </Document>
            )}

            {documentType !== "pdf" && (
              <img src={`data:image/png;base64,${documentData}`} />
            )}
          </div>
        </Modal>
      )}
      {isAddDocument && (
        <Modal
          open={isAddDocument}
          footer={null}
          onCancel={() => setIsAddDocument(false)}
        >
          <div className="flex flex-col space-y-2">
            <Input
              className="rounded-md mt-6 "
              placeholder="Nom du document"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />

            <div
              onClick={handleClick}
              className="border border-[#00000040] flex flex-col items-center justify-center py-3 text-[#00000040] rounded-md"
            >
              <CloudUploadIcon />
              <p className="mt-2 ">Ajouter document</p>
              <p className="text-black">{selectedFile?.name}</p>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="hidden"
                ref={inputFileRef}
              />
            </div>

            <p
              onClick={addDocument}
              className="rounded-md bg-purple-500 text-center text-black p-2"
            >
              Ajouter le document
            </p>
          </div>
        </Modal>
      )}
      <div className="flex justify-end mx-10 items-center space-x-2">
        <Input
          className="w-48 rounded-md py-1 px-2"
          placeholder="rechercher un document"
          onChange={(e) => findByName(e.target.value)}
        />

        <div
          className="border hover:cursor-pointer bg-purple-400 rounded-md flex items-center"
          onClick={() => setIsAddDocument(true)}
        >
          <AddOutlined className="hover:cursor-pointer text-black" />
          <p className="w-48 rounded-md bg-purple-400 text-black p-1">
            Ajouter un document
          </p>
        </div>
      </div>

      <div className="mt-10 mx-4">
        <div className="border flex items-center uppercase font-semibold p-2 border-gray-300 rounded-t-md">
          <p className="w-1/3 text-center">Document</p>
          <p className="w-1/3 text-center">Date du dépot</p>
          <p className="w-1/3 text-center">action</p>
        </div>

        <div className="flex flex-col space-y-2">
          {documents.map((doc, idx) => {
            return (
              <div
                key={idx}
                className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
              >
                <p className="w-1/3">{doc.name}</p>
                <p className="w-1/3 text-center">
                  {formatJsDate(doc.createdAt)}
                </p>
                <p className="w-1/3 flex items-center justify-center space-x-2">
                  <SearchOffRounded onClick={() => getDocument(doc._id)} />
                  <DeleteForeverOutlined
                    onClick={() => deleteDocument(doc._id, idx)}
                  />
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Bibliotheque;
