import { Select } from "antd";

const CreateContractContact = ({ client, contract, setContract }) => {
  const clientContactsArray = client.contacts.map((contact, idx) => {
    return {
      value: `${contact.firstname?.trim() || ""} ${
        contact.lastname?.trim() || ""
      } ${contact.email?.trim() || ""} ${contact.mobile?.trim() || ""}`,
      label: `${contact.firstname?.trim() || ""} ${
        contact.lastname?.trim() || ""
      } ${contact.email?.trim() || ""} ${contact.mobile?.trim() || ""}`,
      key: idx,
    };
  });

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">Contact</p>

        <Select
          className="w-full mt-6"
          onChange={(e) => setContract({ ...contract, contact: e })}
          value={contract.contact ? `${contract.contact}` : null}
          optionFilterProp="children"
          placeholder="Contact"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={clientContactsArray}
        />
      </div>
    </div>
  );
};

export default CreateContractContact;
