import { Icon } from "@iconify/react";
import { useState } from "react";
import nationalite from "../../assets/img/nationalite.png";
import civilite from "../../assets/img/civilite.png";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import tempo from "../../assets/img/tempo.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DatePicker, Input, Select, TreeSelect, message } from "antd";
import { regionArray } from "../utils/regions";
import AxiosInstance from "../../axios/AxiosInstance";
import qualificationsArray from "../utils/qualifications";
import { codePostaux } from "../utils/codes";
import { countries } from "../utils/countries";
import { cacesList } from "../utils/cacesList";
import { habilitationsList } from "../utils/habilitationsList";
import moment from "moment";
import "moment/locale/fr";
import { useNavigate } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";

export default function CandidatInfo({
  user,
  setUser,
  setCandidates,
  candidates,
}) {
  const [licences, setLicences] = useState(user.licences);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);

  const [diplomas, setDiplomas] = useState(user.diplomas);
  const [habilitations, setHabilitations] = useState(user.habilitations);
  const [locomotions, setLocomotions] = useState(user.locomotions);
  const [qualifications, setQualifications] = useState(user.qualifications);
  const [caceses, setCaceses] = useState(user.caceses);
  const [regions, setRegions] = useState(user.locations);
  const [pld, setPld] = useState(user.pld);
  const [title, setTitle] = useState(user.title);

  const [securityNumber, setSecurityNumber] = useState(user.securityNumber);
  const [address, setAddress] = useState(user.address || "");
  const [phone, setPhone] = useState(user.phone);
  const [phone2, setPhone2] = useState(user.phone2);
  const [email, setEmail] = useState(user.email);
  const [postCode, setPostCode] = useState(user.postCode || "");
  const [town, setTown] = useState(user.town || "");
  const [dob, setDob] = useState(user.dob);
  const [dailySalary, setDailySal] = useState(user.dailySalaryExpectations);
  const [monthlySalary, setMonthlySal] = useState(
    user.monthlySalaryExpectations,
  );
  const [yearlySalary, setYearlySal] = useState(user.yearlySalaryExpectations);
  const [birthCountry, setBirthCountry] = useState(user.birthCountry);
  const [nationality, setNationality] = useState(user.nationality);
  const [birthTown, setBirthTown] = useState(user.birthTown);
  const [birthDepartment, setBirthDepartment] = useState(user.birthDepartment);

  const [isLoading, setIsLoading] = useState(false);

  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditPhone, setIsEditPhone] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [isEditDob, setIsEditDob] = useState(false);
  const [isEditSecurity, setIsEditSecurity] = useState(false);
  const [isEditPld, setIsEditPld] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  const updateCandidate = () => {
    setIsLoading(true);

    const updateData = {
      securityNumber,
      pld,
      title,
      firstname,
      lastname,
      phone,
      phone2,
      email,
      postCode,
      town,
      qualifications,
      diplomas,
      licences,
      nationality,
      habilitations,
      caceses,
      address,
      locations: regions,
      locomotions,
      dob,
      birthCountry,
      birthDepartment,
      birthTown,
      dailySalaryExpectations: dailySalary,
      monthlySalaryExpectations: monthlySalary,
      yearlySalaryExpectations: yearlySalary,
      pld,
    };
    console.log(updateData);

    console.log(pld);
    AxiosInstance.post(`tempworker-candidate/${user._id}/update`, updateData)
      .then((res) => {
        console.log(res.data);
        const newCandidate = res.data;
        const candidateIndex = candidates.findIndex(
          (obj) => obj._id === newCandidate._id,
        );
        candidates[candidateIndex] = newCandidate;
        candidates.sort((a, b) => a.lastname.localeCompare(b.lastname));
        setCandidates([...candidates]);
        message.success("Modifications enregistrées!");
        setUser(newCandidate);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);

        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const onChangeRegion = (newValue) => {
    setRegions(newValue);
  };

  const onChangeLicence = (newValue) => {
    setLicences(newValue);
  };

  const onChangeDiploma = (newValue) => {
    setDiplomas(newValue);
  };

  const setYearlySalary = (value) => {
    if (value === "") {
      setYearlySal("");
      setMonthlySal("");
      setDailySal("");
    } else {
      setYearlySal(value);
      setMonthlySal((parseFloat(value) / 12).toFixed(2));
      setDailySal((parseFloat(value) / 12 / 151.67).toFixed(2));
    }
  };

  const setMonthlySalary = (value) => {
    if (value === "") {
      setYearlySal("");
      setMonthlySal("");
      setDailySal("");
    } else {
      setYearlySal((parseFloat(value) * 12).toFixed(2));
      setMonthlySal(value);
      setDailySal((parseFloat(value) / 151.67).toFixed(2));
    }
  };

  const setDailySalary = (value) => {
    if (value === "") {
      setYearlySal("");
      setMonthlySal("");
      setDailySal("");
    } else {
      setYearlySal((parseFloat(value) * 12 * 151.67).toFixed(2));
      setMonthlySal((parseFloat(value) * 151.67).toFixed(2));
      setDailySal(value);
    }
  };

  const onChangeCaces = (newValue) => {
    setCaceses(newValue);
  };

  const onChangeLocomotion = (newValue) => {
    setLocomotions(newValue);
  };

  const onChangeHabilitation = (newValue) => {
    setHabilitations(newValue);
  };

  const onChangeQualification = (newValue) => {
    setQualifications(newValue);
  };

  const licencesArray = [
    "AM",
    "A1",
    "A2",
    "A",
    "B1",
    "B",
    "C1",
    "C",
    "D1",
    "D",
    "BE",
    "C1E",
    "CE",
    "D1E",
    "DE",
  ];

  const diplomasArray = [
    "Aide soignant",
    "infirmier",
    "AES",
    "AMP",
    "BTS analyse biologique",
    "BP préparateur en pharmacie",
    "CAP - BEP",
    "Baccalauréat",
    "DEUG - BTS - DUT - DEUST",
    "Licence - BUT",
    "Maîtrise - Master",
    "Doctorat",
  ];

  const diplomasData = diplomasArray.map((diploma) => {
    return {
      title: diploma,
      value: diploma,
      key: diploma,
    };
  });

  const diplomasProps = {
    treeData: diplomasData,
    onChange: onChangeDiploma,
    value: diplomas,
    treeCheckable: true,
    placeholder: "Diplome",
  };

  const habilitationsData = habilitationsList.map((habilitation) => {
    return {
      title: habilitation,
      value: habilitation,
      key: habilitation,
    };
  });

  const habilitationsProps = {
    treeData: habilitationsData,
    onChange: onChangeHabilitation,
    value: habilitations,
    treeCheckable: true,
    placeholder: "Habilitations",
  };

  const regionData = regionArray.map((licence) => {
    return {
      title: licence,
      value: licence,
      key: licence,
    };
  });

  const licenceData = licencesArray.map((licence) => {
    return {
      title: licence,
      value: licence,
      key: licence,
    };
  });

  const licenceProps = {
    treeData: licenceData,
    onChange: onChangeLicence,
    value: licences,
    treeCheckable: true,
    placeholder: "Permis",
  };

  const regionProps = {
    treeData: regionData,
    onChange: onChangeRegion,
    value: regions,
    treeCheckable: true,
    placeholder: "Secteur géographique",
  };

  const departmentArray = codePostaux.map((code) => {
    const regionVal = code.split(" : ")[0];

    return {
      value: regionVal,
      label: regionVal,
    };
  });

  const countriesArray = countries.map((country) => {
    return {
      value: country,
      label: country,
    };
  });

  const cacesData = cacesList.map((cace) => {
    return {
      title: cace,
      value: cace,
      key: cace,
    };
  });

  const cacesProps = {
    treeData: cacesData,
    onChange: onChangeCaces,
    value: caceses,
    treeCheckable: true,
    placeholder: "Caces",
  };

  const locomotion = [
    "Transport en commun",
    "Voiture",
    "Moto",
    "Vélo/Trottinette",
  ];

  const locomotionData = locomotion.map((loco) => {
    return {
      title: loco,
      value: loco,
      key: loco,
    };
  });

  const qualificationData = qualificationsArray.map((qualification) => {
    return {
      title: qualification,
      value: qualification,
      key: qualification,
    };
  });

  const qualificationProps = {
    treeData: qualificationData,
    onChange: onChangeQualification,
    value: qualifications,
    treeCheckable: true,
    placeholder: "Qualification",
  };

  const locomotionProps = {
    treeData: locomotionData,
    onChange: onChangeLocomotion,
    value: locomotions,
    treeCheckable: true,
    placeholder: "Locomotion",
  };

  const changeDob = (newDob) => {
    setDob(newDob);
  };

  const onChangeDepartment = (value) => {
    setBirthDepartment(value);
  };

  const onChangeCountry = (value) => {
    setBirthCountry(value);
  };

  const onChangeNationality = (value) => {
    setNationality(value);
  };

  const onChangeBirthTown = (value) => {
    setBirthTown(value);
  };

  const AgeCalculator = (dateOfBirth) => {
    if (!dateOfBirth) return "0";
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Vérifier si l'anniversaire est déjà passé dans l'année en cours
    const isBirthdayPassed =
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate());

    // Si l'anniversaire n'est pas encore passé, décrémenter l'âge
    if (isBirthdayPassed) {
      return age - 1;
    }

    return age;
  };

  const deletePld = () => {
    AxiosInstance.delete(`tempworker-candidate/${user._id}/delete-pld`)
      .then((res) => {
        AxiosInstance.get("tempworker-candidate")
          .then((res) => {
            setCandidates(res.data);
            message.success("Modification enregistrées ! 😏");
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="mb-8 bg-red-50 p-2">
        <p className="uppercase text-red-500  font-semibold text-xl mb-3">
          Informations personnelles :{" "}
          <span
            className="hover:cursor-pointer"
            onClick={() => setIsEdit(!isEdit)}
          >
            <EditOutlinedIcon />
          </span>
        </p>
        <div className=" w-full p-4 border-2  rounded-lg shadow-lg">
          <div className="flex flex-row my-2 items-center  space-x-2">
            <p className="text-base flex items-center my-1 space-x-4 font-light">
              <img className="w-6" src={civilite} />
            </p>
            {isEdit ? (
              <div>
                <Select
                  className={`w-96  font-light rounded-md border`}
                  optionFilterProp="children"
                  placeholder="Civilité"
                  onChange={(e) => setTitle(e)}
                  value={title}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Mr",
                      label: "Mr",
                      key: "0",
                    },
                    {
                      value: "Mme",
                      label: "Mme",
                      key: "1",
                    },
                  ]}
                />
              </div>
            ) : (
              <p className="text-base font-light uppercase">
                {`${title || ""}`}{" "}
              </p>
            )}
          </div>

          <div className="flex flex-row my-2 items-center space-x-2">
            <Icon
              icon={"mingcute:location-2-line"}
              width={20}
              className="text-green-400 mr-2"
            />

            {isEdit ? (
              <div className="grid grid-cols-4 gap-x-4">
                <Input
                  value={address}
                  placeholder="Adresse"
                  onChange={(e) => setAddress(e.target.value)}
                  className="col-span-2 py-1 px-2 p-2 outline-none text-black border rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                />
                <Input
                  value={postCode}
                  type="tel"
                  maxLength={5}
                  placeholder="75015"
                  onChange={(e) => setPostCode(e.target.value)}
                  className="col-span-1  py-1 px-2 p-2 outline-none text-black border rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                />
                <Input
                  value={town}
                  placeholder="Paris"
                  onChange={(e) => setTown(e.target.value)}
                  className="col-span-1 py-1 px-2 outline-none text-black border rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                />
              </div>
            ) : (
              <p className="text-base font-light ">
                {`${address} ${postCode} ${town}`}{" "}
              </p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditAddress(!isEditAddress)}
            ></span>
          </div>

          <div className="flex flex-row my-2 items-center space-x-2">
            <Icon
              icon={"ic:baseline-phone"}
              width={20}
              className="text-red-600 mr-2"
            />
            {isEdit ? (
              <div className="w-full">
                <Input
                  value={phone}
                  maxLength={13}
                  placeholder="Téléphone"
                  onChange={(e) => setPhone(e.target.value)}
                  className="px-2 py-1 outline-none text-black border rounded-md border-gray-200 placeholder-gray-400"
                />
              </div>
            ) : (
              <p className="text-base font-light uppercase">{phone} </p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditPhone(!isEditPhone)}
            ></span>
          </div>

          <div className="flex flex-row my-2 items-center space-x-2">
            <Icon
              icon={"ic:baseline-phone"}
              width={20}
              className="text-red-600 mr-2"
            />
            {isEdit ? (
              <div className="w-full">
                <Input
                  value={phone2}
                  maxLength={10}
                  placeholder="Téléphone"
                  onChange={(e) => setPhone2(e.target.value)}
                  className="px-2 py-1 outline-none text-black border rounded-md border-gray-200 placeholder-gray-400"
                />
              </div>
            ) : (
              <p className="text-base font-light uppercase">{phone2} </p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditPhone(!isEditPhone)}
            ></span>
          </div>

          <div className="flex flex-row my-2 items-center space-x-2">
            <Icon
              icon={"material-symbols:mail-outline"}
              width={20}
              className="text-blue-600 mr-2"
            />
            {isEdit ? (
              <div className="w-full">
                <Input
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-2 py-1 outline-none text-black border rounded-md border-gray-200 placeholder-gray-400"
                />
              </div>
            ) : (
              <p className="text-base font-light ">{email} </p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditEmail(!isEditEmail)}
            ></span>
          </div>

          <div className="flex flex-row mb-1 items-center space-x-2">
            <Icon
              icon={"iconoir:birthday-cake"}
              width={20}
              className="text-pink-600 mr-2"
            />
            {isEdit ? (
              <div className="grid grid-cols-5 items-baseline gap-x-3">
                <DatePicker
                  placeholder="Date de naissance"
                  onChange={changeDob}
                  format="DD/MM/YYYY"
                  className="col-span-2"
                />

                <Select
                  className="col-span-1 h-8 px-2 py-1 outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                  // class={'test'}
                  style={{ minHeight: 33 }}
                  showSearch
                  value={birthDepartment}
                  placeholder="75"
                  optionFilterProp="children"
                  onChange={onChangeDepartment}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={departmentArray}
                />

                <Input
                  value={birthTown}
                  onChange={(e) => onChangeBirthTown(e.target.value)}
                  placeholder="Ville"
                  className="col-span-1 h-8 px-2 py-1 outline-none text-black border rounded-md border-gray-200 placeholder-gray-400"
                />

                <Select
                  className="col-span-1 px-2 py-1 outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                  style={{ minHeight: 33 }}
                  showSearch
                  value={birthCountry}
                  placeholder="France"
                  optionFilterProp="children"
                  onChange={onChangeCountry}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={countriesArray}
                />
              </div>
            ) : (
              <p className="text-base font-light">
                {moment(user?.dob).format("LL")} ({AgeCalculator(user?.dob)}{" "}
                ans) à ({birthDepartment}) {birthTown} ({birthCountry})
              </p>
            )}

            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditDob(!isEditDob)}
            ></span>
          </div>

          {isEdit ? (
            <div className="flex space-x-1 items-center">
              <p>Nationalité : </p>

              <Select
                className="col-span-1 px-2 py-1 w-1/4 outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                style={{ minHeight: 33 }}
                showSearch
                value={nationality}
                placeholder="France"
                optionFilterProp="children"
                onChange={onChangeNationality}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={countriesArray}
              />
            </div>
          ) : (
            <p className="text-base flex items-center my-1 space-x-4 font-light">
              <img className="w-6" src={nationalite} />
              <span>{nationality}</span>
            </p>
          )}

          <div className="flex flex-row mb-1 items-center space-x-2">
            <LocalHospitalOutlinedIcon className="text-green-700 mr-1" />
            {isEdit ? (
              <Input
                value={securityNumber}
                maxLength={15}
                placeholder="Numero de securite sociale"
                onChange={(e) => setSecurityNumber(e.target.value)}
                className="w-96 px-2 py-1 outline-none   text-black   border rounded-md border-gray-200  placeholder-black"
              />
            ) : (
              <p className="text-base">{securityNumber}</p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditSecurity(!isEditSecurity)}
            ></span>
          </div>
          <div className="flex flex-row mb-1 items-center space-x-2">
            <p>
              <img className="w-5 mr-[6px] ml-[2px]" src={tempo} />
            </p>
            {isEdit ? (
              <Input
                value={pld}
                placeholder="Numero pld"
                onChange={(e) => setPld(e.target.value)}
                className="w-96 px-2 py-1 outline-none   text-black   border rounded-md border-gray-200  placeholder-gray-400"
              />
            ) : (
              <p className="text-base font-light">{pld}</p>
            )}
            <span
              className="hover:cursor-pointer"
              onClick={() => setIsEditPld(!isEditPld)}
            ></span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8 mt-8">
          <div>
            <p className="text-lg uppercase font-semibold flex items-center space-x-1 text-red-500">
              <span>
                <WorkIcon fontSize="small" />
              </span>
              <span className="mt-1 text-xl">POSTE RECHERCHE :</span>
            </p>
            <div className="p-4 flex flex-col border border-gray-200 items-start space-y-2 w-full rounded-lg shadow-lg">
              <p className=" uppercase text-gray-600 font-medium mb-3">
                Poste :
              </p>

              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...qualificationProps}
              />

              <p className=" uppercase text-gray-600 font-medium mb-3">
                Secteur géographique :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...regionProps}
              />

              <p className=" uppercase text-gray-600 font-medium mb-3">
                Moyen de locomotion :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...locomotionProps}
              />
            </div>
          </div>

          {/*   <CanditatListDetails data={diplomasProps} title={'Diplomes'} /> */}

          <div>
            <p className="text-lg uppercase font-semibold flex items-center space-x-1 text-red-500">
              <span className="mt-1 text-xl">AUTRES :</span>
            </p>

            <div className=" p-4 flex flex-col border-border-gray-200 items-start space-y-2 w-full  rounded-lg shadow-lg">
              <p className=" uppercase text-gray-600 font-medium mb-3">
                Diplomes :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...diplomasProps}
              />

              <p className=" uppercase text-gray-600 font-medium mb-3">
                Habilitations :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...habilitationsProps}
              />

              <p className="uppercase text-gray-600 font-medium mb-3">
                Caces :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...cacesProps}
              />
              <p className=" uppercase text-gray-600 font-medium mb-3">
                Permis :
              </p>
              <TreeSelect
                className="w-full hover:cursor-pointer text-start text-gray-600"
                size="large"
                {...licenceProps}
              />
            </div>
          </div>
        </div>
        <div>
          <p className=" uppercase text-red-500 text-xl font-semibold mb-3">
            Prétentions salariales (Brut) :
          </p>
          <div className="p-4 flex  border border-gray-200 items-center space-x-2 w-full rounded-lg shadow-lg">
            <div>
              <input
                tabIndex={0}
                name=""
                value={yearlySalary}
                onChange={(e) => setYearlySalary(e.target.value)}
                type="text"
                placeholder="Annuel"
                className="max-h-10  p-2 text-right text-lg leading-none text-gray-600 font-light focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-300"
              />{" "}
              &euro; /an
            </div>
            <div>
              <input
                tabIndex={0}
                name=""
                value={monthlySalary}
                onChange={(e) => setMonthlySalary(e.target.value)}
                type="text"
                placeholder="Mensuel"
                className="max-h-10  p-2 text-right text-lg leading-none text-gray-600 font-light focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-300"
              />
              &euro; /mois
            </div>

            <div>
              <input
                tabIndex={0}
                name=""
                value={dailySalary}
                onChange={(e) => setDailySalary(e.target.value)}
                type="text"
                placeholder="Horaire"
                className="max-h-10  p-2 text-right text-lg leading-none text-gray-600 font-light focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-300"
              />
              &euro; /h
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end w-full  ">
        {isLoading ? (
          <p className="flex w-48 items-center space-x-2 p-2 justify-center rounded-md text-right bg-mountain-500 text-white">
            ...chargement
          </p>
        ) : (
          <p
            className="flex w-48  items-center hover:cursor-pointer space-x-2 p-2 rounded-md justify-center bg-mountain-500 text-white"
            onClick={updateCandidate}
          >
            <Icon
              className=""
              icon={"material-symbols:edit-square-outline"}
              width={20}
            />
            <span>Enregistrer</span>
          </p>
        )}
      </div>
    </>
  );
}
