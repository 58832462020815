import { SearchBar } from "../utils/SearchBar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { AddOutlined } from "@mui/icons-material";
import modifier from "../../assets/img/modifier.png";
import supprimer from "../../assets/img/supprimer.png";
import terminer from "../../assets/img/terminer.png";
import dupliquer from "../../assets/img/dupliquer.png";
import annuler from "../../assets/img/annuler.png";
import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Spin,
  Pagination,
  Popover,
  Button,
  message,
} from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/AxiosInstance";
import { DesktopTimePicker, TimePicker } from "@mui/x-date-pickers";
import qualifications from "../utils/qualifications";
import {
  DeleteForeverOutlined,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import epinone from "../../assets/img/epinone.png";
import epicasque from "../../assets/img/epicasque.png";
import epichaussure from "../../assets/img/epichaussure.png";
import epigilet from "../../assets/img/epigilet.png";
import epimasque from "../../assets/img/epimasque.png";
import epiaudio from "../../assets/img/epiaudio.png";
import epiaudioselected from "../../assets/img/epiaudioselected.png";
import epicasqueselected from "../../assets/img/epicasqueselected.png";
import epichaussureselected from "../../assets/img/epichaussureselected.png";
import epigiletselected from "../../assets/img/epigiletselected.png";
import epimasqueselected from "../../assets/img/epimasqueselected.png";
import epibleuselected from "../../assets/img/epibleuselected.png";
import epigantselected from "../../assets/img/epigantselected.png";
import epiharnaisselected from "../../assets/img/epiharnaisselected.png";
import epilunetteselected from "../../assets/img/epilunetteselected.png";
import epinoneselected from "../../assets/img/epinoneselected.png";
import epiautre from "../../assets/img/epiautre.png";
import epibleu from "../../assets/img/epibleu.png";
import epigant from "../../assets/img/epigant.png";
import epiharnais from "../../assets/img/epiharnais.png";
import epilunette from "../../assets/img/epilunette.png";
import { MailOutlined } from "@ant-design/icons";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import _ from "lodash";
import { Puff } from "react-loader-spinner";
import OrderCandidatModal from "./OrderCandidatModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FilterOrder from "./FilterOrder";
import ModalDetail from "./ModalDetail";
import TextArea from "antd/es/input/TextArea";

const Commandes = () => {
  const userId = localStorage.getItem("userId");
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState("1");
  const [clients, setClients] = useState([]);
  const [clientAddressesArray, setClientAdressesArray] = useState([]);
  const [clientContactsArray, setClientContactsArray] = useState([]);
  const [isFilterOrder, setIsFilterOrder] = useState(false);
  const [isOriginal, setIsOriginal] = useState(true);
  const [enCoursCount, setEnCoursCount] = useState(0);
  const [filter, setFilter] = useState("Toutes");
  const [isSearch, setIsSearch] = useState(false);
  const [finishReason, setFinishReason] = useState("");
  const [comment, setComment] = useState("");
  const [isCommentModal, setIsCommentModal] = useState(false);
  const [order, setOrder] = useState({
    epiProvider: "",
    isVehicule: true,
    epis: [],
    primes: [],
    workedDays: [],
    quantity: 1,
    recruiter: userId,
  });
  const [orders, setOrders] = useState([]);
  const [originalOrders, setOriginalOrders] = useState([]);
  const [prime, setPrime] = useState("");
  const [primeType, setPrimeType] = useState("");
  const [taux, setTaux] = useState("");
  const [potentialCandidate, setPotentialCandidate] = useState([]);
  const [openCommandeModal, setOpenCommandeModal] = useState(true);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isLoadingValidate, setIsLoadingValidate] = useState(false);

  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [isFinishOrder, setIsFinishOrder] = useState(false);
  const [canceller, setCanceller] = useState("");
  const [cancelReason, setCancelReason] = useState("");

  const [facturationDescription, setFactureDescription] = useState("");
  const [facturationQuantity, setFactureQuantity] = useState("");
  const [facturationRate, setFactureRate] = useState("");

  const [documentCount, setDocumentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const addFacturation = () => {
    if (!facturationDescription.length) return;
    if (!facturationQuantity.length) return;
    if (!facturationRate.length) return;

    const facturations = order.facturation
      ? [
          ...order.facturation,
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ]
      : [
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ];
    setFactureDescription("");
    setFactureQuantity("");
    setFactureRate("");
    setOrder({ ...order, facturation: facturations });
  };

  const duplicateOrder = (order, isUpdate) => {
    setIsUpdate(isUpdate);
    setOrder({
      ...order,
      startHour: createDateFromTimeString(order.startHour),
      endHour: createDateFromTimeString(order.endHour),
    });
    setOpenModal(true);
  };

  const deleteOrder = (e, orderId) => {
    axiosInstance
      .delete(`order/${orderId}`)
      .then((res) => {
        const orderIdx = orders.findIndex((ord) => ord._id === orderId);
        const originalOrderIdx = originalOrders.findIndex(
          (ord) => ord._id === orderId,
        );
        const newOrders = [...orders];
        const newOriginalOrders = [...originalOrders];
        newOrders.splice(orderIdx, 1);
        newOriginalOrders.splice(originalOrderIdx);
        setOrders(newOrders);
        setOriginalOrders(newOriginalOrders);
        message.success("Commande supprimée !");
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !");
        console.log(e);
      });
  };

  const finishOrder = () => {
    if (finishReason === "Commande gagnée") {
      axiosInstance
        .patch(`order/${selectedCommande._id}`, {
          status: "terminee",
        })
        .then(() => {
          message.success("Commande terminee!");
        })
        .catch((e) => {
          console.log(e);
          message.error("Une erreur s'est produite !");
        })
        .finally(() => {
          setFinishReason("");
          setIsFinishOrder(false);
        });
    } else {
      cancelOrder();
    }
  };

  const onChangePage = (page, pageSize) => {
    setIsLoading(true);
    axiosInstance
      .get(`order/freelance/paginate/${page - 1}`)
      .then((res) => {
        setOrders(res.data);
        setFilter("Toutes");
        setIsLoading(false);
        setCurrentPage(page);
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const deleteFacturationItem = (idx) => {
    const facturations = order.facturation.filter(
      (fact, index) => idx !== index,
    );
    setOrder({ ...order, facturation: facturations });
  };

  const filterOrders = (status) => {
    if (status === "Toutes") {
      setOrders(
        originalOrders.filter(
          (order) => order.status !== "terminee" && order.status !== "annulee",
        ),
      );
      setFilter("Toutes");
      setIsOriginal(true);
      return;
    }
    setIsOriginal(false);

    axiosInstance
      .get(`order/freelance/filter/${status}`)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !");
        console.log(e);
      });
  };

  const navigate = useNavigate();

  const images = [
    { selected: epiaudioselected, name: epiaudio, description: "audio" },
    { selected: epicasqueselected, name: epicasque, description: "casque" },
    {
      selected: epichaussureselected,
      name: epichaussure,
      description: "chaussure",
    },
    { selected: epigiletselected, name: epigilet, description: "gilet" },
    { selected: epimasqueselected, name: epimasque, description: "masque" },
    { selected: epibleuselected, name: epibleu, description: "bleu" },
    { selected: epigantselected, name: epigant, description: "gant" },
    { selected: epiharnaisselected, name: epiharnais, description: "harnais" },
    { selected: epilunetteselected, name: epilunette, description: "lunette" },
  ];

  const usedMotif = [
    "42 : Accroissement temporaire d'activité lié à une variation cyclique d'activité",
    "52 : Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable",
    "11 : Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
    "83 : Emploi temporaire par nature défini comme emploi à caractère saisonnier",
    "93 : Emploi temporaire par nature défini comme emploi d'usage constant",
  ];

  const otherMotif = [
    "10 : Formation",
    "72 : Accroissement temporaire d'activité lié à des travaux dont  l'exécution immédiate est nécessitée par des mesures de sécurité",
    "12 : Remplacement d'un salarié en cas de passage provisoire à temps partiel, conclu par avenant à son contrat de travail ou par échange écrit entre le salarié et l'employeur",
    "62 : Accroissement temporaire d'activité lié à une commande exceptionnelle à l'exportation",
    "21 : Conformément aux dispositions légales faciliter l'embauche des personnes sans emploi ayant des difficultés sociales professionnelles particulières",
    "14 : Remplacement d'un salarié en cas de l'entrée effective du salarié recruté par un contrat à durée indéterminée appelé à le remplacer",
    "23 : Remplacement d'un associé non salarié d'une société civile professionnelle d'une société civile de moyens ou d'une société d'exercice libéral",
    "25 : Remplacement d'un chef d'exploitation agricole ou d'une entreprise mentionnée aux 1° à 4° de l'art. L.722-1 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
    "24 : Remplacement d'un chef d'entreprise artisanale industriel, commerciale, d'une profession libérale, de son conjoint participant réellement à l'activité à titre professionnel et habituel",
    "31 : Remplacement d'un salarié en cas de départ définitif  précédant la suppression de son poste de travail",
    "22 : L'ETT et l'E.U. s'engagent, pour une durée, dans les  conditions légales à assurer un complément de formation professionnelle au salarié",
    "HANDICP : Bénéficiaire de l'obligation d'emploi de l'article L. 5212-13 CT",
    "26 : Remplacement d'un aide familial, associé d'exploit. ou leur conjoint mentionné  à l'art. L.722-10 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
  ];

  const usedMotifArray = usedMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx };
  });
  const otherMotifArray = otherMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx };
  });
  const qualificationsArray = qualifications.map((qualification, idx) => {
    return { value: qualification, label: qualification, key: idx };
  });

  const searchClient = (word) => {
    if (word.length === 0) {
      setSelectedClients([]);
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer);
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer);

      const timer2 = setTimeout(() => {
        axiosInstance
          .post("client/freelance/find-by-text", { query: word })
          .then((res) => {
            setSelectedClients(res.data);
            console.log(res.data);
          })
          .catch((e) => console.log(e));
      }, 700);
      setTimer(timer2);
    }
  };

  const cancelOrder = () => {
    if (cancelReason.length && canceller.length) {
      axiosInstance
        .patch(`order/${selectedCommande._id}`, {
          status: "annulée",
          cancelReason,
          cancelledBy: canceller,
        })
        .then(() => {
          message.success("Commande annulée!");
          setCancelReason("");
          setCanceller("");
          setIsCancelOrder(false);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setFinishReason("");
          setIsFinishOrder(false);
        });
    }
  };

  const search = (word) => {
    if (word.length === 0) {
      setOrders(originalOrders);
    }
    if (word.length >= 3) {
      axiosInstance
        .post("order/freelance/find-by-text", { query: word })
        .then((res) => setOrders(res.data));
    }
  };

  useEffect(() => {
    axiosInstance
      .get("order/freelance/en-cours")
      .then((res) => {
        setEnCoursCount(res.data);
      })
      .catch((e) => console.log(e));

    axiosInstance
      .get("order/freelance/count")
      .then((res) => {
        console.log(documentCount);
        setDocumentCount(res.data);
        console.log(res.data);
      })
      .catch((e) => {});

    axiosInstance
      .get("order/freelance")
      .then((res) => {
        const ordersData = res.data.map((order) => {
          return { ...order, loading: false };
        });
        setOrders(ordersData);
        console.log(ordersData);
        setOriginalOrders(ordersData);
      })
      .catch((e) => {
        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, []);

  const selectContact = (e) => {
    const newOrder = { ...order };
    newOrder.contact = e;
    setOrder(newOrder);
  };

  const selectQualification = (e) => {
    const newOrder = { ...order };
    newOrder.qualification = e;
    setOrder(newOrder);
  };

  const selectTypeContrat = (e) => {
    const newOrder = { ...order };
    newOrder.typeContrat = e;
    setOrder(newOrder);
  };

  const selectMotif = (e) => {
    const newOrder = { ...order };
    newOrder.otherMotif = null;
    newOrder.motif = e;
    setOrder(newOrder);
  };

  const selectOtherMotif = (e) => {
    const newOrder = { ...order };
    newOrder.motif = null;
    newOrder.otherMotif = e;
    setOrder(newOrder);
  };

  const selectJustificatif = (e) => {
    const newOrder = { ...order };
    newOrder.justificatif = e;
    setOrder(newOrder);
  };

  const selectNombreHeureHebdo = (e) => {
    const newOrder = { ...order };
    newOrder.nbHeureHebdo = e;
    setOrder(newOrder);
    console.log(newOrder);
  };

  const selectDateDebut = (e) => {
    console.log(e.getDate());
    const newOrder = { ...order };
    newOrder.startDate = e;
    setOrder(newOrder);
  };

  const selectDateFin = (e) => {
    const newOrder = { ...order };
    newOrder.endDate = e;
    setOrder(newOrder);
  };

  const selectClientName = (e) => {
    const newOrder = { ...order };
    newOrder.clientName = e;
    setOrder(newOrder);
  };

  const selectAddress = (e) => {
    const newOrder = { ...order };
    newOrder.address = e;
    setOrder(newOrder);
  };

  const selectIsVehicule = (bool) => {
    const newOrder = { ...order };
    newOrder.isVehicule = bool;
    setOrder(newOrder);
  };

  const selectConsigne = (e) => {
    const newOrder = { ...order };
    newOrder.consigne = e;
    setOrder(newOrder);
  };

  const selectPostCode = (e) => {
    const newOrder = { ...order };
    newOrder.postCode = e;
    setOrder(newOrder);
  };

  const selectTown = (e) => {
    const newOrder = { ...order };
    newOrder.town = e;
    setOrder(newOrder);
  };

  const setTask = (e) => {
    const newOrder = { ...order };
    newOrder.task = e;
    setOrder(newOrder);
  };

  const selectQuantity = (e) => {
    const newOrder = { ...order };
    if (e <= 0) e = 0;
    newOrder.quantity = e;
    setOrder(newOrder);
  };

  const selectHourlyRate = (e) => {
    const newOrder = { ...order };
    newOrder.hourlyRate = e;
    setOrder(newOrder);
  };

  const selectEpiProvider = (e) => {
    const newOrder = { ...order };
    newOrder.epiProvider = e;
    setOrder(newOrder);
  };

  const deletePrime = (idx) => {
    const newPrimes = order.primes.filter((prime, index) => idx !== index);
    setOrder({
      ...order,
      primes: newPrimes,
    });
  };

  const formatJsDate = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const showCommande = (commande, idx) => {
    const newOrders = [...orders];
    newOrders[idx].loading = true;
    setOrders(newOrders);
    axiosInstance
      .get(`/order/${commande._id}`)
      .then((res) => {
        console.log(res.data.order);
        const newCommande = {
          ...res.data.order,
          startHour: createDateFromTimeString(res.data.order.startHour),
          endHour: createDateFromTimeString(res.data.order.endHour),
        };
        newCommande.candidates = res.data.potentialCandidates.filter(
          (candidate) => {
            return (
              !newCommande.contactedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.refusedByAgence.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.acceptedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.confirmedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.submittedToClient.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.contractedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.refusedCandidates.some(
                (c) => c._id === candidate._id,
              )
            );
          },
        );
        const contact = newCommande.contact.split(" ");
        const phone = contact.pop();
        const email = contact.pop();
        const name = contact.join(" ");
        setSelectedCommande({
          ...newCommande,
          contactData: { email, phone, name },
        });
        setOpenCommandeModal(true);

        newOrders[idx].loading = false;
        setOrders(newOrders);
      })
      .catch((e) => console.log(e));
  };

  const formaterHeure = (date) => {
    let heures = date.getHours();
    let minutes = date.getMinutes();

    // Ajouter un zéro devant les nombres inférieurs à 10
    heures = heures < 10 ? "0" + heures : heures;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${heures}:${minutes}`;
  };

  const createDateFromTimeString = (timeString) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":").map(Number);

    // Create a new Date object for the current date
    const date = new Date();

    // Set the hours and minutes based on the provided time string
    date.setHours(hours, minutes, 0, 0); // Hours, minutes, seconds, milliseconds

    return date;
  };

  const validateOrder = () => {
    setIsLoadingValidate(true);
    let newOrder;
    if (isUpdate) {
      newOrder = {
        ...order,
        startHour: formaterHeure(order.startHour),
        endHour: formaterHeure(order.endHour),
      };
      axiosInstance
        .put(`/order/${order._id}`, { ...newOrder, client: order.client._id })
        .then((res) => {
          const newCommande = res.data.order;
          newCommande.candidates = res.data.potentialCandidates;
          const contact = newCommande.contact.split(" ");
          const phone = contact.pop();
          const email = contact.pop();
          const name = contact.join(" ");
          const newOrders = [{ ...newCommande, loading: false }, ...orders];
          setOrders(newOrders);
          setOrder({});
          setStep("1");
          setSelectedCommande({
            ...newCommande,
            contactData: { email, phone, name },
          });
          setOpenModal(false);
          setOpenCommandeModal(true);
          setIsUpdate(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.statusCode === 403) {
            localStorage.clear();
            navigate("/login");
          }
        })
        .finally(() => setIsLoadingValidate(false));
    } else {
      newOrder = {
        ...order,
        startHour: formaterHeure(order.startHour),
        endHour: formaterHeure(order.endHour),
      };
      axiosInstance
        .post("order", {
          ...newOrder,
          companyName: order.client.companyName,
          client: order.client._id,
          motif: order.motif || order.otherMotif,
        })
        .then((res) => {
          const newCommande = res.data.order;
          newCommande.candidates = res.data.potentialCandidates;
          const contact = newCommande.contact.split(" ");
          const phone = contact.pop();
          const email = contact.pop();
          const name = contact.join(" ");
          const newOrders = [{ ...newCommande, loading: false }, ...orders];
          setOrders(newOrders);
          setOrder({});
          setStep("1");
          setSelectedCommande({
            ...newCommande,
            contactData: { email, phone, name },
          });
          setOpenModal(false);
          setOpenCommandeModal(true);
          setIsUpdate(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.statusCode === 403) {
            localStorage.clear();
            navigate("/login");
          }
        })
        .finally(() => setIsLoadingValidate(false));
    }
  };

  const deleteEpi = () => {
    const newOrder = { ...order };
    newOrder.epis = [];
    console.log(newOrder);
    setOrder(newOrder);
  };

  const addPrime = () => {
    const newPrime = {
      prime,
      primeType,
      taux,
    };
    const primes = order.primes ? [...order.primes, newPrime] : [newPrime];
    setOrder({
      ...order,
      primes,
    });

    setPrime("");
    setPrimeType("");
    setTaux("");
  };

  const addEpi = (image) => {
    const epis = order.epis ? order.epis : [];
    if (!epis.includes(image.description)) epis.push(image.description);
    else {
      const index = epis.findIndex((item) =>
        _.isEqual(item, image.description),
      );

      if (index !== -1) {
        epis.splice(index, 1);
      }
    }
    const newOrder = { ...order, epis };
    setOrder(newOrder);
    console.log(newOrder);
  };

  const selectClient = (e) => {
    const client = e;
    const newOrder = order;
    newOrder.client = client;
    setOrder(newOrder);
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ];

    const clientContactsArray = client.contacts.map((contact, idx) => {
      return {
        value: `${contact.firstname} ${contact.lastname} ${contact.email} ${contact.mobile}`,
        label: `${contact.firstname} ${contact.lastname} ${contact.email} ${contact.mobile}`,
        key: idx,
      };
    });

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      };
    });
    setClientAdressesArray(clientAddressesArray);
    setClientContactsArray(clientContactsArray);
    setSelectedClient(e);
  };

  const selectStep = (step, direction) => {
    if (step === "4" && order.typeContrat !== "Interim" && direction === "f")
      step = "6";

    if (step === "5" && order.typeContrat !== "Interim" && direction === "b")
      step = "3";
    if (
      step === "5" &&
      (order.typeContrat === "CDI" || order.typeContrat === "CDD") &&
      direction === "b"
    )
      step = "3";

    if (step === "12" && order.typeContrat !== "Interim" && direction === "f")
      step = "13";

    if (step === "12" && order.typeContrat !== "Interim" && direction === "b")
      step = "11";

    if (step === "17" && !order.epis.length) {
      validateOrder();
    }
    setStep(step);
  };

  /*   const handleDrag = (e, ui) => {
    e.stopPropagation()

    const id = ui.node.id
    // Get the position of the draggable element
    const elementX = e.srcElement.getBoundingClientRect().x
    const elementY = e.srcElement.getBoundingClientRect().y
    // Get the position and dimensions of the other div
    const otherDiv = document.getElementById('contactedCandidat')
    const { x, y, left, top, right, bottom } = otherDiv.getBoundingClientRect()

    // Check if the draggable element is inside the other div
    if (
      elementX > left &&
      elementX < right &&
      elementY > top &&
      elementY < bottom
    ) {
      let availableCandidat = selectedCommande.candidates
      let candidate = availableCandidat.find((candidat) => candidat._id === id)
      availableCandidat = availableCandidat.filter(
        (candidat) => candidat._id !== id,
      )
      axiosInstance
        .put(`/order/${selectedCommande._id}`, {
          contactedCandidates: [
            ...selectedCommande.contactedCandidates.map((c) => c._id),
            candidate._id,
          ],
        })
        .then((res) => {
          console.log(res.data)
          message.success('Modifications enregistrées')
          setSelectedCommande({
            ...selectedCommande,
            candidates: availableCandidat,
            contactedCandidates: [
              ...selectedCommande.contactedCandidates,
              candidate,
            ],
          })
        })
        .catch((e) => console.log(e))
    }
  } */

  /*   const handleDragContacted = (e, ui) => {
    const id = ui.node.id
    // Get the position of the draggable element
    const elementX = e.srcElement.getBoundingClientRect().x
    const elementY = e.srcElement.getBoundingClientRect().y
    // Get the position and dimensions of the other div
    const otherDiv = document.getElementById('proposedCandidat')
    const { x, y, left, top, right, bottom } = otherDiv.getBoundingClientRect()

    // Check if the draggable element is inside the other div
    if (
      elementX > left &&
      elementX < right &&
      elementY > top &&
      elementY < bottom
    ) {
      let contactedCandidates = selectedCommande.contactedCandidates
      let candidate = contactedCandidates.find(
        (candidat) => candidat._id === id,
      )
      contactedCandidates = contactedCandidates.filter(
        (candidat) => candidat._id !== id,
      )
      axiosInstance
        .put(`/order/${selectedCommande._id}`, {
          contactedCandidates,
          submittedToClient: [
            ...selectedCommande.submittedToClient.map((c) => c._id),
            candidate._id,
          ],
        })
        .then((res) => {
          console.log(res.data)
          message.success('Modifications enregistrées')
          setSelectedCommande({
            ...selectedCommande,
            contactedCandidates,
            submittedToClient: [
              ...selectedCommande.submittedToClient,
              candidate,
            ],
          })
        })
        .catch((e) => console.log(e))
    }
  }
 */

  const selectReason = (e) => {
    console.log(e);
  };

  const setAdressFromSiege = (client) => {
    const newOrder = { ...order };
    newOrder.clientName = client.companyName;
    newOrder.address = client.address;
    newOrder.postCode = client.postCode;
    newOrder.town = client.town;
    setOrder(newOrder);
  };

  const setAdressFromLivraison = (client) => {
    const newOrder = { ...order };
    newOrder.clientName = client.companyName;
    newOrder.address = client.addressLivraison;
    newOrder.postCode = client.postCodeLivraison;
    newOrder.town = client.townLivraison;
    setOrder(newOrder);
  };

  const setAdressFromFacturation = (client) => {
    const newOrder = { ...order };
    newOrder.clientName = client.companyName;
    newOrder.address = client.addressFacturation;
    newOrder.postCode = client.postCodeFacturation;
    newOrder.town = client.townFacturation;
    setOrder(newOrder);
  };

  const addWorkedDay = (day) => {
    let days = order.workedDays;
    if (days.includes(day)) {
      console.log(days, "1");
      days = days.filter((d) => d !== day);
      console.log(days, "2");
    } else {
      days.push(day);
    }
    setOrder({ ...order, workedDays: days });
  };

  const clientArray = clients.map((client) => {
    return {
      value: client._id,
      label: `${client.companyName} - ${client.address} ${client.postCode} ${client.town}`,
    };
  });

  return (
    <div className="">
      <FilterOrder
        open={isFilterOrder}
        originalOrders={originalOrders}
        setOrders={setOrders}
        setIsOpen={setIsFilterOrder}
      />
      {selectedCommande && (
        <div>
          <ModalDetail
            order={order}
            setOrder={setOrder}
            setOpenModal={setOpenModal}
            openCommandeModal={openCommandeModal}
            setOpenCommandeModal={setOpenCommandeModal}
            selectedCommande={selectedCommande}
            setSelectedCommande={setSelectedCommande}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            isFinishOrder={isFinishOrder}
            setIsFinishOrder={setIsFinishOrder}
          />
        </div>
      )}

      {isCommentModal && (
        <Modal
          open={isCommentModal}
          footer={null}
          onCancel={() => {
            setIsCommentModal((bool) => false);
          }}
        >
          <div>
            <Input
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Commentaire"
              onChange={(e) => setComment(e.target.value)}
            />

            <div className="flex items-center justify-center">
              <button
                onClick={finishOrder}
                className="w-2/4 rounded-md p-2 bg-purple-400 text-white"
              >
                <span>Valider</span>
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isCancelOrder && (
        <Modal
          open={isCancelOrder}
          footer={null}
          onCancel={() => {
            setIsCancelOrder((bool) => false);
          }}
        >
          <div className="h-96 flex flex-col items-center justify-center space-y-6">
            <div className="w-4/5 flex items-center justify-center">
              <Select
                className="w-2/4  my-2 ring-0"
                showSearch="true"
                onChange={(e) => setCanceller(e)}
                value={canceller.length ? canceller : null}
                optionFilterProp="children"
                placeholder="Annulée par"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "le client",
                    label: "le client",
                    key: "le client",
                  },
                  {
                    value: "l'agence",
                    label: "l'agence",
                    key: "l'agence",
                  },
                ]}
              />
            </div>

            <div className="w-4/5 flex items-center justify-center">
              <Select
                className="w-2/4  my-2 ring-0"
                showSearch="true"
                value={cancelReason.length ? cancelReason : null}
                onChange={(e) => setCancelReason(e)}
                optionFilterProp="children"
                placeholder="Raison de l'annulation"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "concurrence",
                    label: "concurrence",
                    key: "concurrence",
                  },
                  {
                    value: "plus de besoin",
                    label: "plus de besoin",
                    key: "plus de besoin",
                  },
                  {
                    value: "trop cher",
                    label: "trop cher",
                    key: "trop cher",
                  },
                  {
                    value: "ne repond plus",
                    label: "ne repond plus",
                    key: "ne repond plus",
                  },
                  {
                    value: "pas de candidat à proposer",
                    label: "pas de candidat à proposer",
                    key: "pas de candidat à proposer",
                  },
                  {
                    value: "atradius refusé",
                    label: "atradius refusé",
                    key: "atradius refusé",
                  },
                ]}
              />
            </div>

            <button
              onClick={cancelOrder}
              className="w-2/4 rounded-md p-2 bg-purple-400 text-white"
            >
              Annuler
            </button>
          </div>
        </Modal>
      )}

      {isFinishOrder && (
        <Modal
          open={isFinishOrder}
          footer={null}
          onCancel={() => {
            setIsFinishOrder((bool) => false);
          }}
        >
          <div className="my-10 flex flex-col items-center justify-center space-y-6">
            <div className="w-4/5 flex items-center justify-center">
              <Select
                className="w-2/4  my-2 ring-0"
                showSearch="true"
                value={finishReason.length ? finishReason : null}
                onChange={(e) => setFinishReason(e)}
                optionFilterProp="children"
                placeholder="Raison"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "Commande gagnée",
                    label: "Commande gagnée",
                    key: "Commande gagnée",
                  },
                  {
                    value: "Commande perdue",
                    label: "Commande perdue",
                    key: "Commande perdue",
                  },
                ]}
              />
            </div>
          </div>

          {finishReason === "Commande perdue" && (
            <div className="flex flex-col items-center justify-center space-y-6">
              <div className="w-4/5 flex items-center justify-center">
                <Select
                  className="w-2/4  my-2 ring-0"
                  showSearch="true"
                  onChange={(e) => setCanceller(e)}
                  value={canceller.length ? canceller : null}
                  optionFilterProp="children"
                  placeholder="Annulée par"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "le client",
                      label: "le client",
                      key: "le client",
                    },
                    {
                      value: "l'agence",
                      label: "l'agence",
                      key: "l'agence",
                    },
                  ]}
                />
              </div>

              <div className="w-4/5 flex items-center justify-center">
                <Select
                  className="w-2/4  my-2 ring-0"
                  showSearch="true"
                  value={cancelReason.length ? cancelReason : null}
                  onChange={(e) => setCancelReason(e)}
                  optionFilterProp="children"
                  placeholder="Raison de l'annulation"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "concurrence",
                      label: "concurrence",
                      key: "concurrence",
                    },
                    {
                      value: "plus de besoin",
                      label: "plus de besoin",
                      key: "plus de besoin",
                    },
                    {
                      value: "trop cher",
                      label: "trop cher",
                      key: "trop cher",
                    },
                    {
                      value: "ne repond plus",
                      label: "ne repond plus",
                      key: "ne repond plus",
                    },
                    {
                      value: "pas de candidat à proposer",
                      label: "pas de candidat à proposer",
                      key: "pas de candidat à proposer",
                    },
                    {
                      value: "atradius refusé",
                      label: "atradius refusé",
                      key: "atradius refusé",
                    },
                  ]}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-center">
            <button
              onClick={finishOrder}
              className="w-2/4 rounded-md p-2 bg-purple-400 text-white"
            >
              {finishReason === "Commande perdue" && <span>Annuler</span>}
              {finishReason !== "Commande perdue" && <span>Terminer</span>}
            </button>
          </div>
        </Modal>
      )}

      {selectedCandidate && (
        <OrderCandidatModal
          setSelectedCandidate={setSelectedCandidate}
          candidate={selectedCandidate}
        />
      )}
      <Modal
        width={1000}
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
          setStep("1");
        }}
        footer={null}
      >
        <div className="h-[600px]">
          {isLoadingValidate && (
            <div className="w-full h-screen  absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
              <Puff
                height="80"
                width="80"
                radius={1}
                color="#4fa94d"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          {step === "1" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold  text-center text-3xl">Client</p>

                <Input
                  className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                  placeholder="Client"
                  onChange={(e) => searchClient(e.target.value)}
                />
                {selectedClients.length > 0 && (
                  <div className="border flex flex-col space-y-2 uppercase font-semibold border-gray-300 rounded-md mt-4">
                    {selectedClients.map((client, idx) => {
                      return (
                        <p
                          onClick={() => selectClient(client)}
                          key={idx}
                          className={`flex items-center space-x-1  p-2 ${
                            selectedClient?._id === client._id
                              ? "bg-purple-400"
                              : ""
                          }`}
                        >
                          <span>{client.companyName}</span>
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="flex items-center justify-center ">
                <button
                  disabled={!order.client}
                  onClick={() => selectStep("2", "f")}
                  className={`${
                    !order.client
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "2" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">Contact</p>

                <Select
                  className="w-full mt-6"
                  onChange={(e) => selectContact(e)}
                  value={order.contact ? `${order.contact}` : null}
                  optionFilterProp="children"
                  placeholder="Contact"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientContactsArray}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("1", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("3", "f")}
                  className={`${
                    !order.contact
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.contact}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "3" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Type de contrat
                </p>

                <Select
                  className="w-full mt-6"
                  showSearch
                  onChange={(e) => selectTypeContrat(e)}
                  value={order.typeContrat ? `${order.typeContrat}` : null}
                  optionFilterProp="children"
                  placeholder="Type de contrat"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    { value: "Interim", label: "Interim", key: "Interim" },
                    { value: "Vacation", label: "Vacation", key: "Vacation" },
                    { value: "CDD", label: "CDD", key: "CDD" },
                    { value: "CDI", label: "CDI", key: "CDI" },
                  ]}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("2", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("4", "f")}
                  className={`${
                    !order.typeContrat
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.typeContrat}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "4" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Motif de recours
                </p>

                <div>
                  <p>Les plus utilisés</p>
                  <Select
                    className="w-full mt-6"
                    showSearch
                    onChange={(e) => selectMotif(e)}
                    value={order.motif ? `${order.motif}` : null}
                    optionFilterProp="children"
                    placeholder="Motif de recours"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={usedMotifArray}
                  />
                </div>

                <div>
                  <p>Les autres motifs</p>
                  <Select
                    className="w-full mt-6"
                    showSearch
                    onChange={(e) => selectOtherMotif(e)}
                    value={order.otherMotif ? `${order.otherMotif}` : null}
                    optionFilterProp="children"
                    placeholder="Motif de recours"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={otherMotifArray}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("3", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("5", "f")}
                  className={`${
                    !order.motif && !order.otherMotif
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.motif && !order.otherMotif}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "5" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Développer le motif
                </p>

                <div>
                  <p className="mt-6 text-lg"> {order.motif} </p>
                  <Input
                    onChange={(e) => selectJustificatif(e.target.value)}
                    value={order.justificatif ? `${order.justificatif}` : null}
                    className="rounded-md border border-gray-300 mb-2 mt-6"
                    placeholder="Justificatif"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("4", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("6", "f")}
                  className={`${
                    !order.justificatif
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.justificatif}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "6" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                {order.typeContrat !== "Vacation" && (
                  <p className="font-semibold text-center text-3xl">
                    Date de la mission
                  </p>
                )}

                {order.typeContrat === "Vacation" && (
                  <p className="font-semibold text-center text-3xl">
                    Date de la vacation
                  </p>
                )}

                <div className="flex items-center my-6 space-x-2 justify-center">
                  <DatePicker
                    onChange={(e) => selectDateDebut(e.$d)}
                    value={order.startDate ? dayjs(order?.startDate) : null}
                    className="w-1/2 h-10"
                    format="DD/MM/YYYY"
                    placeholder="Date de début"
                  />
                  {order.typeContrat !== "CDI" && (
                    <DatePicker
                      onChange={(e) => selectDateFin(e.$d)}
                      value={order.endDate ? dayjs(order?.endDate) : null}
                      className="w-1/2 h-10"
                      format="DD/MM/YYYY"
                      placeholder="Date de fin"
                    />
                  )}
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("5", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {order.typeContrat !== "CDI" && (
                  <button
                    onClick={() => selectStep("7", "f")}
                    className={`${
                      !order.startDate || !order.endDate
                        ? "bg-gray-200 text-gray-600"
                        : "bg-purple-500 text-white"
                    } rounded-md p-4 `}
                    disabled={!order.startDate || !order.endDate}
                  >
                    Suivant
                  </button>
                )}

                {order.typeContrat === "CDI" && (
                  <button
                    onClick={() => selectStep("7", "f")}
                    className={`${
                      !order.startDate
                        ? "bg-gray-200 text-gray-600"
                        : "bg-purple-500 text-white"
                    } rounded-md p-4 `}
                    disabled={!order.startDate}
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          )}

          {step === "7" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Horaire de la mission
                </p>

                <div className="flex items-center my-6 space-x-2">
                  <DesktopTimePicker
                    value={order.startHour && dayjs(order.startHour)}
                    onChange={(newValue) => {
                      if (newValue) {
                        setOrder({ ...order, startHour: newValue.$d });
                      }
                    }}
                    format="HH:mm"
                    className="w-1/2 outline-none"
                    placeholder="Heure de début"
                  />
                  <DesktopTimePicker
                    format="HH:mm"
                    value={order.endHour && dayjs(order.endHour)}
                    className="w-1/2 outline-none focus:outline-none hover:outline-none"
                    onChange={(newValue) => {
                      if (newValue) {
                        setOrder({ ...order, endHour: newValue.$d });
                      }
                    }}
                    placeholder="Heure de fin"
                  />
                </div>
                <div>
                  <p className="font-semibold text-center text-3xl">
                    Jours travaillés
                  </p>
                  <div className="flex items-center justify-around space-x-1 mt-6">
                    <p
                      onClick={() => addWorkedDay("Lundi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Lundi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Lundi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Mardi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Mardi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Mardi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Mercredi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Mercredi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Mercredi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Jeudi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Jeudi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Jeudi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Vendredi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Vendredi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Vendredi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Samedi")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Samedi")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Samedi
                    </p>
                    <p
                      onClick={() => addWorkedDay("Dimanche")}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes("Dimanche")
                          ? "bg-purple-400 text-white"
                          : "border border-gray-500"
                      }`}
                    >
                      Dimanche
                    </p>
                  </div>

                  <div>
                    <Input
                      onChange={(e) => selectNombreHeureHebdo(e.target.value)}
                      value={
                        order.nbHeureHebdo ? `${order.nbHeureHebdo}` : null
                      }
                      className="rounded-md border border-gray-300 mb-2 mt-6"
                      placeholder="Nombre heures hebdomadaire"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("6", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("8", "f")}
                  disabled={!order.startHour || !order.endHour}
                  className={`${
                    !order.startHour || !order.endHour
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "8" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Lieu de la mission
                </p>
                <div className="flex space-x-2 items-center justify-around mt-6">
                  <p
                    onClick={() => setAdressFromSiege(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500
                "
                  >
                    Recopier l'adresse du siège
                  </p>
                  <p
                    onClick={() => setAdressFromLivraison(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500
                "
                  >
                    Recopier l'adresse de livraison
                  </p>
                  <p
                    onClick={() => setAdressFromFacturation(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500"
                  >
                    Recopier l'adresse de facturation
                  </p>
                </div>
                <div className="flex flex-col items-center my-6 space-y-3">
                  <Input
                    onChange={(e) => selectClientName(e.target.value)}
                    value={order.clientName ? `${order.clientName}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Nom du client final"
                  />
                  <Input
                    onChange={(e) => selectAddress(e.target.value)}
                    value={order.address ? `${order.address}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Adresse"
                  />
                  <Input
                    onChange={(e) => selectPostCode(e.target.value)}
                    value={order.postCode ? `${order.postCode}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Code postal"
                  />
                  <Input
                    onChange={(e) => selectTown(e.target.value)}
                    value={order.town ? `${order.town}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Ville"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("7", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("9", "f")}
                  className={`${
                    !order.clientName ||
                    !order.address ||
                    !order.postCode ||
                    !order.town
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={
                    !order.clientName ||
                    !order.address ||
                    !order.postCode ||
                    !order.town
                  }
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "9" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">Poste</p>

                <div className="flex flex-col items-center my-6 space-y-3">
                  <Select
                    className="w-full mt-6"
                    showSearch
                    onChange={(e) => selectQualification(e)}
                    value={
                      order.qualification ? `${order.qualification}` : null
                    }
                    optionFilterProp="children"
                    placeholder="Poste"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={qualificationsArray}
                  />
                  <p>Quantite</p>
                  <p className="flex items-center space-x-4">
                    <RemoveCircleOutlineRounded
                      onClick={() => selectQuantity(order.quantity - 1)}
                    />{" "}
                    <span className="text-xl">{order.quantity}</span>
                    <AddCircleOutlineIcon
                      onClick={() => selectQuantity(order.quantity + 1)}
                    />{" "}
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("8", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("10", "f")}
                  className={`${
                    !order.qualification
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.qualification}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "10" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Renseigner le taux horaire
                </p>

                <div className="flex flex-col items-center my-6 space-y-3">
                  <Input
                    onChange={(e) => selectHourlyRate(e.target.value)}
                    value={order.hourlyRate ? `${order.hourlyRate}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Taux horaire"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("9", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("11", "f")}
                  className={`${
                    !order.hourlyRate
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.hourlyRate}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "11" && (
            <div className="flex flex-col h-full justify-between ">
              <div>
                <p className="font-semibold mb-6 text-center text-3xl">
                  Renseigner primes/indemnités
                </p>

                <div className="flex items-center space-x-4 mb-4">
                  <div className="w-1/2 flex flex-col space-y-1">
                    <Select
                      className="w-full mt-6"
                      onChange={(e) => setPrime(e)}
                      value={prime}
                      optionFilterProp="children"
                      placeholder="Primes"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        { value: "Primes", label: "Primes", key: "Primes" },
                        {
                          value: "Indemnités",
                          label: "Indemnités",
                          key: "Indemnités",
                        },
                      ]}
                    />

                    <Input
                      onChange={(e) => setPrimeType(e.target.value)}
                      value={primeType}
                      className="rounded-md border border-gray-300 mb-2"
                      placeholder="Type de prime"
                    />

                    <Input
                      onChange={(e) => setTaux(e.target.value)}
                      value={taux}
                      className="rounded-md border border-gray-300 mb-2"
                      placeholder="Taux ou montant"
                    />
                  </div>

                  <div className=" w-1/2 flex items-center justify-start">
                    <button
                      onClick={addPrime}
                      className="my-2 rounded-md  bg-purple-500 p-2 text-white"
                    >
                      <ShoppingCartCheckoutIcon />
                    </button>
                  </div>
                </div>

                <div>
                  <div className="flex items-center space-x-2 uppercase my-4">
                    <p className="w-1/4 text-center">Prime</p>
                    <p className="w-1/4 text-center">Type de prime</p>
                    <p className="w-1/4 text-center">Taux ou montant </p>
                    <p className="w-1/4 text-center"></p>
                  </div>

                  {order.primes.map((prime, idx) => {
                    return (
                      <div key={idx} className="flex items-center space-x-2">
                        <p className="w-1/4 text-center">{prime.prime}</p>
                        <p className="w-1/4 text-center">{prime.primeType}</p>
                        <p className="w-1/4 text-center">{prime.taux}</p>
                        <p
                          onClick={() => deletePrime(idx)}
                          className="w-1/4 text-center"
                        >
                          <DeleteForeverOutlined />
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("10", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("12", "f")}
                  className="bg-purple-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "12" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Consignes particulières
                </p>

                <div className="flex items-center my-6">
                  <Select
                    className="w-full mt-6"
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => selectConsigne(e)}
                    value={order.consigne ? order.consigne : null}
                    placeholder="Consignes"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "0 : Aucune",
                        label: "0 : Aucune",
                        key: "0",
                      },
                      {
                        value:
                          "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                        label:
                          "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                        key: "1",
                      },
                      {
                        value:
                          "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        label:
                          "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        key: "2",
                      },
                      {
                        value:
                          "3 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        label:
                          "3 :  Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        key: "3",
                      },
                      {
                        value:
                          "4 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. L'assurance du véhicule est à la charge de l'entreprise utilisatrice.",
                        label:
                          "4 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. L'assurance du véhicule est à la charge de l'entreprise utilisatrice.",
                        key: "4",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("11", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("13", "f")}
                  className={`${
                    !order.consigne
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                  disabled={!order.consigne}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "13" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Etre vehiculé(e) ?
                </p>

                <div className="flex items-center my-6 space-x-10 justify-center">
                  <p className="flex items-center space-x-1">
                    <Checkbox
                      checked={order.isVehicule}
                      onChange={() => selectIsVehicule(true)}
                    />
                    <span>OUI</span>
                  </p>
                  <p className="flex items-center space-x-1">
                    <Checkbox
                      checked={!order.isVehicule}
                      onChange={() => selectIsVehicule(false)}
                    />
                    <span>NON</span>
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("12", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep("14", "f")}
                  className="bg-purple-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === "14" && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Quelles sont les tâches/missions ?
                </p>

                <div className="flex items-center my-6 space-x-10 justify-center">
                  <TextArea
                    className="h-20"
                    onChange={(e) => setTask(e.target.value)}
                    value={order.task ? order.task : null}
                    placeholder="Preciser..."
                  />
                </div>
                <p className="text-red-500 text-xl font-semibold text-center">
                  /!\ Ne pas mettre le nom du client !{" "}
                </p>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("13", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {order.typeContrat === "Interim" && (
                  <button
                    onClick={() => selectStep("15", "f")}
                    className={`${
                      !order.task
                        ? "bg-gray-200 text-gray-600"
                        : "bg-purple-500 text-white"
                    } rounded-md p-4 `}
                    disabled={!order.task}
                  >
                    Suivant
                  </button>
                )}

                {order.typeContrat !== "Interim" && (
                  <button
                    onClick={validateOrder}
                    className={`${
                      !order.task
                        ? "bg-gray-200 text-gray-600"
                        : "bg-purple-500 text-white"
                    } rounded-md p-4 `}
                    disabled={!order.task}
                  >
                    Valider la commande
                  </button>
                )}
              </div>
            </div>
          )}

          {step === "15" && (
            <div className="flex flex-col h-full justify-between mt-6">
              {order.typeContrat === "Interim" && (
                <div>
                  <p className="font-semibold text-center text-3xl mb-6">
                    EPI nécessaires
                  </p>

                  <div className="gap-2 grid grid-cols-5">
                    <p
                      onClick={deleteEpi}
                      className={`relative border flex items-center justify-center border-gray-500 ${
                        order.epis?.length === 0 ? "bg-ultramarine-200" : ""
                      }`}
                    >
                      {order.epis?.length ? (
                        <img className="w-36 h-36" src={epinone} />
                      ) : (
                        <img className="w-36 h-36" src={epinoneselected} />
                      )}
                    </p>
                    {images.map((image) => {
                      return (
                        <p
                          onClick={() => addEpi(image)}
                          className={`relative border flex items-center justify-center border-gray-500 ${
                            order.epis?.includes(image.description)
                              ? "bg-ultramarine-200"
                              : ""
                          } `}
                        >
                          {order.epis?.includes(image.description) ? (
                            <img className="w-36 h-36" src={image.selected} />
                          ) : (
                            <img className="w-36 h-36" src={image.name} />
                          )}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}

              {order.typeContrat !== "Interim" && (
                <div>
                  <p className="my-10 font-semibold text-center text-2xl">
                    Valider la commande
                  </p>
                </div>
              )}

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("14", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {((order.typeContrat === "Interim" && !order.epis.length) ||
                  order.typeContrat !== "Interim") && (
                  <button
                    onClick={validateOrder}
                    className={`bg-purple-500 text-white rounded-md p-4 `}
                  >
                    Valider la commande
                  </button>
                )}
                {order.epis.length > 0 && (
                  <button
                    onClick={() => selectStep("16", "f")}
                    className="bg-purple-500 rounded-md p-4 text-white"
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          )}

          {step === "16" && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Qui fournit les EPI ?
                </p>
              </div>

              <div className="flex text-2xl font-bold items-center my-6 space-x-10 justify-center">
                <p
                  onClick={() => selectEpiProvider("EU")}
                  className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                    order.epiProvider === "EU"
                      ? "text-white bg-black"
                      : "text-black border border-black"
                  }`}
                >
                  {" "}
                  EU
                </p>
                <p
                  onClick={() => selectEpiProvider("AGENCE")}
                  className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                    order.epiProvider === "AGENCE"
                      ? "text-white bg-black"
                      : "text-black border border-black"
                  } `}
                >
                  {" "}
                  AGENCE
                </p>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep("15", "b")}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={validateOrder}
                  className={`${
                    !order.epiProvider
                      ? "bg-gray-200 text-gray-600"
                      : "bg-purple-500 text-white"
                  } rounded-md p-4 `}
                >
                  Valider la commande
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <div className="mb-10 mt-10 font-Inter flex items-center justify-between">
        <p className="text-2xl font-semibold uppercase">Commandes</p>

        <div className="flex justify-end ml-20">
          <div className="flex items-center space-x-2">
            {isSearch && (
              <Input
                className="w-80  rounded-md border border-gray-200 focus:outline-none"
                placeholder="Recherche"
                onChange={(e) => search(e.target.value)}
                style={{ outline: "none" }}
              />
            )}
            <SearchIcon
              onClick={() => setIsSearch(!isSearch)}
              className="text-gray-500 text-3xl"
            />
            <FilterListOutlinedIcon
              onClick={() => setIsFilterOrder(true)}
              className="text-gray-500 text-3xl"
            />
            <div
              onClick={() => setOpenModal(true)}
              className="border hover:cursor-pointer bg-purple-400 rounded-md flex items-center"
            >
              <AddOutlined className="hover:cursor-pointer text-black" />
              <p className="text-black p-2">Ajouter une commande</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex hover:cursor-pointer my-6 mb-16 items-center w-fit border rounded-lg font-semibold  border-gray-300 bg-[#F7F9FC] ">
        <p
          onClick={() => {
            setFilter("Toutes");
            filterOrders("Toutes");
          }}
          className={`${
            filter === "Toutes" && "bg-purple-400"
          } text-black p-2  text-center      w-fit  border-r  border-r-gray-300`}
        >
          Toutes
        </p>
        <p
          onClick={() => {
            setFilter("En cours");
            filterOrders("En cours");
          }}
          className={`${
            filter === "En cours" && "bg-purple-400"
          } p-2 text-black w-fit  border-r  text-center  border-r-gray-300`}
        >
          ({enCoursCount}) En cours
        </p>
        <p
          onClick={() => {
            setFilter("Aucun candidats");
            filterOrders("Aucun candidat");
          }}
          className={`${
            filter === "Aucun candidats" && "bg-purple-400"
          } p-2 text-black w-fit  border-r  text-center  border-r-gray-300`}
        >
          Aucun candidat
        </p>
        <p
          onClick={() => {
            setFilter("Candidats à contacter");
            filterOrders("Candidat à contacter");
          }}
          className={`${
            filter === "Candidats à contacter" && "bg-purple-400"
          } p-2  w-fit  border-r text-center text-black border-r-gray-300`}
        >
          Candidats à contacter
        </p>
        <p
          onClick={() => {
            setFilter("Candidats acceptés");
            filterOrders("Candidat accepté");
          }}
          className={`${
            filter === "Candidats acceptés" && "bg-purple-400"
          } p-2 border-r w-fit text-center text-black border-r-gray-300`}
        >
          Candidats acceptés
        </p>
        <p
          onClick={() => {
            setFilter("Contrat à faire");
            filterOrders("Contrat à faire");
          }}
          className={`${
            filter === "Contrat à faire" && "bg-purple-400"
          } p-2  border-r    w-fit  text-center text-black border-r-gray-300`}
        >
          Contrat à faire
        </p>
        <p
          onClick={() => {
            setFilter("Terminée");
            filterOrders("terminee");
          }}
          className={`${
            filter === "Terminée" && "bg-purple-400"
          } p-2  border-r text-center w-fit     text-black border-r-gray-300`}
        >
          Terminée
        </p>
        <p
          onClick={() => {
            setFilter("Annulée");
            filterOrders("annulée");
          }}
          className={`${
            filter === "Annulée" && "bg-purple-400"
          } p-2  border-r text-center   w-fit     text-black border-r-gray-300`}
        >
          Annulée
        </p>
      </div>

      <div className="border border-gray-300 rounded-md">
        <div className="flex items-center font-semibold space-x-4 px-4 text-sm py-3">
          <p className="w-[14%] text-center">POSTE</p>
          <p className="w-[14%] text-center">CLIENT</p>
          <p className="w-[14%] text-center">DATE DE CONTRAT</p>
          <p className="w-[14%] text-center">LIEU DE MISSION</p>
          <p className="w-[14%] text-center">STATUT</p>
          <p className="w-[14%] text-center">COMMENTAIRE (EN COURS)</p>
          <p className="w-[14%] text-center">ACTION</p>
        </div>

        <div className="flex flex-col ">
          {orders.map((order, idx) => {
            return (
              <div
                key={idx}
                className="flex space-x-4  items-center drop-shadow-2xl text-xs   bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
              >
                {order.loading && (
                  <p className="w-[14%] text-center">
                    <Spin />
                  </p>
                )}
                {!order.loading && (
                  <div
                    onClick={() => showCommande(order, idx)}
                    className="w-[14%] text-center flex flex-col items-center justify-center text-sm"
                  >
                    <p>{order?.qualification}</p>
                    {order.typeContrat === "Interim" && (
                      <p className="font-semibold text-blue-500">
                        ({order.typeContrat})
                      </p>
                    )}
                    {order.typeContrat === "CDD" && (
                      <p className="font-semibold text-orange-500">
                        ({order.typeContrat})
                      </p>
                    )}
                    {order.typeContrat === "CDI" && (
                      <p className="font-semibold text-orange-500">
                        ({order.typeContrat})
                      </p>
                    )}
                    {order.typeContrat === "Vacation" && (
                      <p className="font-semibold text-purple-500">
                        ({order.typeContrat})
                      </p>
                    )}
                  </div>
                )}
                <div
                  onClick={() => showCommande(order, idx)}
                  className="w-[14%] flex flex-col items-center justify-center"
                >
                  <p className="text-center w-full">
                    {order.client?.companyName}
                  </p>
                </div>

                {order.typeContrat !== "CDI" && (
                  <p
                    onClick={() => showCommande(order, idx)}
                    className="w-[14%] text-center  flex flex-col items-center"
                  >
                    <span>Du {formatJsDate(order?.startDate)}</span>

                    <span>au {formatJsDate(order?.endDate)}</span>
                  </p>
                )}
                {order.typeContrat == "CDI" && (
                  <p
                    onClick={() => showCommande(order, idx)}
                    className="w-[14%] text-center "
                  >
                    {formatJsDate(order?.startDate)}
                  </p>
                )}
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[14%] text-center "
                >
                  {order?.town}
                </p>
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[14%] text-center "
                >
                  {order?.status}
                </p>
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[14%] text-center "
                >
                </p>
                <p className="w-[14%] flex items-center justify-center ">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-sm space-y-3">
                        <p
                          onClick={() => duplicateOrder(order, false)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img className="w-4" src={dupliquer} />

                          <span>Dupliquer</span>
                        </p>
                        <p
                          onClick={() => duplicateOrder(order, true)}
                          className="hover:bg-gray-200 flex space-x-2 items-center p-1 hover:cursor-pointer"
                        >
                          <img className="w-4" src={modifier} />
                          <span>Modifier</span>
                        </p>
                        <p
                          onClick={() => {
                            setSelectedCommande(order);
                            setIsFinishOrder(true);
                          }}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img className="w-4" src={terminer} />

                          <span>Terminer</span>
                        </p>
                        <p
                          onClick={(e) => deleteOrder(e, order._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img className="w-4" src={supprimer} />

                          <span>Supprimer</span>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {isOriginal && (
        <div className="flex w-full justify-center mr-2">
          <Pagination
            className="mt-6"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={20}
          />
        </div>
      )}
    </div>
  );
};

export default Commandes;
