import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { Helmet } from "react-helmet";
import InactivityLogout from "./components/utils/InactivityLogout";
import HomePage from "./pages/HomePage";
import Activate from "./pages/Activate";
import ForgotPassword from "./pages/ForgotPassword";
export default function App() {
  return (
    <div>
      <InactivityLogout />
      <Helmet>
        <title>J'M INTERIM</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/activate/:token" element={<Activate />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
      </Routes>
    </div>
  );
}
