export const habilitationsList = [
  "ADR - Accord relatif au transport international des marchandises dangereuses par route",
  "Agent de sécurité",
  "AIPR - Autorisation d'Intervention à Proximité des Réseaux",
  "Annonceur sentinelle",
  "Attestation d’aptitude à la manipulation des fluides frigorigènes (chauffagistes)",
  "Consignation des énergies",
  "ESP (équipements sous pression) et ATEX (atmosphère explosive)",
  "Ferroviaire",
  "Gestion des déchets et environnement",
  "Habilitation caténaire CH1-CB1 (percheur)",
  "Habilitation electrique B0 (éxécutant non électricien)",
  "Habilitation electrique B1 (éxécutant electricien)",
  "Habilitation electrique B1T (éxécutant electricien)",
  "Habilitation electrique B2 (chargé de travaux)",
  "Habilitation electrique B2T (chargé de travaux)",
  "Habilitation electrique BC (chargé de consignation)",
  "Habilitation electrique BE (manoeuvre)",
  "Habilitation electrique BR - Hors tension (chargé d'interventions générales)",
  "Habilitation electrique BR - Sous tension (chargé d'interventions générales)",
  "Habilitation electrique BS (chargé d'interventions élémentaires)",
  "Habilitation electrique H0 (éxécutant non électricien)",
  "Habilitation electrique H1 (éxécutant electricien)",
  "Habilitation electrique H1T (éxécutant electricien)",
  "Habilitation electrique H2 (chargé de travaux)",
  "Habilitation electrique H2T (chargé de travaux)",
  "Habilitation electrique HC (chargé de consignation)",
  "Habilitation electrique HE (manoeuvre)",
  "Habilitation gaz",
  "Habilitation Hydraulique (HY0-HY1-HY2-HY3-HYR)",
  "Habilitation mécanique EDF (M0-M1-ME-M2-MR-MC)",
  "Prévention & secourisme",
  "Risques chimiques",
  "Sécurité machines",
  "Sensibilisation Légionelle",
  "Soudage à l'arc",
  "Soudage MIG/MAG",
  "Soudage TIG",
  "Soudage thermoplastique PE / PP / PVC",
  "Travail en hauteur",
];
