import { DatePicker, Input, Modal, Select, message } from "antd";
import axios from "../../axios/AxiosInstance";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AjoutSaisie = ({ isAddSaisie, setIsAddSaisie, candidate }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [amount, setAmount] = useState("0");
  const navigate = useNavigate();
  const inputFileRef = useRef(null);

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const addSaisie = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("candidateId", candidate._id);
    formData.append("amount", amount);
    formData.append(
      "candidateName",
      candidate.firstname + " " + candidate.lastname,
    );
    axios
      .post("/saisie", formData)
      .then((res) => {
        setSelectedFile(null);
        message.success("Saisie ajoutée !");
        setIsAddSaisie(false);
      })
      .catch((e) => {
        setSelectedFile(null);
        console.log(e);
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <Modal
      open={isAddSaisie}
      footer={null}
      onCancel={() => setIsAddSaisie(false)}
    >
      <div className="flex flex-col items-center justify-center space-y-4">
        <div
          onClick={handleClick}
          className="border w-96 border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
        >
          <CloudUploadIcon />
          <p className="mt-2">Ajouter document</p>
          <p className="text-black">{selectedFile?.name}</p>
          <input
            type="file"
            onChange={(e) => handleFileChange(e)}
            className="hidden"
            ref={inputFileRef}
          />
        </div>

        <div className="flex items-center justify-center space-x-2">
          <Input
            className="w-96  rounded-md border outline-none border-gray-200"
            placeholder="Montant de la saisie"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <button
          className="rounded-md bg-mountain-500 text-white p-2 "
          onClick={addSaisie}
        >
          Ajouter saisie
        </button>
      </div>
    </Modal>
  );
};

export default AjoutSaisie;
