import { useEffect, useState, useRef } from "react";
import axios from "../../axios/AxiosInstance";
import { message, Card } from "antd";
import CreateCandidatModal from "./CreateCandidateModal";
import CandidateModal from "./CandidateModal";
import avatar from "../../assets/img/avatar.jpg";
import cv from "../../assets/img/cv.png";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Document, Page, pdfjs } from "react-pdf";

const Candidate = () => {
  const [candidates, setCandidates] = useState([]);
  const [isCreateCandidate, setIsCreateCandidate] = useState(false);
  const [isOpenCandidateModal, setIsOpenCandidateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const fileInputRef = useRef(null);
  const [isModalCV, setIsModalCV] = useState(false);
  const [cvData, setCvData] = useState(null);
  const [numPages, setNumPages] = useState([]);
  const [isCVTheque, setIsCVTheque] = useState([]);

  useEffect(() => {
    loadCandidates();
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    console.log(fileInputRef);
    fileInputRef.current.click(); // Programmatically trigger the file input
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1);
    setNumPages(newArray);
  };

  const handleDownloadPdf = () => {
    // Generate a temporary anchor element and set the PDF file as its href
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${cvData}`;
    link.download = `${selectedUser.firstname}-${selectedUser.lastname}.pdf`;

    // Simulate a click event to trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateCv = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);

    if (file.type === "application/pdf") {
      axios
        .post(`tempworker-candidate/${selectedUser._id}/update-cv`, formData)
        .then((res) => {
          console.log(res.data);
          setIsModalCV(false);
          message.success("Cv modifie!");
        })
        .catch((e) => console.log(e));
    } else {
      message.error("Veuillez ajouter un fichier pdf !");
    }

    /*   axiosInstance.post(`tempworker-candidate/${selectedUser._id}/update`,{cv:updatedCv})
    .then((res)=>{

    })
    .catch(e=>{
      console.log(e)
    }) */
  };

  const showCv = (e, candidate) => {
    //e.stopPropagation()
    console.log(candidate);
    const candidateId = candidate._id;
    axios
      .get(`tempworker-candidate/${candidateId}/cv`)
      .then((res) => {
        setCvData(res.data.cv);
        setSelectedUser(candidate);
        setIsModalCV(true);
      })
      .catch((e) => console.log(e));
  };

  const openCandidateModal = (user) => {
    setSelectedUser(user);
    setIsOpenCandidateModal(true);
  };

  const loadCandidates = () => {
    axios
      .get("/tempworker-candidate/freelance")
      .then((res) => {
        console.log(res.data);
        setCandidates(res.data);
      })
      .catch((e) => {
        console.log(e);
        message.error("Une erreur s'est produite !");
      });
  };

  return (
    <div>
      {isModalCV && (
        <div className="flex items-stretch absolute top-0 min-h-screen  left-0 right-0 z-30 ">
          <div className="grow flex flex-col  items-center justify-center py-6 bg-black bg-opacity-70 min-h-screen">
            <div className="flex items-center my-3 justify-center space-x-4">
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={handleDownloadPdf}
              >
                Telechargez le cv
              </p>
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={(e) => handleButtonClick(e)}
              >
                Modifier le cv
              </p>
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={() => setIsModalCV(false)}
              >
                Fermer
              </p>
            </div>
            <Document
              file={`data:application/pdf;base64,${cvData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={5 / 3} pageNumber={idx + 1} />;
              })}
            </Document>

            <input
              type="file"
              onChange={(e) => updateCv(e)}
              className="hidden"
              ref={fileInputRef}
            />
          </div>
        </div>
      )}
      {isOpenCandidateModal && (
        <CandidateModal
          isOpen={isOpenCandidateModal}
          setIsOpen={setIsOpenCandidateModal}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setCandidates={setCandidates}
          candidates={candidates}
        />
      )}
      {isCreateCandidate && (
        <CreateCandidatModal
          isCreateCandidate={isCreateCandidate}
          setIsCreateCandidate={setIsCreateCandidate}
          candidates={candidates}
          setCandidates={setCandidates}
        />
      )}

      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsCVTheque(true)}
          className={`${
            isCVTheque ? "bg-white border border-gray-200" : ""
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">CVthèque</p>
        </div>

        <div
          onClick={() => setIsCVTheque(false)}
          className={`${
            !isCVTheque ? "bg-white border border-gray-200" : ""
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Candidats disponibles</p>
        </div>
      </div>

      <div className="flex items-center w-full mb-6 justify-between"></div>

      {isCVTheque && (
        <div>
          <div className="flex mt-10 items-start justify-end mx-8 mb-6">
            <p
              onClick={() => setIsCreateCandidate(true)}
              className="rounded-md bg-purple-500 text-white p-2"
            >
              Ajouter un candidat
            </p>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {candidates.map((candidate, idx) => {
              return (
                <Card
                  onClick={() => openCandidateModal(candidate)}
                  key={idx}
                  className="bg-white overflow-hidden hover:cursor-pointer rounded-md border border-gray-300"
                  /* className={`${
                      candidate.status === 'Disponible'
                        ? 'bg-green-100'
                        : ''
                    } ${candidate.status === 'Bloqué' ? 'bg-red-50' : ''} ${
                      candidate.status === 'Indisponible'
                        ? 'bg-orange-50'
                        : ''
                    } border p-0 overflow-hidden bg-gray-50 border-gray-300 hover:scale-101 hover:cursor-pointer transition-all duration-200`} */
                >
                  <div className="flex flex-col justify-between h-80">
                    <div>
                      <div className="flex items-start space-x-2 justify-between">
                        <div className="border-b w-full border-b-gray-300 pb-4">
                          <div className="flex items-start justify-between space-x-2">
                            <div className="flex items-start space-x-2">
                              <img
                                src={
                                  candidate.image
                                    ? `data:image/png;base64,${candidate.image}`
                                    : avatar
                                }
                                className="w-12 h-12 rounded-full"
                              />
                              <div>
                                <p className="font-bold text-lg">
                                  <span className="capitalize">
                                    {candidate.firstname.toLowerCase()}{" "}
                                    {candidate.lastname.toLowerCase()}
                                  </span>
                                </p>
                                {candidate.pld && (
                                  <p className="text-xl text-red-500">
                                    #{candidate.pld}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div>
                              <img
                                src={cv}
                                className="w-12"
                                onClick={(e) => showCv(e, candidate)}
                              />
                            </div>
                          </div>

                          {/*   {candidate.pld && (
                              <p className="flex justify-center">
                                {' '}
                                <span className="text-white rounded-md text-center px-2 py-1   bg-red-600 mt-2 text-xs">
                                  Interimaire : {candidate.pld}
                                </span>
                              </p>
                            )} */}
                        </div>

                        {/* <div className=" flex  justify-center">
                            <img
                              src={cv}
                              className="w-12"
                              onClick={(e) => showCv(e, candidate)}
                            />
                          </div> */}
                      </div>

                      <div className="flex  items-center my-6 space-x-2">
                        <PhoneOutlined className="text-blue-500 text-lg" />
                        <p className="font-semibold">
                          {" "}
                          {candidate.phone.replace(/(\d{2})(?=\d)/g, "$1 ")}
                        </p>
                      </div>

                      <div className="flex items-center mb-6 space-x-2">
                        <MailOutlined className="text-blue-500 text-lg" />

                        <p className="font-semibold uppercase">
                          {candidate.email}
                        </p>
                      </div>

                      <div className="flex items-end mb-6 space-x-2">
                        <LocationOnOutlinedIcon className="text-blue-500 text-xl" />
                        <p className="font-semibold uppercase">
                          {" "}
                          {
                            candidate.postCode
                          } {candidate.town?.toUpperCase()}{" "}
                        </p>
                      </div>

                      <div className="flex items-start overflow-hidden mb-6 space-x-2">
                        <WorkOutlineOutlinedIcon className="text-blue-500 text-xl" />
                        <div className="flex flex-col">
                          <p className="font-semibold uppercase">
                            {candidate.qualifications[0]}
                          </p>
                          {/* {candidate.qualifications.map(
                              (qualification, idx) => (
                                <p key={idx}>{qualification}</p>
                              ),
                            )} */}
                        </div>
                      </div>
                    </div>

                    <div>
                      {candidate.status === "Indisponible" && (
                        <p className="flex justify-center">
                          {" "}
                          <span className="text-white rounded-md text-center px-2 py-1   bg-orange-400 text-xs">
                            {candidate.status}
                          </span>
                        </p>
                      )}

                      {candidate.status === "Bloqué" && (
                        <p className="flex justify-center">
                          {" "}
                          <span className="text-white rounded-md text-center px-2 py-1   bg-black text-xs">
                            {candidate.status}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      )}

      {!isCVTheque && (
        <div>
          <div className="flex mt-10 items-start justify-end mx-8 mb-6">
            <p
              className="rounded-md bg-purple-500 text-white p-2"
            >
              Ajouter un candidat disponible
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Candidate;
