import { useEffect, useState } from "react";
import axios from "../../axios/AxiosInstance";
import { Card, Input } from "antd";
import CreateClient from "./CreateClient";
import ClientDetail from "./ClientDetail";
import phone from "../../assets/img/phone.png";
import email from "../../assets/img/email.png";
import placeholder from "../../assets/img/placeholder.png";
import { AddOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import Devis from "./Devis";
import ClientTask from "./ClientTask";
import FactureRelance from "../FactureRelance/FactureRelance.jsx";

const Client = () => {
  const [clients, setClients] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isOpenCreateClient, setIsOpenCreateClient] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [action, setAction] = useState("devis");
  const [tasks, setTasks] = useState([]);

  const loadTasks = () => {
    axios
      .get("task/freelance/client")
      .then((res) => {
        setTasks(res.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  const loadClients = () => {
    axios
      .get("client/freelance")
      .then((res) => {
        setClients(res.data);
        setOriginalClients(res.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  const search = () => {
    axios
      .post("client/find-by-text", { query: searchWord })
      .then((res) => {
        setClients(res.data);
      })
      .catch((e) => {});
  };

  const selectSearchWord = (word) => {
    if (word.length === 0) {
      setClients(originalClients);
    }
    setSearchWord(word);
  };

  useEffect(() => {
    loadClients();
    loadTasks();
  }, []);

  return (
    <div className="w-full">
      {selectedClient && (
        <ClientDetail
          client={selectedClient}
          originalClients={originalClients}
          setClient={setSelectedClient}
          clients={clients}
          setClients={setClients}
        />
      )}

      {isOpenCreateClient && (
        <CreateClient
          isOpenCreateClient={isOpenCreateClient}
          setIsOpenCreateClient={setIsOpenCreateClient}
          clients={clients}
          setClients={setClients}
        />
      )}
      <div className="mb-10 mt-10 font-Inter">
        <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
          <div
            onClick={() => setIsAction(false)}
            className={`${
              !isAction ? "bg-white border border-gray-200" : ""
            } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
          >
            <p className="text-center font-bold">Clients</p>
          </div>

          <div
            onClick={() => setIsAction(true)}
            className={`${
              isAction ? "bg-white border border-gray-200" : ""
            } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
          >
            <p className="text-center font-bold">Actions commerciales</p>
          </div>
        </div>
      </div>

      {!isAction && (
        <div>
          <div className="flex items-center space-x-2 justify-end">
            <div className="flex items-center justify-center mb-6 space-x-2">
              {isSearch && (
                <div className="flex items-center space-x-2">
                  <Input
                    className={`w-80 rounded-md border border-gray-200 focus:outline-none`}
                    placeholder="Recherche"
                    style={{ outline: "none" }}
                    onChange={(e) => selectSearchWord(e.target.value)}
                  />
                  <button
                    onClick={() => search()}
                    className="bg-purple-400 text-black p-2 rounded-md"
                  >
                    Rechercher
                  </button>
                </div>
              )}
              <SearchIcon
                onClick={() => setIsSearch(!isSearch)}
                className="text-gray-500 text-3xl"
              />
            </div>

            <div
              onClick={() => setIsOpenCreateClient(true)}
              className="border hover:cursor-pointer bg-purple-400 rounded-md flex items-center"
            >
              <AddOutlined className="hover:cursor-pointer text-black" />
              <p className="text-black p-2">Ajouter un client</p>
            </div>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
            {clients.map((client, idx) => {
              return (
                <Card
                  key={idx}
                  onClick={() => {
                    setSelectedClient(client);
                  }}
                  className="border p-0 text-gray-800 bg-gray-50 border-gray-400 hover:scale-101 hover:cursor-pointer transition-all duration-200"
                >
                  <div>
                    <p className="font-bold uppercase text-lg text-">
                      {client.companyName}{" "}
                    </p>
                  </div>

                  <div className="flex items-start space-x-2">
                    <div className="flex flex-col items-start my-2">
                      <p className=" text-sm flex items-center space-x-3">
                        <img className="w-6" src={placeholder} />{" "}
                        <span>{client.address}</span>{" "}
                      </p>
                      <p className="ml-[36px]">
                        {" "}
                        {client.postCode} {client.town}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2 mb-2">
                    <p className=" text-sm flex items-center ">
                      <img className="w-4" src={phone} />{" "}
                      <span className="ml-[22px]">{client.contactPhone}</span>{" "}
                    </p>
                  </div>

                  <div className=" flex items-center space-x-2">
                    <p className=" text-sm flex items-center ">
                      <img className="w-4" src={email} />{" "}
                      <span className="ml-[22px]">{client.contactEMail}</span>{" "}
                    </p>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      )}

      {isAction && (
        <div>
          <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
            <div
              onClick={() => setAction("devis")}
              className={`${
                action === "devis" ? "bg-white border border-gray-200" : ""
              } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
            >
              <p className="text-center font-bold">Devis</p>
            </div>

            <div
              onClick={() => setAction("task")}
              className={`${
                action === "task" ? "bg-white border border-gray-200" : ""
              } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
            >
              <p className="text-center font-bold">Tâches</p>
            </div>

            <div
              onClick={() => setAction("relance")}
              className={`${
                action === "relance" ? "bg-white border border-gray-200" : ""
              } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
            >
              <p className="text-center font-bold">Factures à relancer</p>
            </div>
          </div>

          {action === "devis" && (
            <div>
              <Devis />
            </div>
          )}

{action === "relance" && (
            <div>
              <FactureRelance />
            </div>
          )}

          {action === "task" && (
            <div>
              <ClientTask tasks={tasks} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Client;
