import { Select } from "antd";

const CreateContractConsigne = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">
          Consignes particulières
        </p>

        <div className="flex items-center my-6">
          <Select
            className="w-full  my-6"
            showSearch
            optionFilterProp="children"
            onChange={(e) => setContract({ ...contract, consigne: e })}
            value={contract.consigne ? contract.consigne : null}
            placeholder="Consignes"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "0 : Aucune",
                label: "0 : Aucune",
                key: "0",
              },
              {
                value:
                  "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                label:
                  "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                key: "1",
              },
              {
                value:
                  "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                label:
                  "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                key: "2",
              },
              {
                value:
                  "3 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                label:
                  "3 :  Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                key: "3",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContractConsigne;
