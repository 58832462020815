import { Checkbox, Input } from "antd";

const CreateContractLieu = ({ client, contract, setContract, chantiers }) => {
  const setAdressFromSiege = () => {
    const newContract = { ...contract };
    newContract.clientName = client.companyName;
    newContract.address = client.address;
    newContract.postCode = client.postCode;
    newContract.town = client.town;
    setContract(newContract);
  };

  const selectChantier = (chantier) => {
    const newContract = { ...contract };
    newContract.clientName = chantier.NomChantier;
    newContract.address = chantier.AdresseRue;
    newContract.postCode = chantier.AdresseCodePostal;
    newContract.town = chantier.AdresseVille;
    setContract(newContract);
  };

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">Lieu de la mission</p>
        <div className="flex space-x-2 items-center justify-around mt-6">
          {/* <p
            onClick={() => setAdressFromSiege()}
            className="text-xs hover:cursor-pointer text-blue-500
            "
          >
            Recopier l'adresse du siège
          </p> */}

          {/* <p
            onClick={() => setAdressFromLivraison()}
            className="text-xs text-blue-500
            "
          >
            Recopier l'adresse de livraison
          </p>
          <p
            onClick={() => setAdressFromFacturation()}
            className="text-xs text-blue-500"
          >
            Recopier l'adresse de facturation
          </p> */}
        </div>
        <p className="font-semibold mt-4">
          Lieux de missions enregistrés sur tempo :
        </p>
        <div className="max-h-40 mt-4 overflow-y-scroll border border-gray-300 rounded-md">
          {chantiers.map((chantier) => {
            return (
              <div
                onClick={() => selectChantier(chantier)}
                className="border-b hover:cursor-pointer p-4 border-b-gray-300"
              >
                <p>{chantier.NomChantier.toUpperCase()}</p>
                <p>{chantier.AdresseRue.toUpperCase()}</p>
                <p>{chantier.AdresseSuite.toUpperCase()}</p>
                <p>
                  {chantier.AdresseVille.toUpperCase()}{" "}
                  {chantier.AdresseCodePostal.toUpperCase()}
                </p>
              </div>
            );
          })}
        </div>
        <p className="font-semibold my-4">Nouveau lieu de mission :</p>
        <div className="flex flex-col items-center mb-6 space-y-3">
          <Input
            onChange={(e) =>
              setContract({ ...contract, clientName: e.target.value })
            }
            value={contract.clientName ? `${contract.clientName}` : null}
            className="rounded-md bcontract bcontract-gray-300 mb-2"
            placeholder="Nom du client final"
          />
          <Input
            onChange={(e) =>
              setContract({ ...contract, address: e.target.value })
            }
            value={contract.address ? `${contract.address}` : null}
            className="rounded-md bcontract bcontract-gray-300 mb-2"
            placeholder="Adresse"
          />
          <Input
            onChange={(e) =>
              setContract({ ...contract, postCode: e.target.value })
            }
            value={contract.postCode ? `${contract.postCode}` : null}
            className="rounded-md bcontract bcontract-gray-300 mb-2"
            placeholder="Code postal"
          />
          <Input
            onChange={(e) => setContract({ ...contract, town: e.target.value })}
            value={contract.town ? `${contract.town}` : null}
            className="rounded-md bcontract bcontract-gray-300 mb-2"
            placeholder="Ville"
          />
        </div>
      </div>

      <div className="flex items-center w-3/5 justify-between my-6 space-x-10 ">
        <p className="font-semibold text-xl">Est En Chantier</p>
        <div className="flex items-center space-x-8">
          <p className="flex items-center space-x-1">
            <Checkbox
              onChange={() => setContract({ ...contract, EstChezClient: "N" })}
              checked={contract.EstChezClient === "N"}
            />
            <span>OUI</span>
          </p>
          <p className="flex items-center space-x-1">
            <Checkbox
              onChange={() => setContract({ ...contract, EstChezClient: "O" })}
              checked={contract.EstChezClient === "O"}
            />
            <span>NON</span>
          </p>
        </div>
      </div>

      <div className="flex items-center w-3/5 justify-between my-6 space-x-10 ">
        <p className="font-semibold text-xl">Est BTP</p>
        <div className="flex items-center space-x-8">
          <p className="flex items-center space-x-1">
            <Checkbox
              onChange={() => setContract({ ...contract, EstBTP: "O" })}
              checked={contract.EstBTP === "O"}
            />
            <span>OUI</span>
          </p>
          <p className="flex items-center space-x-1">
            <Checkbox
              onChange={() => setContract({ ...contract, EstBTP: "N" })}
              checked={contract.EstBTP === "N"}
            />
            <span>NON</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreateContractLieu;
