import atradius from "../../assets/img/atradius.png";
import contrat1 from "../../assets/img/contrat1.png";
import contrat2 from "../../assets/img/contrat2.png";
import financialimage from "../../assets/img/financial.png";
import eventimage from "../../assets/img/event.png";
import contactimage from "../../assets/img/contact.png";
import infoimage from "../../assets/img/info.png";
import fileimage from "../../assets/img/file.png";
import commentimage from "../../assets/img/comment.png";
import information from "../../assets/img/information.png";
import money from "../../assets/img/money.png";
import devis from "../../assets/img/devis.png";
import ClientComment from "./ClientComment";
import ClientDocuments from "./ClientDocuments";
import { Document, Page, pdfjs } from "react-pdf";
import CreateQuotationModal from "./CreateQuotationModal";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import axios from "../../axios/AxiosInstance";
import CreateFacture from "./CreateFacture";
import DevisSection from "./DevisSection";
import { useEffect, useState } from "react";
import { Input, Modal, Select, TreeSelect, message } from "antd";
import CreateContract from "../CreateContract/CreateContract";
import qualifications from "../utils/qualifications";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//import CreateContract from './CreateContract'
//import ClientModalContract from './ClientModalContract'
//import DuplicateContract from './DuplicateContract'
const ClientDetail = ({
  client,
  setClient,
  clients,
  setClients,
  originalClients,
}) => {
  const [section, setSection] = useState("info");
  const [acceptedAtradius, setAcceptedAtradius] = useState(false);
  const [updateContactObject, setUpdateContactObject] = useState({});
  const [pld, setPld] = useState("");
  const [createdQuotation, setCreatedQuotation] = useState(false);

  const [contacts, setContacts] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [originalQuotations, setOriginalQuotations] = useState([]);
  const [isOpenCreateContact, setIsOpenCreateContact] = useState(false);
  const [isOpenCreateTask, setIsOpenCreateTask] = useState(false);
  const [canCreateContract, setCanCreateContrat] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [epis, setEpis] = useState([]);
  const [isCreateFacture, setIsCreateFacture] = useState(false);
  const [isCreatePldContract, setIsCreatePldContract] = useState(false);
  const [clientQualifications, setClientQualifications] = useState([]);
  const [clientChantiers, setClientChantiers] = useState([]);
  const [isAskAtradius, setIsAskAtradius] = useState(false);
  const [isLoadingAtradius, setIsLoadingAtradius] = useState(false);
  const [isDuplicateContract, setIsDuplicateContract] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [cover, setCover] = useState(null);
  const [loadedCover, setLoadedCover] = useState(false);
  const [amount, setAmount] = useState("");
  const [isCreateQuotation, setIsCreateQuotation] = useState(false);

  useEffect(() => {
    loadQualifications();
    getCover();
  }, []);

  const loadQualifications = () => {
    if (!client.pld) {
      setCanCreateContrat(false);
      console.log("pas de pld");
      return;
    }
    axios
      .get(`client/qualifications/${client.pld}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          setCanCreateContrat(true);
          setClientQualifications(res.data.TabQualifications);
          setClientChantiers(res.data.TabChantiers);
        }
      })
      .catch((e) => console.log(e));
  };

  const getCover = () => {
    axios
      .get(`cover/client/${client._id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.length) {
          setCover(res.data[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoadedCover(true));
  };

  const episList = [
    "Gants",
    "Lunette",
    "Protection",
    "Blouse",
    "Bleu",
    "Chaussures",
    "Auditive",
    "Casque",
    "Harnais",
    "Gilet",
    "Autre..",
  ];

  const epiData = episList.map((epi) => {
    return {
      title: epi,
      value: epi,
      key: epi,
    };
  });

  const onChangeEpi = (newValue) => {
    setEpis(newValue);
  };

  const atradiusValues = ["Accepté", "Refusé", "En arbitrage"];

  const atradiusValueArray = atradiusValues.map((atradiusValue) => {
    return {
      value: atradiusValue,
      label: atradiusValue,
    };
  });

  const epiProps = {
    treeData: epiData,
    onChange: onChangeEpi,
    value: epis,
    treeCheckable: true,
    placeholder: "EPI",
  };

  const setAtradius = (atradiusValue) => {
    if (atradiusValue === "Accepté") {
      setAcceptedAtradius(true);
    } else {
      setAcceptedAtradius(false);
    }
  };

  const deleteContact = (contacts, idx) => {
    console.log(contacts);
    console.log(idx);
    const newContacts = [...contacts];
    newContacts.splice(idx, 1);
    axios
      .patch(`client/${client._id}`, { contacts: newContacts })
      .then((res) => {
        setUpdateContactObject({});
        const clientIdx = originalClients.findIndex(
          (c) => c._id === client._id,
        );
        const newClients = [...originalClients];
        newClients[clientIdx] = res.data;
        setClient(res.data);
        setClients(newClients);
        message.success("Contact supprimé ! ");
        setIsOpenCreateContact(false);
      })
      .catch((e) => {
        message.error("une erreur s'est produite ! ");
      });
  };

  const formatJsDate = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const filter = (array, word) => {
    const excludedFields = ["_id", "user"];
    word = word.toLowerCase();
    return array.filter((obj) => {
      const stringObj = JSON.stringify(obj).toLowerCase();
      return stringObj.includes(word.toLowerCase());
      /* for (const key in obj) {
        if (obj.hasOwnProperty(key) && !excludedFields.includes(key)) {
          const value = obj[key]

          if (typeof value === 'string' && value.toLowerCase().includes(word)) {
            return true
          }
          if (typeof value === 'object') {
            if (value.join('').toLowerCase().includes(word)) {
              return true
            }
          }
        }
      } */
    });
  };

  const createPldContract = () => {
    if (canCreateContract) setIsCreatePldContract(true);
    else
      message.error("Le client n'a pas de numéro PLD ! Demander sa création");
    console.log(canCreateContract);
  };

  const updateContacts = () => {
    axios
      .patch(`client/${client._id}`, {
        contacts: [...client.contacts, updateContactObject],
      })
      .then((res) => {
        setUpdateContactObject({});
        const clientIdx = originalClients.findIndex(
          (c) => c._id === client._id,
        );
        const newClients = [...originalClients];
        newClients[clientIdx] = res.data;
        setClient(res.data);
        setClients(newClients);
        message.success("Contact créé ! ");
        setIsOpenCreateContact(false);
      })
      .catch((e) => {
        message.error("une erreur s'est produite ! ");
      });
  };

  const askAtradius = () => {
    setIsLoadingAtradius(true);
    if (!client.siret) {
      message.error("Le client doit avoir un numero de siret");
      return;
    }
    if (amount.length === 0) {
      message.error("Veuillez entrer un montant entier !");
      return;
    }
    const siren = client.siret.trim().substring(0, 9);
    axios
      .post("cover/new-cover", { amount, siren, clientId: client._id })
      .then((res) => {
        console.log(res);
        message.success("Cote demandée !");
        setIsAskAtradius(false);
      })
      .catch((e) => {
        console.log(e);
        message.error(e.response.data.message);
      })
      .finally(() => {
        setIsLoadingAtradius(false);
      });
  };

  function formatPhoneNumber(input) {
    const formatted = input.replace(/\B(?=(\d{2})+(?!\d))/g, " ");
    return formatted;
  }

  return (
    <div className="w-full">
      {isCreatePldContract && (
        <CreateContract
          client={client}
          qualifications={clientQualifications}
          chantiers={clientChantiers}
          isCreateContract={isCreatePldContract}
          setIsCreateContract={setIsCreatePldContract}
        />
      )}

      {isAskAtradius && (
        <Modal
          open={isAskAtradius}
          footer={null}
          onCancel={() => setIsAskAtradius(false)}
        >
          <div className="flex flex-col mt-10 items-center justify-center space-y-4">
            <input
              onChange={(e) => setAmount(e.target.value)}
              className="border outline-none border-gray-300 w-96 rounded-md p-2"
              placeholder="Montant"
            />
            <button
              onClick={askAtradius}
              className="p-2 text-white rounded-md w-96 text-center bg-purple-500"
            >
              {!isLoadingAtradius && <span>Demander cote atradius</span>}
              {isLoadingAtradius && <span>...Patientez</span>}
            </button>
          </div>
        </Modal>
      )}

      <Modal
        className="w-full h-screen"
        onCancel={() => {
          setClient(null);
          setSection("info");
        }}
        open={client}
        footer={null}
      >
        {isOpenCreateContact && (
          <Modal
            footer={null}
            width={900}
            open={isOpenCreateContact}
            onCancel={() => setIsOpenCreateContact(false)}
            className="z-30"
          >
            <div className="flex items-center space-x-2 mb-6 mt-12">
              <Select
                className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                onChange={(e) =>
                  setUpdateContactObject({ ...updateContactObject, title: e })
                }
                value={updateContactObject.title}
                optionFilterProp="children"
                placeholder="Civilité"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    label: "M.",
                    value: "M.",
                    key: "M.",
                  },
                  {
                    label: "Mme",
                    value: "Mme",
                    key: "Mme",
                  },
                ]}
              />
              <Input
                placeholder="Prénom"
                onChange={(e) =>
                  setUpdateContactObject({
                    ...updateContactObject,
                    firstname: e.target.value,
                  })
                }
                value={updateContactObject.firstname}
                className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              />
              <Input
                placeholder="Nom"
                onChange={(e) =>
                  setUpdateContactObject({
                    ...updateContactObject,
                    lastname: e.target.value,
                  })
                }
                value={updateContactObject.lastname}
                className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              />
            </div>

            <Input
              placeholder="Profession"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  profession: e.target.value,
                })
              }
              value={updateContactObject.profession}
              className="px-2 mb-6 placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />

            <div className="mb-6 flex items-center space-x-2">
              <Input
                placeholder="Téléphone fixe"
                onChange={(e) =>
                  setUpdateContactObject({
                    ...updateContactObject,
                    phone: e.target.value,
                  })
                }
                value={updateContactObject.phone}
                className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              />
              <Input
                placeholder="Téléphone portable"
                onChange={(e) =>
                  setUpdateContactObject({
                    ...updateContactObject,
                    mobile: e.target.value,
                  })
                }
                value={updateContactObject.mobile}
                className="px-2 placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              />
            </div>

            <Input
              placeholder="Email"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  email: e.target.value,
                })
              }
              value={updateContactObject.email}
              className="px-2 mb-6  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />

            {/*   <Select
          className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
          onChange={(e) => changeTitle(e)}
          value={updateContactObject.title}
          optionFilterProp="children"
          placeholder="Permissions"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              label: 'M.',
              value: 'M.',
              key: 'M.',
            },
            {
              label: 'Mme',
              value: 'Mme',
              key: 'Mme',
            },
          ]}
        /> */}

            <p className="text-right">
              <button
                onClick={updateContacts}
                className="bg-mountain-600 p-3 rounded-md text-white"
              >
                Enregistrer
              </button>
            </p>
          </Modal>
        )}
        {isCreateFacture && (
          <CreateFacture
            client={client}
            isCreateFacture={isCreateFacture}
            setIsCreateFacture={setIsCreateFacture}
          />
        )}

        {isCreateQuotation && (
          <CreateQuotationModal
            client={client}
            createdQuotation={createdQuotation}
            setCreatedQuotation={setCreatedQuotation}
            isCreateQuotation={isCreateQuotation}
            setIsCreateQuotation={setIsCreateQuotation}
          />
        )}
        {/* {isCreateQuotation && (
          <CreateQuotationModal
            client={client}
            isCreateQuotation={isCreateQuotation}
            setIsCreateQuotation={setIsCreateQuotation}
          />
        )} */}
        <div className="flex items-start space-x-14  overflow-hidden">
          <div>
            <div>
              <p className="text-2xl text-gray-600 font-semibold">
                {client.companyName}
              </p>
              {!client.pld && (
                <p className="text-blue-500 mx-auto w-fit text-xs py-1 px-2 mt-2 border-2 mb-4 border-blue-500 rounded-full">
                  Prospect
                </p>
              )}

              <div className="flex flex-col justify-between border-r border-r-blue-400 pr-12  mt-6 h-[500px]">
                <div>
                  <p className="capitalize  flex items-center space-x-1">
                    <PersonIcon className="text-base" />
                    <span>{client.commercial}</span>
                  </p>

                  <p className="capitalize flex items-center space-x-1">
                    <MultipleStopIcon className="text-base" />
                    <span>{client.clientAcquisition}</span>
                  </p>

                  <p className="lowercase flex mt-2 items-center space-x-1">
                    <EmailIcon className="text-base" />
                    <span>{client.contactEMail}</span>
                  </p>

                  <p className="lowercase flex items-center space-x-1">
                    <PhoneIcon className="text-base" />
                    <span>{formatPhoneNumber(client.contactPhone)}</span>
                  </p>

                  <p className="lowercase flex items-center space-x-1">
                    <span className="uppercase font-bold">PLD</span>
                    <span>{client.pld}</span>
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <p
                    onClick={() => setIsAskAtradius(true)}
                    className="text-white hover:cursor-pointer bg-red-400 rounded-md font-semibold p-2 w-full  mx-auto  flex items-center space-x-1"
                  >
                    <img src={atradius} className="w-6 rounded-full" />{" "}
                    <span>Demander une cote atradius</span>
                  </p>
                  <p
                    onClick={() => setIsCreateQuotation(true)}
                    className="text-white hover:cursor-pointer bg-blue-400 rounded-md font-semibold p-2 w-full  mx-auto  flex items-center space-x-1"
                  >
                    <img src={devis} className="w-6" />{" "}
                    <span>Faire un devis</span>
                  </p>

                  <p
                    onClick={() => setIsCreateFacture(true)}
                    className="text-white hover:cursor-pointer bg-green-400 rounded-md font-semibold w-full  p-2  mx-auto  flex items-center space-x-1"
                  >
                    <img src={contrat2} className="w-6" />{" "}
                    <span>Créer vacation/CDD/CDI</span>
                  </p>

                  <p
                    onClick={() => {
                      createPldContract();
                    }}
                    className="text-white hover:cursor-pointer bg-yellow-400 rounded-md font-semibold w-full  p-2  mx-auto  flex items-center space-x-1"
                  >
                    <img src={contrat1} className="w-6" />{" "}
                    <span>Créer interim (PLD)</span>
                  </p>

                  <p className="text-white bg-pink-400 rounded-md font-semibold w-full  p-2  mx-auto flex items-center space-x-1">
                    <img src={information} className="w-6" />{" "}
                    <span>Generer fiche de renseignement</span>
                  </p>
                </div>

                <div className="font-semibold  mt-6 flex flex-col space-y-2">
                  <p className="flex items-center">
                    <img src={money} className="w-8" />
                    <span className="ml-2">CA 2023 :</span>
                  </p>
                  <p className="flex items-center ml-8">
                    <span className="ml-2">CA 2022 :</span>
                  </p>
                  <p className="flex items-center ml-8">
                    <span className="ml-2">CA 2021 :</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grow mt-6 h-full">
            <div className="flex  space-x-2 justify-between items-center">
              <p
                onClick={() => setSection("info")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "info"
                    ? "bg-red-50 text-red-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={infoimage} className="w-6" />
                <span>Informations</span>
              </p>
              <p
                onClick={() => setSection("contact")}
                className={`w-1/6 text-center hover:cursor-pointer  uppercase flex items-center space-x-2 font-semibold p-2 ${
                  section === "contact"
                    ? "bg-blue-50 text-blue-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={contactimage} className="w-6" />
                <span>Contact</span>
              </p>
              <p
                onClick={() => setSection("event")}
                className={`w-1/6 text-center hover:cursor-pointer uppercase flex items-center space-x-2 font-semibold p-2 ${
                  section === "event"
                    ? "bg-green-50 text-green-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={eventimage} className="w-6" />
                <span>Evenement</span>
              </p>
              <p
                onClick={() => setSection("devis")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "devis"
                    ? "bg-pink-50 text-pink-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={financialimage} className="w-6" />
                <span>Devis</span>
              </p>

              <p
                onClick={() => setSection("financial")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "financial"
                    ? "bg-purple-50 text-purple-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={financialimage} className="w-6" />
                <span>ATRADIUS</span>
              </p>

              <p
                onClick={() => setSection("file")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "file"
                    ? "bg-orange-50 text-orange-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={fileimage} className="w-6" />
                <span>Fichiers</span>
              </p>
              <p
                onClick={() => setSection("commentaire")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "commentaire"
                    ? "bg-yellow-50 text-yellow-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={commentimage} className="w-6" />
                <span>Commentaires</span>
              </p>
              <p
                onClick={() => setSection("contrat")}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === "contrat"
                    ? "bg-cyan-100  text-cyan-500 rounded-tr-xl rounded-tl-xl"
                    : ""
                }`}
              >
                <img src={infoimage} className="w-6" />
                <span>Contrat</span>
              </p>
            </div>

            {section === "info" && (
              <div className="bg-red-50  py-10 px-4 h-full">
                <div className="flex items-start space-x-2">
                  <div className="flex flex-col space-y-2 grow">
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold"> Siège : </span>

                      <span className="lowercase">
                        {client.address}, {client.postCode}, {client.town}
                      </span>
                    </p>
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold"> Livraison : </span>

                      <span className="lowercase">
                        {client.addressLivraison}, {client.postCodeLivraison},{" "}
                        {client.townLivraison}
                      </span>
                    </p>

                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold">Facturation :</span>
                      <span className="lowercase">
                        {client.addressFacturation},{" "}
                        {client.postCodeFacturation}, {client.townFacturation}
                      </span>
                    </p>
                  </div>

                  <div className="grow">
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold">SIRET :</span>

                      <span>{client.siret}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1">
                      <span className="font-semibold">APE :</span>
                      <span className="uppercase">{client.APE}</span>
                    </p>
                  </div>
                  <div className="grow">
                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">
                        CONDITIONS DE PAIEMENT :
                      </span>
                      <span className="uppercase">{client.facturation}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">TAUX DE TVA :</span>
                      <span className="uppercase">{client.tvaRate}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">TVA INTRA. :</span>
                      <span className="uppercase">{client.TVA}</span>
                    </p>
                  </div>
                </div>

                <p className="uppercase flex items-center space-x-1 mt-28">
                  <span className="font-semibold">
                    EPI FOURNIS PAR L'E.U. :
                  </span>
                </p>
                <TreeSelect
                  className="w-96 rounded-full border-0"
                  size="large"
                  {...epiProps}
                />
                {!cover && loadedCover && (
                  <p className="uppercase text-center font-semibold text-3xl mt-20 text-red-500">
                    Aucune couverture atradius
                  </p>
                )}
              </div>
            )}

            {section === "contact" && (
              <div className="bg-blue-50  py-10 px-1 h-full">
                <div className="flex items-center text-xs mx-10">
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    TITRE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    NOM
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    PRENOM
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    POSTE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    EMAIL
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    TELEPHONE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    HABILITATION
                  </p>
                  <div className="flex items-center w-[12%] space-x-4 justify-center">
                    <AddIcon
                      className="hover:cursor-pointer text-blue-400"
                      onClick={() => setIsOpenCreateContact(true)}
                    />
                  </div>
                </div>

                {client.contacts.map((contact, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center mt-6 text-xs mx-10"
                    >
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {contact.title}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {" "}
                        {contact.lastname}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {" "}
                        {contact.firstname}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {" "}
                        {contact.profession}
                      </p>
                      <p className="w-[12%] text-center  font-semibold">
                        {" "}
                        {contact.email}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {" "}
                        {contact.mobile}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {contact.habilitation || ""}{" "}
                      </p>
                      <p className="w-[12%] justify-center items-center  flex space-x-2 text-xs text-blue-600">
                        <EditOutlinedIcon />
                        <DeleteForeverIcon
                          onClick={() => deleteContact(client.contacts, idx)}
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            {section === "financial" && (
              <div className="bg-purple-50  py-10 px-4 h-full">
                <div className=" mt-2 mx-auto h-32">
                  <div className="flex p-3 bg-gray-500 text-white items-center justify-between mb-2 py-3">
                    <p className="w-1/6 text-center">DATE DE LA DEMANDE</p>
                    <p className="w-1/6 text-center">TYPE D'ASSURANCE</p>
                    <p className="w-1/6 text-center">MONTANT DEMANDE</p>
                    <p className="w-1/6 text-center">MONTANT ACCORDE</p>
                    <p className="w-1/6 text-center">FACTURE EN COURS</p>
                    <p className="w-1/6 text-center">ETAT</p>
                  </div>
                  {cover && (
                    <div className="flex bg-white shadow-lg text-xs border border-gray-300 rounded-md p-3 items-center justify-between">
                      <p className="w-1/6  text-center">
                        {formatJsDate(cover._doc.decisionDate)}
                      </p>
                      <p className="w-1/6 text-center">
                        {cover._doc.coverType}
                      </p>
                      <p className="w-1/6  text-center">
                        {
                          cover._doc
                            .creditLimitApplicationAmountInPolicyCurrency
                        }
                        &euro;
                      </p>
                      <p className="w-1/6  text-center">
                        {cover._doc.totalDecision.decisionAmtInPolicyCurrency}
                        &euro;
                      </p>
                      <p className="w-1/6  text-center">
                        {cover.montantNonFacture.toFixed(2)}&euro;
                      </p>
                      {cover.montantNonFacture <=
                        cover._doc.totalDecision.decisionAmtInPolicyCurrency &&
                        cover._doc.totalDecision.decisionAmtInPolicyCurrency >
                          0 && (
                          <p className="w-1/6 text-green-600  text-center">
                            OK
                          </p>
                        )}
                      {cover._doc.totalDecision.decisionAmtInPolicyCurrency ===
                        "0" && (
                        <p className="w-1/6 text-red-600  text-center uppercase">
                          refusée
                        </p>
                      )}
                      {cover._doc.totalDecision.decisionAmtInPolicyCurrency !==
                        "0" &&
                        cover.montantNonFacture >
                          cover._doc.totalDecision
                            .decisionAmtInPolicyCurrency && (
                          <p className="w-1/6 text-red-600 font-semibold  text-center">
                            A REVOIR
                          </p>
                        )}
                      <p className="w-1/6  text-center"></p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {section === "event" && (
              <div className="bg-green-50  py-10 px-4 h-full">
                <div className="mt-10 uppercase text-xs flex items-center justify-between space-x-1">
                  <p className="text-green-400 text-center">type</p>
                  <p className="text-green-400 text-center">date</p>
                  <p className="text-green-400 text-center">heure</p>
                  <p className="text-green-400 text-center">lieu</p>
                  <p className="text-green-400 text-center">commentaire</p>

                  <AddIcon
                    className="hover:cursor-pointer w-20 text-green-400"
                    onClick={() => setIsOpenCreateTask(true)}
                  />
                </div>

                <div className="mt-10 font-semibold flex flex-col space-y-2">
                  {tasks.map((task) => {
                    return (
                      <div className="uppercase text-xs flex items-center justify-between space-x-1">
                        <p className=" text-center">{task.type}</p>
                        <p className=" text-center">
                          {formatJsDate(task.date)}
                        </p>
                        <p className=" text-center">{task.hour}</p>
                        <p className=" text-center">{task.place}</p>
                        <p className=" text-center">{task.comment}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {section === "file" && <ClientDocuments client={client} />}

            {section === "commentaire" && (
              <ClientComment
                client={client}
                clients={clients}
                setClients={setClients}
              />
            )}

            {section === "devis" && (
              <DevisSection
                createdQuotation={createdQuotation}
                client={client}
              />
            )}

            {section === "contrat" && (
              <div className="bg-fuschia-50 h-full">
                {/* <ClientModalContract
                  client={client}
                  setSelectedContract={setSelectedContract}
                  setIsDuplicateContract={setIsDuplicateContract}
                /> */}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClientDetail;
