import { Button, Input, Modal, Popconfirm, Select, message } from "antd";
import qualifications from "../utils/qualifications";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/AxiosInstance";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import jsPDF from "jspdf";
import myImage from "../../assets/img/logopdf.png";
import html2canvas from "html2canvas";
import { EmailOutlined, Euro, PhoneAndroidOutlined } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";
import logotoulouse from "../../assets/img/logotoulouse.png";
import logobrive from "../../assets/img/logobrive.png";
import gmail from "../../assets/img/gmail.png";
import phone from "../../assets/img/phone.png";
import { Document, Page } from "react-pdf";

const CreateQuotationModal = ({
  client,
  createdQuotation,
  setCreatedQuotation,
  isCreateQuotation,
  setIsCreateQuotation,
}) => {
  const [step, setStep] = useState("1");
  const [isPlacement, setIsPlacement] = useState(false);
  const [isVacation, setIsVacation] = useState(false);
  const [quotation, setQuotation] = useState({ products: [] });
  const [isDelegation, setIsDelegation] = useState(true);
  const [isGestion, setIsGestion] = useState(false);
  const [selectedPoste, setSelectedPoste] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedTauxHoraire, setSelectedTauxHoraire] = useState("");
  const [selectedCoeff, setSelectedCoeff] = useState("");
  const [coefficientPrime, setcoefficientPrime] = useState("");
  const [tauxHorairePrime, setTauxHorairePrime] = useState("");
  const [minimumHours, setMinimumHours] = useState("");
  const [selectedComment2, setSelectedComment2] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [clientAddressesArray, setClientAdressesArray] = useState([]);
  const [clientContactsArray, setClientContactsArray] = useState([]);
  const [visualizeQuotation, setVisualizeQuotation] = useState("");
  const [isVisualizeQuotation, setIsVisualizeQuotation] = useState(false);
  const [originalQuotations, setOriginalQuotations] = useState([]);
  const [prime, setPrime] = useState("Prime");
  const [primeDetail, setPrimeDetail] = useState("");
  const [primeComment, setPrimeComment] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [username, setUsername] = useState("");
  const [nombre, setNombre] = useState("");
  const [forfait, setForfait] = useState("");
  const [numPages, setNumPages] = useState([]);
  const [isAddPrime, setIsAddPrime] = useState(false);

  const products = ["Délégation", "Gestion", "Placement", "Vacation"];

  function splitStringWithoutCuttingWords(inputString, chunkSize) {
    const words = inputString.split(" ");
    const resultChunks = [];
    let currentChunk = "";

    for (const word of words) {
      if (currentChunk.length + word.length <= chunkSize) {
        currentChunk += (currentChunk ? " " : "") + word;
      } else {
        resultChunks.push(currentChunk);
        currentChunk = word;
      }
    }

    if (currentChunk) {
      resultChunks.push(currentChunk);
    }

    return resultChunks;
  }

  const closeModal = () => {
    setIsCreateQuotation(false);
    setQuotation({});
    setStep("1");

    setSelectedProduct(null);
    setSelectedCoeff("");
    setSelectedComment("");
    setSelectedTauxHoraire("");
    setMinimumHours("");
    setSelectedPoste(null);
  };

  useEffect(() => {
    selectClient();
    const userName = localStorage.getItem("name");
    setUsername(userName);
  }, []);

  const qualificationsArray = qualifications.map((qualification) => {
    return {
      value: qualification,
      label: qualification,
    };
  });

  const generatePDF = (quotation) => {
    console.log(quotation);

    const pdf = new jsPDF({
      unit: "mm",
      format: [210, 297],
    });

    const img = new Image();
    if (client.agency.name === "CAPENEO") {
      img.src = logotoulouse;
      pdf.addImage(img, "PNG", 14, 10, 40, 24);
    } else if (client.agency.name === "J'M CAPENEO") {
      img.src = logobrive;
      pdf.addImage(img, "PNG", 24, 14, 32, 24);
    } else {
      img.src = myImage;
      pdf.addImage(img, "PNG", 14, 10, 40, 24);
    }

    const gmailImg = new Image();
    gmailImg.src = gmail;

    const phoneImg = new Image();
    phoneImg.src = phone;

    pdf.setFontSize(14);
    pdf.setFont("Helvetica", "Bold");
    pdf.text(client.agency.name, 24, 45);
    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    pdf.text(client.agency.address, 15, 50);
    pdf.text(client.agency.postCode + " " + client.agency.town, 15, 55);
    pdf.text("SIRET  : " + client.agency.siret, 15, 60);
    pdf.text("TVA n° : " + client.agency.tva, 15, 65);
    pdf.addImage(gmailImg, "PNG", 15, 67, 3, 3);

    pdf.text(" : " + client.agency.email, 18, 69.5);
    pdf.addImage(phoneImg, "PNG", 15, 71.5, 3, 3);
    pdf.text(" : " + client.agency.phone, 18, 74);
    /*  pdf.text(
      'Contact : ' +
        quotation.creator.firstname +
        ' ' +
        quotation.creator.lastname,
      15,
      79.5,
    ) */

    pdf.setFontSize(14);
    pdf.setFont("Helvetica", "Bold");
    pdf.text("Devis provisoire", 110, 45);

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");

    /* pdf.text('Date : ', 110, 50)
    pdf.text(formatJsDate(quotation.createdAt), 120, 50)
    pdf.text("Date d'expiration :", 110, 55)
    pdf.text(formatJsDate(quotation.expiryDate), 137, 55) */

    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "Bold");
    pdf.text("Adresse de facturation :", 15, 95);

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    var [partieAvant, codePostal, partieApres] = quotation.addressFacturation
      .trim()
      .match(/^(.*?)\b(\d{5})\b(.*)$/)
      .slice(1)
      .map((str) => str.trim());
    try {
      pdf.text(partieAvant, 15, 100);
      pdf.text(codePostal + " " + partieApres, 15, 105);
    } catch (e) {
      console.log(e);
    }

    pdf.setFontSize(12);
    pdf.setFont("Helvetica", "Bold");
    pdf.text(quotation.client.companyName.toUpperCase(), 110, 80);

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    pdf.text(
      `${quotation.contact.split(" ")[1].toUpperCase()} ${quotation.contact
        .split(" ")[2]
        .toUpperCase()}`,
      110.5,
      85,
    );
    pdf.text(partieAvant, 110.5, 90);
    pdf.text(codePostal + " " + partieApres, 110.5, 95);
    pdf.text("SIRET : " + quotation.client.siret, 110.5, 100);
    pdf.text("TVA : " + quotation.client.TVA, 110.5, 105);

    pdf.setFontSize(12);
    pdf.setFont("Helvetica", "bold");
    pdf.setTextColor(9, 132, 227);
    pdf.setDrawColor(9, 132, 227);

    if (
      quotation.products[0].product === "Placement" ||
      quotation.products[0].product === "Vacation"
    ) {
      pdf.roundedRect(26, 115, 160, 30, 1, 1);
      pdf.text(quotation.products[0].product.toUpperCase(), 95, 120);
      pdf.line(95, 122, 122, 122);

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);

      if(quotation.products[0].product==="Placement"){
        pdf.text(
          "Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD ou CDI.",
          46,
          130,
        );
      }else{
        {
          pdf.text(
            "Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD.",
            46,
            130,
          );
        }
      }
      pdf.text(
        "Le contrat, la DPAE et la fiche de paie sont établis par vos soins. Vous êtes l'employeur.",
        46,
        135,
      );
      pdf.text("Vous recevrez une seule facture pour le placement", 57, 140);
    }

    if (
      quotation.products[0].product === "Délégation" ||
      quotation.products[0].product === "Gestion"
    ) {
      pdf.roundedRect(15, 110, 92, 35, 1, 1);
      pdf.roundedRect(110, 110, 92, 35, 1, 1);
      pdf.text("DELEGATION", 47, 115);
      pdf.line(47, 117, 75, 117);
      pdf.text("GESTION", 145, 115);
      pdf.line(145, 117, 164, 117);

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);

      pdf.text("Vous nous mandatez pour", 17, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("recruter", 55, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text("   et", 65, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("  gérer", 70, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text(" l'administratif", 80, 125);
      pdf.text("de ", 17, 130);
      pdf.setFont("Helvetica", "bold");
      pdf.text("notre", 21, 130);
      pdf.setFont("Helvetica", "normal");
      pdf.text("recrue. Le contrat, la DPAE et la fiche de paie sont", 30, 130);
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        17,
        135,
      );
      pdf.text(
        "la sécurité du salarié. Vous recevrez une facture mensuelle.",
        17,
        140,
      );

      pdf.text("Vous nous mandatez pour", 112, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("gérer", 150, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text("simplement l'administratif", 159, 125);
      pdf.text("de", 112, 130);
      pdf.setFont("Helvetica", "bold");
      pdf.text("votre", 116, 130);
      pdf.setFont("Helvetica", "normal");
      pdf.text(
        "recrue. Le contrat, la DPAE et la fiche de paie sont",
        125,
        130,
      );
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        112,
        135,
      );
      pdf.text(
        "la sécurité du salarié. Vous recevrez une facture mensuelle.",
        112,
        140,
      );
    }

    pdf.setTextColor(0, 0, 0);

    pdf.setFillColor(9, 132, 227);
    pdf.setDrawColor(9, 132, 227);
    pdf.rect(15, 155, 180, 10, "F");

    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "bold");

    if (
      quotation.products[0].product === "Délégation" ||
      quotation.products[0].product === "Gestion"
    ) {
      let height = 170;

      pdf.text("Designation", 25, 161);
      pdf.text("Taux horaire", 60, 161);
      pdf.text("Coefficient", 95, 161);
      pdf.text("Prix HT/heure", 130, 159);
      pdf.text("travaillée", 135, 163);
      pdf.text("Observation", 165, 161);

      pdf.setFontSize(10);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setDrawColor(0, 0, 0);

      quotation.products.map((product, idx) => {
        if (product.type === "Prime") {
          const prime = splitStringWithoutCuttingWords(
            "Prime : " + product.product,
            24,
          );
          for (let i = 0; i < prime.length; i++) {
            pdf.text(prime[i], 20, height);
            height += 5;
          }
          height -= 5;
        } else if (product.type === "Indemnité") {
          const indemnite = splitStringWithoutCuttingWords(
            "Indemnité : " + product.product,
            24,
          );
          for (let i = 0; i < indemnite.length; i++) {
            pdf.text(indemnite[i], 20, height);
            height += 5;
          }
          height -= 5;
        } else if (product.type === "Autre")
          pdf.text(product.product, 20, height);
        else {
          pdf.text(product.product + " H/F", 20, height);
          const poste = splitStringWithoutCuttingWords(product.poste, 24);
          for (let i = 0; i < poste.length; i++) {
            pdf.text(poste[i], 20, height + 5);
            height += 5;
          }
        }
        if (product.taux !== "")
          pdf.text(parseFloat(product.taux).toFixed(2) + "€", 65, height);
        if (product.coefficient !== "")
          pdf.text(product.coefficient, 105, height);
        if (product.taux !== "" && product.coefficient !== "")
          pdf.text(
            (
              parseFloat(product.taux) * parseFloat(product.coefficient)
            ).toFixed(2) + "€",
            140,
            height,
          );
        const comment = splitStringWithoutCuttingWords(product.comment, 24);
        for (let i = 0; i < comment.length; i++) {
          pdf.text(comment[i], 165, height);
          height += 5;
        }
        height += 5;
        pdf.setTextColor(128, 128, 128);
        pdf.setDrawColor(128, 128, 128);
        if (product.minimumHours) {
          pdf.text(
            "Nombre d'heures minimales avant embauche sans facturation : " +
              product.minimumHours +
              "h",
            50,
            height,
          );
          height += 3;
        }

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineWidth(0.5);
        }
        pdf.line(15, height, 195, height);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(0, 0, 0);
        height += 7;
        if (height > 260) {
          pdf.addPage();
          height = 10;
        }
      });

      height += 10;
      if (height > 250) {
        pdf.addPage();
        height = 10;
      }

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");

      pdf.text("Nom et qualité du signataire :", 15, height);
      pdf.text("Date : " + formatJsDate(new Date()), 15, height + 5);

      pdf.text(quotation.client.tvaRate, 120, height);
      pdf.text(
        "Conditions de paiement : " + quotation.client.facturation,
        120,
        height + 5,
      );
      pdf.text("Moyen de règlement : Virement/Chèque/CB", 120, height + 10);
    }

    if (
      quotation.products[0].product === "Placement" ||
      quotation.products[0].product === "Vacation"
    ) {
      let height = 170;

      pdf.text("Designation", 25, 161);
      pdf.text("Quantité", 68, 161);
      pdf.text("PU (HT)", 110, 161);
      pdf.text("Montant (HT)", 160, 161);

      pdf.setFontSize(10);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setDrawColor(0, 0, 0);

      quotation.products.map((product, idx) => {
        pdf.text(product.product + " H/F", 20, height);
        const poste = splitStringWithoutCuttingWords(product.poste, 30);
        for (let p of poste) {
          height += 5;
          pdf.text(p, 20, height);
        }
        console.log(product.taux);
        console.log(product.coefficient);
        if (product.taux !== "")
          pdf.text(parseFloat(product.taux).toFixed(2) + "€", 110, height);
        if (product.coefficient !== "")
          pdf.text(product.coefficient, 75, height);
        if (product.taux !== "" && product.coefficient !== "")
          pdf.text(
            (
              parseFloat(product.taux) * parseFloat(product.coefficient)
            ).toFixed(2) + "€",
            175,
            height,
          );
        height += 5;
        pdf.setTextColor(128, 128, 128);
        pdf.setDrawColor(128, 128, 128);

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineWidth(0.5);
        }
        pdf.line(15, height, 195, height);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(0, 0, 0);
        height += 7;
        if (height > 260) {
          pdf.addPage();
          height = 10;
        }
      });

      if (height > 250) {
        pdf.addPage();
        height = 10;
      }

      pdf.setFillColor(135, 177, 255);
      pdf.setDrawColor(9, 132, 227);
      height += 6;
      pdf.setTextColor(255, 255, 255);
      pdf.setFont("Helvetica", "Bold");

      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Helvetica", "normal");

      pdf.text("Total HT", 110, height);
      pdf.text(
        quotation.products
          .reduce((total, item) => total + parseFloat(item.taux), 0)
          .toFixed(2) + " €",
        174,
        height,
      );

      height += 10;

      pdf.text(
        (
          quotation.products.reduce(
            (total, item) => total + parseFloat(item.taux),
            0,
          ) * 0.2
        ).toFixed(2) + " €",
        174,
        height,
      );
      height += 10;
      pdf.setFillColor(9, 132, 227);
      pdf.rect(105, height - 7, 90, 10, "F");
      pdf.setTextColor(255, 255, 255);
      pdf.text("Total TTC", 110, height);
      pdf.text(
        (
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ) * 0.2,
          ) +
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ),
          )
        ).toFixed(2) + " €",
        174,
        height,
      );

      pdf.setTextColor(0, 0, 0);

      height += 10;

      if (height >= 270) pdf.addPage();

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");

      /* let hauteur=35
      for(let x of comment2){
        pdf.text(x,18,height)
        height+=5
      }
      pdf.text(
        'Conditions de paiement : '+quotation.products[0].comment,
        18,
        height,
      )
      pdf.text('Moyens de règlement : Virement/Chèque/CB', 18, height+5)
      pdf.text(
        'Coordonnées bancaires Asnières : FR76 1005 7190 0300 0208 2410146 - CMCIFRPP',
        18,
        height+10,
      )
      pdf.text(
        'Coordonnées bancaires Brive / Toulouse : FR76 1005 7190 0300 0207 4100 156 - CMCIFRPP',
        18,
        height+15,
      )
      pdf.text('Nom et qualité du signataire :', 18, height+20)
      pdf.text('Date : ' + formatJsDate(new Date()), 18,  height+25) */

      if (height > 280) {
        pdf.addPage();
        height = 10;
      }

      const paymentCondition = splitStringWithoutCuttingWords(
        "Conditions de paiement : " + quotation.products[0].comment,
        70,
      );

      pdf.text("Nom et qualité du signataire :", 15, height);
      pdf.text("Date : " + formatJsDate(new Date()), 15, height + 5);

      pdf.text("Moyen de règlement : Virement/Chèque/CB", 90, height);
      for (let x of paymentCondition) {
        pdf.text(x, 90, height + 5);
        height += 4;
        if (height >= 280) {
          pdf.addPage();
          height = 10;
        }
      }
      if (quotation.products[0].product === "Placement") {
        const replaceCondition = splitStringWithoutCuttingWords(
          "Conditions de remplacement : " + quotation.products[0].comment2,
          70,
        );
        for (let x of replaceCondition) {
          pdf.text(x, 90, height + 10);
          height += 4;
          if (height >= 280) {
            pdf.addPage();
            height = 10;
          }
        }
      }
    }

    pdf.text(
      `J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély, Hall A, 92600 Asnières sur Seine, France`,
      25,
      280,
    );
    pdf.text(
      "SIRET : dd - Code APE : 7820z - N° de TVA : FR52919125518",
      50,
      285,
    );

    //pdf.addPage();
    //pdf.addImage(cgvClient, 'PNG', 10, 10, 198, 282)

    const pdfBase64 = pdf.output("datauristring");
    setVisualizeQuotation(pdfBase64.split(",")[1]);
  };

  const visualize = () => {
    if (!quotation.products.length) {
      message.error("Selectionner au moins un service");
      return;
    }
    console.log(quotation);
    generatePDF(quotation);
    setIsVisualizeQuotation(true);
  };

  const modifyProduct = (product, idx) => {
    setSelectedCoeff(product.coefficient);
    setSelectedTauxHoraire(product.taux);
    setSelectedPoste(product.poste);
    setSelectedProduct(product.product);
    setMinimumHours(product.minimumHours);
    setSelectedComment(product.comment);
    deleteProduct(idx);
  };

  const deleteProduct = (productId) => {
    let newProducts = quotation.products.filter(
      (_, index) => index !== productId,
    );
    let newQuotation = { ...quotation };
    newQuotation.products = newProducts;
    setQuotation(newQuotation);
  };

  const selectStep = (step) => {
    if (step === "2") {
      if (!quotation.client) {
        message.error("Selectionnez un client");
      } else {
        setStep("2");
      }
    }
    if (step === "3") {
      if (!quotation.addressFacturation) {
        message.error("Selectionnez une adresse de facturation");
      } else {
        setStep("3");
      }
    }
    if (step === "4") {
      if (!quotation.addressLivraison) {
        message.error("Selectionnez le lieu de la mission");
      } else {
        setStep("4");
      }
    }
    if (step === "5") {
      if (!quotation.contact) {
        message.error("Selectionnez le contact");
      } else {
        setStep("5");
      }
    }
  };

  const formatJsDate = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const formatJsDate2 = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const addPlacement = () => {
    const item = {
      type: "service",
      product: "Placement",
      poste: selectedPoste,
      comment: selectedComment,
      comment2: selectedComment2,
      coefficient: nombre,
      taux: forfait,
    };
    setSelectedComment("");
    setNombre("");
    setForfait("");
    setSelectedPoste(null);
    let newQuotation;
    if (quotation.products) {
      const newProducts = [...quotation.products, item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    } else {
      const newProducts = [item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    }
    newQuotation.minimumHours = minimumHours;
    setQuotation(newQuotation);
  };

  const addVacation = () => {
    const item = {
      type: "service",
      product: "Vacation",
      poste: selectedPoste,
      comment: selectedComment,
      coefficient: nombre,
      taux: forfait,
    };
    setSelectedComment("");
    setNombre("");
    setForfait("");
    setSelectedPoste(null);
    let newQuotation;
    if (quotation.products) {
      const newProducts = [...quotation.products, item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    } else {
      const newProducts = [item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    }
    newQuotation.minimumHours = minimumHours;
    setQuotation(newQuotation);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1);
    setNumPages(newArray);
  };

  const addGestion = () => {
    const item = {
      type: "service",
      product: "Gestion",
      poste: selectedPoste,
      comment: selectedComment,
      coefficient: selectedCoeff,
      taux: selectedTauxHoraire,
    };
    setSelectedCoeff("");
    setSelectedTauxHoraire("");
    setSelectedComment("");
    setSelectedPoste(null);
    let newQuotation;
    if (quotation.products) {
      const newProducts = [...quotation.products, item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    } else {
      const newProducts = [item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    }
    newQuotation.minimumHours = minimumHours;
    setQuotation(newQuotation);
  };

  const addServiceToBasket = () => {
    const item = {
      type: "service",
      product: selectedProduct,
      poste: selectedPoste,
      comment: selectedComment,
      coefficient: selectedCoeff,
      taux: selectedTauxHoraire,
      minimumHours,
    };
    setSelectedCoeff("");
    setSelectedTauxHoraire("");
    setMinimumHours("");
    setSelectedComment("");
    setSelectedPoste(null);
    let newQuotation;
    if (quotation.products) {
      const newProducts = [...quotation.products, item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    } else {
      const newProducts = [item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    }
    newQuotation.minimumHours = minimumHours;
    setQuotation(newQuotation);
  };

  const addPrimeToBasket = () => {
    const item = {
      type: prime,
      product: primeDetail,
      poste: primeDetail,
      comment: primeComment,
      coefficient: coefficientPrime,
      taux: tauxHorairePrime,
    };
    setPrime("Prime");
    setPrimeDetail("");
    setPrimeComment("");
    setcoefficientPrime("");
    setTauxHorairePrime("");
    let newQuotation;
    if (quotation.products) {
      console.log(quotation.products);
      const newProducts = [...quotation.products, item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    } else {
      const newProducts = [item];
      newQuotation = { ...quotation };
      newQuotation.products = newProducts;
    }
    newQuotation.minimumHours = minimumHours;
    setQuotation(newQuotation);
  };

  const selectProduct = (product) => {
    if (product === "Délégation") {
      setIsDelegation(true);
      setIsGestion(false);
      setIsPlacement(false);
      setIsVacation(false);
    }

    if (product === "Gestion") {
      setIsDelegation(false);
      setIsGestion(true);
      setIsPlacement(false);
      setIsVacation(false);
    }

    if (product === "Placement") {
      setIsDelegation(false);
      setIsGestion(false);
      setIsPlacement(true);
      setIsVacation(false);
    }

    if (product === "Vacation") {
      setIsDelegation(false);
      setIsGestion(false);
      setIsPlacement(false);
      setIsVacation(true);
    }

    console.log(product);
    const newQuotation = { ...quotation };
    newQuotation.products = [];
    setQuotation(newQuotation);
    setSelectedProduct(product);
  };

  const productArray = products.map((product) => {
    return {
      value: product,
      label: product,
    };
  });

  const generateQuotation = () => {
    {
      const content = document.getElementById("cisu");

      // Use html2canvas to capture the content as a canvas
      html2canvas(content).then((canvas) => {
        const pdf = new jsPDF();

        // Convert the canvas to a data URL (PNG format)
        const imageDataURL = canvas.toDataURL("image/png");

        // Create an image element and set its source to the data URL
        const img = new Image();
        img.src = imageDataURL;
        img.width = 784;
        img.height = 1183;

        const downloadLink = document.createElement("a");

        // Set the href attribute to the data URL
        downloadLink.href = imageDataURL;

        // Set the download attribute with the desired file name
        downloadLink.download = "your_image.png"; // You can change the file name here

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click();

        /*   // Calculate the height and width for the PDF page (assuming A4 size)
          const pdfWidth = 210;
          const pdfHeight = 297;

          img.width = 780;
          img.height = 780;
  
          // Add the captured canvas to the PDF
          pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
  
          // Save or display the PDF
          pdf.save("generated.pdf"); */
      });
    }
  };

  const selectAddressFacturation = (e) => {
    const newQuotation = { ...quotation };
    newQuotation.addressFacturation = e;
    setQuotation(newQuotation);
    console.log(quotation);
  };

  const selectAddressLivraison = (e) => {
    const newQuotation = { ...quotation };
    newQuotation.addressLivraison = e;
    setQuotation(newQuotation);
    console.log(quotation);
  };

  const selectClient = () => {
    const newQuotation = quotation;
    newQuotation.client = client;
    setQuotation(newQuotation);
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ];

    const clientContactsArray = client.contacts.map((contact, idx) => {
      return {
        value: `${contact.title || ""} ${contact.firstname || ""} ${
          contact.lastname || ""
        } ${contact.email || ""} ${contact.mobile || ""}`,
        label: `${contact.title || ""} ${contact.firstname || ""} ${
          contact.lastname || ""
        } ${contact.email || ""} ${contact.mobile || ""}`,
        key: idx,
      };
    });

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      };
    });
    setClientAdressesArray(clientAddressesArray);
    setClientContactsArray(clientContactsArray);
  };

  const selectContact = (e) => {
    const newQuotation = { ...quotation };
    console.log(e);
    console.log(e.split(" "));
    newQuotation.contact = e;
    setQuotation(newQuotation);
    console.log(quotation);
  };

  const saveQuotation = () => {
    console.log(quotation);
    axiosInstance
      .post("quotation", quotation)
      .then((res) => {
        console.log(res.data);
        setCreatedQuotation(!createdQuotation);
        closeModal();
      })
      .catch((e) => console.log(e));
  };

  const saveDraft = () => {
    if (!quotation.products.length) {
      message.error("Ajouter au moins un produit");
      return;
    }

    console.log(isUpdate);
    if (isUpdate) {
      axiosInstance
        .put(`quotation/${quotation._id}/update`, quotation)
        .then((res) => {
          console.log(res.data);
          closeModal();
        })
        .catch((e) => console.log(e));
    } else {
      axiosInstance
        .post("quotation/save", quotation)
        .then((res) => {
          console.log(res.data);
          closeModal();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <Modal
      width={1000}
      open={isCreateQuotation}
      onCancel={() => setIsCreateQuotation(false)}
      footer={null}
    >
      {isVisualizeQuotation && (
        <Modal
          className="w-[784px]  flex justify-center"
          open={isVisualizeQuotation}
          onCancel={() => setIsVisualizeQuotation(false)}
          footer={null}
        >
          {/*  <div id="cisu">
            <div className="flex items-center mx-10 mt-4">
              <div className="flex flex-col justify-center w-1/2">
                <p className="flex items-center  mb-6">
                  <img src={myImage} className="w-20" />
                </p>
                <p className="font-bold text-lg -mt-6">J'm intérim</p>
                <p className="text-xs">4 Avenue Laurent Cély, Hall A</p>
                <p className="text-xs">92600 Asnières sur Seine, France</p>
                <p className="text-xs flex items-center space-x-1">
                  <EmailOutlined className="text-sm" />{' '}
                  <span>contact@jm-interim.com</span>
                </p>
                <p className="text-xs flex items-center space-x-1">
                  <PhoneAndroidOutlined className="text-sm" />
                  <span>01.80.88.21.35</span>
                </p>
                <p className="text-xs">SIRET : {quotation.client.siret}</p>
                <p className="text-xs">N° de TVA : {quotation.client.TVA}</p>
              </div>

              <div className="w-1/2 ">
                <div className="flex flex-col items-start">
                  <p className="text-xl font-semibold">Devis provisoire</p>
                  <p className="text-xs">Date : {formatJsDate2(new Date())}</p>
                  <p className="text-xs">
                    Date d'expiration :{' '}
                    {formatJsDate2(
                      new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000),
                    )}
                  </p>
                  <p className="text-xs">Votre contact : {username}</p>
                </div>
              </div>
            </div>

            <div className="flex items-start my-10 mx-10">
              <div className="w-1/2">
                <p className="font-semibold text-sm">
                  Adresse de facturation :
                </p>
                <p className="text-xs">
                  {quotation.client.addressFacturation},{' '}
                </p>
                <p className="text-xs">
                  {quotation.client.postCodeFacturation}{' '}
                  {quotation.client.townFacturation}{' '}
                </p>
              </div>

              <div className="w-1/2  -mt-[52px]">
                <div className="">
                  <p className="font-bold uppercase text-lg">
                    {quotation.client.companyName}
                  </p>
                  <p className="text-xs">{quotation.contact}</p>

                  <p className="text-xs">
                    {quotation.client.addressLivraison},{' '}
                  </p>
                  <p className="text-xs">
                    {quotation.client.postCodeLivraison}{' '}
                    {quotation.client.townLivraison}{' '}
                  </p>
                  <p className="text-xs">SIRET : {quotation.client.siret}</p>
                  <p className="text-xs">TVA : {quotation.client.TVA}</p>
                </div>
              </div>
            </div>

            {quotation.products[0]?.product === 'Délégation' ||
              quotation.products[0]?.product === 'Gestion' && (
                <div className="flex space-x-4 text-xs justify-center mx-6 mb-8">
                  <div className="border border-gray-500 rounded-lg w-1/2 flex flex-col p-4 ">
                    <p className="text-blue-500 underline text-center mb-4 font-semibold">
                      DELEGATION
                    </p>
                    <p className="text-xs text-center mb-4">
                      Vous nous mandatez pour recruter et gérer l'administratif
                      de
                      <span className="font-bold"> notre</span> recrue.<br></br>
                      Le contrat, la DPAE et la fiche de paie sont établis par
                      nous, qui sommes l'employeur. Vous avez à charge la
                      sécurité du salarié. Vous recevrez une facture mensuelle.
                    </p>
                  </div>

                  <div className="border border-gray-500 rounded-lg w-1/2 flex flex-col p-4">
                    <p className="text-blue-500 underline text-center mb-4 font-semibold">
                      GESTION
                    </p>
                    <p className="text-xs text-center mb-4">
                      Vous nous mandatez pour gérer simplement l'administratif
                      de
                      <span className="font-bold"> votre</span> recrue.<br></br>{' '}
                      Le contrat, la DPAE et la fiche de paie sont établis par
                      nous, qui sommes l'employeur. Vous avez à charge la
                      sécurité du salarié. Vous recevrez une facture mensuelle.
                    </p>
                  </div>
                </div>
              )}

            {quotation.products[0]?.product === 'Placement' && (
              <div className="flex space-x-4 text-xs justify-center mx-6 mb-8">
                <div className="border border-gray-500 rounded-lg flex flex-col p-4">
                  <p className="text-blue-500 underline text-center mb-4 font-semibold">
                    Placement
                  </p>
                  <p className="text-xs text-center mb-4">
                    Vous nous mandatez pour recruter au sein de votre
                    entreprise, un poste en CDD ou CDI. Le contrat, la DPAE et
                    la fiche de paie sont établis par vos soins. Vous êtes
                    l'employeur. Vous recevrez une seule facture pour le
                    placement
                  </p>
                </div>
              </div>
            )}

{quotation.products[0]?.product === 'Vacation' && (
              <div className="flex space-x-4 text-xs justify-center mx-6 mb-8">
                <div className="border border-gray-500 rounded-lg flex flex-col p-4">
                  <p className="text-blue-500 underline text-center mb-4 font-semibold">
                    Placement
                  </p>
                  <p className="text-xs text-center mb-4">
                    Vous nous mandatez pour recruter au sein de votre
                    entreprise, un poste en CDD ou CDI. Le contrat, la DPAE et
                    la fiche de paie sont établis par vos soins. Vous êtes
                    l'employeur. Vous recevrez une seule facture pour le
                    placement
                  </p>
                </div>
              </div>
            )}

            {quotation.products[0]?.product === 'Délégation' ||
              (quotation.products[0]?.product === 'Gestion' && (
                <div className="bg-blue-500 flex items-center  mx-6   p-2 font-semibold ">
                  <p className=" text-white text-center w-1/5">Désignation</p>
                  <p className=" text-white text-center w-1/5">taux horaire</p>
                  <p className=" text-white text-center w-1/5">Coefficient</p>
                  <p className=" text-white text-center w-1/5">
                    Prix HT/heure travaillée
                  </p>
                  <p className=" text-white text-center w-1/5">Observation</p>
                </div>
              ))}

            {quotation.products[0]?.product === 'Placement' && (
              <div className="bg-blue-500 flex items-center  mx-6   p-2 font-semibold ">
                <p className=" text-white text-center w-1/4">Désignation</p>
                <p className=" text-white text-center w-1/4">Quantité</p>
                <p className=" text-white text-center w-1/4">PU (HT)</p>
                <p className=" text-white text-center w-1/4">Montant (HT)</p>
              </div>
            )}

            {quotation.products[0]?.product === 'Délégation' ||
              (quotation.products[0]?.product === 'Gestion' && (
                <div className="mt-2 border-b border-b-gray-500 mx-10">
                  {quotation.products.map((product, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`flex flex-col items-start w-full ${
                          product.type === 'service' && idx > 0
                            ? 'border-t border-b-gray-500'
                            : ''
                        } py-1 text-xs`}
                      >
                        <div className="flex items-center justify-between w-full">
                          {product.type === 'service' && (
                            <div className=" w-1/5 flex flex-col  justify-center items-center">
                              <p className="text-center font-semibold text-blue-500">
                                {product.product}
                              </p>
                              <p className="text-center font-bold">
                                {product.poste}
                              </p>
                            </div>
                          )}

                          {(product.type === 'Prime' ||
                            product.type === 'Indemnité') && (
                            <div className="w-1/5 flex flex-col justify-center items-center">
                              <p className="text-center">
                                {product.type} {product.product}
                              </p>
                            </div>
                          )}
                          <p className="w-1/5 text-center">
                            {parseFloat(product.taux).toFixed(2)} &euro;
                          </p>
                          <p className="w-1/5 text-center">
                            {product.coefficient}
                          </p>
                          <p className="w-1/5 text-center">
                            {parseFloat(
                              product.taux * product.coefficient,
                            ).toFixed(2)}{' '}
                            &euro;
                          </p>
                          <p className="w-1/5 text-center italic">
                            {product.comment}
                          </p>
                        </div>
                        {product.product === 'Délégation' && (
                          <p className="w-full text-center">
                            Nombre d'heures minimum avant embauche sans
                            facturation : {product.minimumHours}h
                          </p>
                        )}
                      </div>
                    )
                  })}
                </div>
              ))}

            {quotation.products[0]?.product === 'Placement' && (
              <div className="mt-2 border-b border-b-gray-500 mx-10">
                {quotation.products.map((product, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`flex flex-col items-start w-full ${
                        product.type === 'service' && idx > 0
                          ? 'border-t border-b-gray-500'
                          : ''
                      } py-1 text-xs`}
                    >
                      <div className="flex items-center justify-between w-full">
                        {product.type === 'service' && (
                          <div className=" w-1/4 flex flex-col  justify-center items-center">
                            <p className="text-center font-semibold text-blue-500">
                              {product.product}
                            </p>
                            <p className="text-center font-bold">
                              {product.poste}
                            </p>
                          </div>
                        )}

                        {(product.type === 'Prime' ||
                          product.type === 'Indemnité') && (
                          <div className="w-1/4 flex flex-col justify-center items-center">
                            <p className="text-center">
                              {product.type} {product.product}
                            </p>
                          </div>
                        )}
                        <p className="w-1/4 text-center">
                          {parseFloat(product.coefficient)}
                        </p>
                        <p className="w-1/4 text-center">
                          {parseFloat(product.taux).toFixed(2)} &euro;
                        </p>
                        <p className="w-1/4 text-end">
                          {parseFloat(
                            product.taux * product.coefficient,
                          ).toFixed(2)}{' '}
                          &euro;
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}

            {quotation.products[0]?.product === 'Placement' && (
              <div className="mt-4 mx-10 flex items-center space-x-2">
                <div className="w-2/5">
                  <div className="flex bg-ultramarine-200 p-2 items-center justify-between">
                    <p className="w-1/3  text-center">Base HT</p>
                    <p className="w-1/3  text-center">Taux</p>
                    <p className="w-1/3  text-center">TVA</p>
                  </div>
                  <div className="flex  p-2 items-center justify-between">
                    <p className="w-1/3  text-center">
                      {quotation.products
                        .reduce(
                          (total, item) => total + parseFloat(item.taux),
                          0,
                        )
                        .toFixed(2)}{' '}
                      &euro;
                    </p>

                    <p className="w-1/3  text-center">20,00 %</p>
                    <p className="w-1/3 text-center">
                      {(
                        quotation.products.reduce(
                          (total, item) => total + parseFloat(item.taux),
                          0,
                        ) * 0.2
                      ).toFixed(2)}{' '}
                      &euro;
                    </p>
                  </div>
                  <div></div>
                </div>
                <div className="w-3/5 flex flex-col space-y-2 mt-6">
                  <div className="flex items-center justify-between">
                    <p className="w-1/3"></p>
                    <p className="w-1/3">Total HT</p>
                    <p className="w-1/3 text-end">
                      {quotation.products
                        .reduce(
                          (total, item) => total + parseFloat(item.taux),
                          0,
                        )
                        .toFixed(2)}{' '}
                      &euro;
                    </p>
                  </div>

                  <p className="border-b border-b-blue-500 ml-20"></p>

                  <div className="flex items-center justify-between">
                    <p className="w-1/3"></p>
                    <p className="w-1/3">Total TVA</p>
                    <p className="w-1/3 text-end">
                      {(
                        quotation.products.reduce(
                          (total, item) => total + parseFloat(item.taux),
                          0,
                        ) * 0.2
                      ).toFixed(2)}{' '}
                      &euro;
                    </p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="w-1/3"></p>
                    <p className="w-1/3">Total TTC</p>
                    <p className="w-1/3 text-end">
                      {(
                        parseFloat(
                          quotation.products.reduce(
                            (total, item) => total + parseFloat(item.taux),
                            0,
                          ) * 0.2,
                        ) +
                        parseFloat(
                          quotation.products.reduce(
                            (total, item) => total + parseFloat(item.taux),
                            0,
                          ),
                        )
                      ).toFixed(2)}{' '}
                      &euro;
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            )}

            {quotation.products[0]?.product === 'Délégation' ||
              (quotation.products[0]?.product === 'Gestion' && (
                <div className=" mt-4 text-xs mb-10 pb-10 mx-10">
                  <div className="flex items-start justify-around">
                    <div className="flex flex-col w-1/2">
                      <p className="">Nom et qualité du signataire :</p>
                      <p className="">Date : </p>
                    </div>

                    <div className="w-1/2">
                      <div className="flex items-center">
                        <p className="">{quotation.client.tvaRate}</p>
                      </div>

                      <div className="flex items-center">
                        <p className="">
                          Conditions de paiement :{' '}
                          {quotation.client.facturation}
                        </p>
                      </div>

                      <div className="flex items-center">
                        <p className="">
                          Moyen de règlement : Virement/Cheque/CB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {quotation.products[0]?.product === 'Placement' && (
              <div className="text-sm mt-6 mx-6">
                <p>
                  50% le jour de l'embauche et 50% sous 30 jours date à date.
                </p>
                <p className="text-xs mb-8">
                  Si le(a) candidat(e) ne convient pas au poste, dans un délai
                  de 30 jours après l'embauche, nous nous engageons à rechercher
                  un(e) nouveau(velle) candidat(e) sans frais supplémentaires.
                  Si aucun(e) candidat(e) n'a pu être recruté(e) dans un délai
                  raisonnable, seulement 50% de la facture sera due, le jour de
                  l'embauche.
                </p>
                <p>Conditions de paiement : 30 jours</p>

                <p>Moyens de règlement : Virement bancaire</p>
                <p>
                  Coordonnées bancaires Asnières : FR76 1005 7190 0300 0208 2410
                  146 - CMCIFRPP
                </p>
                <p>
                  Coordonnées bancaires Brive / Toulouse : FR76 1005 7190 0300
                  0207 4100 156 - CMCIFRPP
                </p>
              </div>
            )}
            <hr className="my-6"></hr>
            <p className="text-center text-xs">
              J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély,
              Hall A, 92600 Asnières sur Seine, France
            </p>

            <p className="text-center text-xs">
              SIRET : 91912551800010 - Code APE : 7820z - N° de TVA :
              FR52919125518
            </p>
          </div> */}

          <Document
            file={`data:application/pdf;base64,${visualizeQuotation}`}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            {numPages.map((x, idx) => {
              return <Page key={idx} scale={5 / 3} pageNumber={idx + 1} />;
            })}
          </Document>

          <p className="flex justify-center mt-6">
            <button
              onClick={saveQuotation}
              className="rounded-md text-white bg-mountain-500 px-2 py-1"
            >
              Generer le devis
            </button>
          </p>
        </Modal>
      )}

      <div>
        {step === "1" && (
          <div>
            <p className="font-semibold text-center text-3xl">
              Selectionner l'adresse de facturation
            </p>

            <div className="flex flex-col space-y-2 mt-10">
              <Select
                className="w-full  my-2"
                showSearch
                onChange={(e) => selectAddressFacturation(e)}
                optionFilterProp="children"
                placeholder="Adresse de facturation"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clientAddressesArray}
              />
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep("2")}
                className="bg-mountain-500 rounded-md p-4 text-white"
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === "2" && (
          <div>
            <p className="font-semibold text-center text-3xl">
              Selectionner le contact
            </p>

            <Select
              className="w-full my-2"
              showSearch
              onChange={(e) => selectContact(e)}
              optionFilterProp="children"
              placeholder="Contact"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={clientContactsArray}
            />
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => setStep("1")}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep("3")}
                className="bg-mountain-500 rounded-md p-4 text-white"
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === "3" && (
          <div>
            <p className="font-semibold text-center text-3xl">
              Selectionner le service
            </p>

            <Select
              className="w-full h-10 mt-2"
              showSearch
              onChange={(e) => selectProduct(e)}
              optionFilterProp="children"
              placeholder="Service"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={productArray}
              value={selectedProduct}
            ></Select>

            {(isDelegation || isGestion) && (
              <div>
                <Select
                  className="w-full  h-10 my-2"
                  showSearch
                  onChange={(e) => setSelectedPoste(e)}
                  optionFilterProp="children"
                  placeholder="Poste"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={qualificationsArray}
                  value={selectedPoste}
                ></Select>

                <p className="text-2xl font-bold mb-4">HEURES</p>
                <div className="flex flex-col items-start justify-center">
                  <div className="flex mb-2 items-center space-x-2 w-full">
                    <Input
                      onChange={(e) => setSelectedTauxHoraire(e.target.value)}
                      placeholder="Taux horaire"
                      className="rounded-md border h-10 w-1/3 border-gray-300"
                      value={selectedTauxHoraire}
                    />
                    <Input
                      onChange={(e) => setSelectedCoeff(e.target.value)}
                      placeholder="Coefficient"
                      className=" rounded-md border h-10 w-1/3 border-gray-300"
                      value={selectedCoeff}
                    />

                    {selectedCoeff && selectedTauxHoraire && (
                      <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                        {(
                          parseFloat(selectedCoeff.replace(",", ".")) *
                          parseFloat(selectedTauxHoraire.replace(",", "."))
                        ).toFixed(2)}
                        &euro; HT/HEURE
                      </p>
                    )}

                    {(!selectedCoeff.length || !selectedTauxHoraire.length) && (
                      <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                        0 &euro; HT/HEURE
                      </p>
                    )}
                  </div>

                  {isDelegation && (
                    <div className="mb-2 flex  space-x-2 mr-4 w-full items-start">
                      <Input
                        type="numeric"
                        placeholder="Nombre d'heures minimum pour une embauche sans facturation"
                        onChange={(e) => setMinimumHours(e.target.value)}
                        className="rounded-md border h-10 w-full border-gray-300"
                        value={minimumHours}
                      />
                    </div>
                  )}
                  <div className="mb-10 flex  space-x-2 mr-4 w-full items-start">
                    <Input
                      placeholder="Commentaire"
                      onChange={(e) => setSelectedComment(e.target.value)}
                      className="rounded-md border h-10 w-full border-gray-300"
                      value={selectedComment}
                    />
                  </div>
                </div>

                <div className="flex justify-center w-full">
                  <p
                    onClick={addServiceToBasket}
                    className="bg-[#6022ea] hover:cursor-pointer rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-white "
                  >
                    Ajouter heures
                  </p>
                </div>

                <div className="flex space-x-3 items-center justify-center w-full">
                  <p className="text-xl font-semibold">
                    Si vous souhaitez ajouter des primes cliquez sur ce bouton :
                  </p>
                  <p
                    onClick={() => setIsAddPrime(true)}
                    className="bg-[#6022ea] hover:cursor-pointer rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-white "
                  >
                    Primes
                  </p>
                </div>

                {isAddPrime && (
                  <div>
                    <p className="text-2xl font-bold mb-2">PRIMES/INDEMNITES</p>
                    <p className="text-sm mb-2 italic">
                      Les indemnités ne sont pas assujetties aux charges
                      sociales alors que les primes le sont.
                    </p>

                    <div className="flex space-x-2 mb-2">
                      <Select
                        className="w-1/2  h-8"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => setPrime(e)}
                        placeholder="Prime"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          { value: "Prime", label: "Prime", key: 0 },
                          { value: "Indemnité", label: "Indemnité", key: 1 },
                          { value: "Autre", label: "Autre", key: 2 },
                        ]}
                      />
                      <Input
                        onChange={(e) => setPrimeDetail(e.target.value)}
                        className=" rounded-md border h-8 w-1/2 border-gray-300"
                        placeholder="Nom de la prime ou de l'indemnité"
                        value={primeDetail}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <div className="flex mb-2 items-center space-x-2 w-full">
                        <Input
                          onChange={(e) => setTauxHorairePrime(e.target.value)}
                          placeholder="Taux horaire ou montant"
                          className="rounded-md border h-10 w-1/3 border-gray-300"
                          value={tauxHorairePrime}
                        />
                        <Input
                          placeholder="Coefficient"
                          className=" rounded-md border h-10 w-1/3 border-gray-300"
                          onChange={(e) => setcoefficientPrime(e.target.value)}
                          value={coefficientPrime}
                        />

                        {coefficientPrime && tauxHorairePrime && (
                          <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                            {(
                              parseFloat(coefficientPrime.replace(",", ".")) *
                              parseFloat(tauxHorairePrime.replace(",", "."))
                            ).toFixed(2)}
                            &euro; HT/HEURE
                          </p>
                        )}

                        {(!coefficientPrime.length ||
                          !tauxHorairePrime.length) && (
                          <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                            0 &euro; HT/HEURE
                          </p>
                        )}
                      </div>

                      <div className="mb-4 flex  space-x-2 mr-4 w-full items-start">
                        <Input
                          placeholder="Commentaire"
                          onChange={(e) => setPrimeComment(e.target.value)}
                          className="rounded-md border h-10 w-full border-gray-300"
                          value={primeComment}
                        />
                      </div>

                      <div className="flex justify-center w-full">
                        {/*   <Popconfirm
                    title="Ajouter prime"
                    description={`Ajouter cette prime au service ${quotation.products.length?quotation.products[quotation.products.length-1].product+" "+quotation.products[quotation.products.length-1].poste:""} ?`}
                    cancelText="NON"
                    okText="OUI"
                    onConfirm={addPrimeToBasket}
                    > */}
                        <Button
                          onClick={addPrimeToBasket}
                          className="bg-[#6022ea] hover:cursor-pointer rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-white "
                        >
                          Ajouter prime
                        </Button>

                        {/*  </Popconfirm> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* 
            {isGestion && (
              <div>
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Poste"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={qualificationsArray}
                ></Select>

                <Input
                  value={selectedTauxHoraire}
                  onChange={(e) => setSelectedTauxHoraire(e.target.value)}
                  placeholder="taux horaire"
                  className="mb-2"
                />
                <Input
                  value={selectedCoeff}
                  onChange={(e) => setSelectedCoeff(e.target.value)}
                  placeholder="coefficient"
                  className="mb-2"
                />
                <Input
                  value={selectedComment}
                  onChange={(e) => setSelectedComment(e.target.comment)}
                  placeholder="Commentaire"
                  className="mb-2"
                />
              </div>
            )} */}

            {isPlacement && (
              <div>
                <Select
                  className="w-full my-2"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={qualificationsArray}
                ></Select>

                <Input
                  onChange={(e) => setNombre(e.target.value)}
                  value={nombre}
                  placeholder="Nombre"
                  className="mb-2"
                />
                <Input
                  onChange={(e) => setForfait(e.target.value)}
                  value={forfait}
                  placeholder="forfait"
                  className="mb-2"
                />
                <p className="text-xl my-2">Conditions de paiement : </p>

                <Input
                  onChange={(e) => setSelectedComment(e.target.value)}
                  placeholder="Ex : 50% le jour de l'embauche et 50% sous 30 jours date à date."
                  className="mb-2"
                />

                <p className="text-xl my-2">
                  Conditions des changements de candidats :{" "}
                </p>
                <TextArea
                  rows={4}
                  onChange={(e) => setSelectedComment2(e.target.value)}
                  placeholder="Ex : Si le(a) candidat(e) ne convient pas au poste, dans un délai de 30 jours après l'embauche, nous nous engageons à rechercher un(e) nouveau(velle) candidat(e) sans frais supplémentaires.Si aucun(e) candidat(e) n'a pu être recruté(e) dans un délai"
                  className="mb-2 h-24 border border-black"
                />

                <div className="flex justify-center w-full">
                  <p
                    onClick={addPlacement}
                    className="bg-[#6022ea] rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-white "
                  >
                    Ajouter placement
                  </p>
                </div>
              </div>
            )}

            {isVacation && (
              <div>
                <Select
                  className="w-full my-2"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={qualificationsArray}
                ></Select>

                <Input
                  onChange={(e) => setNombre(e.target.value)}
                  value={nombre}
                  placeholder="Nombre"
                  className="mb-2"
                />
                <Input
                  onChange={(e) => setForfait(e.target.value)}
                  value={forfait}
                  placeholder="forfait"
                  className="mb-2"
                />
                <p className="text-xl my-2">Conditions de paiement : </p>

                <Input
                  onChange={(e) => setSelectedComment(e.target.value)}
                  placeholder="Ex : 50% le jour de l'embauche et 50% sous 30 jours date à date."
                  className="mb-2"
                />

                <div className="flex justify-center w-full">
                  <p
                    onClick={addVacation}
                    className="bg-[#6022ea] rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-white"
                  >
                    Ajouter vacation
                  </p>
                </div>
              </div>
            )}

            {/*  <p>
                je veux pouvoir mettre plusieurs services sur le devis 
                il doit y avoir la liste des services - delegation
                ensuite le choix du poste avec la liste qui existe déjà
                ensuite un champ libre numérique taux horaire qui sera facultative
                ensuite un champ libre numérique avec le coef ex : 1,89

                pour le placement ce sont juste des champs nombre et forfait pas de taux horaire ni de COEF



               </p> */}
            <div className="p-1 flex items-center justify-between  bg-blue-50 text-blue-400 mb-4">
              <p className="w-1/6 font-semibold text-center">SERVICE</p>
              <p className="w-1/6 font-semibold text-center">POSTE H/F</p>
              <p className="w-1/6 font-semibold text-center ">TAUX HORAIRE</p>
              <p className="w-1/6 font-semibold text-center ">COEFFICIENT</p>
              <p className="w-1/6 font-semibold text-center ">TOTAL</p>{" "}
              <p className="w-1/6 font-semibold text-center">COMMENTAIRE</p>
            </div>

            {quotation.products &&
              quotation.products.map((product, idx) => {
                return (
                  <div className="flex items-center justify-between" key={idx}>
                    {product.type === "service" && (
                      <p className="w-1/6 text-center">{product.product}</p>
                    )}
                    {product.type === "service" && (
                      <p className="w-1/6 text-center">{product.poste}</p>
                    )}
                    {(product.type === "Prime" ||
                      product.type === "Indemnité") && (
                      <p className="w-1/6 text-center"></p>
                    )}
                    {(product.type === "Prime" ||
                      product.type === "Indemnité") && (
                      <p className="w-1/6 text-center">
                        {product.type} {product.product}
                      </p>
                    )}
                    <p className="w-1/6 text-center">
                      {product.taux && product.taux}{" "}
                    </p>
                    <p className="w-1/6 text-center">
                      {product.coefficient && product.coefficient}
                    </p>
                    <p className="w-1/6 text-center">
                      {product.taux &&
                        (
                          parseFloat(product.taux) *
                          parseFloat(product.coefficient)
                        ).toFixed(2)}
                    </p>
                    <div className="w-1/6 flex items-center justify-between space-x-1">
                      <p>{product.comment || ""}</p>
                      <p className="flex items-center space-x-1">
                        <span
                          className="hover:cursor-pointer"
                          onClick={() => deleteProduct(idx)}
                        >
                          <DeleteForeverIcon className="text-red-500" />
                        </span>
                        <span
                          className="hover:cursor-pointer"
                          onClick={() => modifyProduct(product, idx)}
                        >
                          <ModeEditIcon className="text-blue-500" />
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                );
              })}

            <div className="flex items-center justify-center space-x-6 mt-10">
              <button
                onClick={() => setStep("2")}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              {/*  <button
                onClick={saveDraft}
                className="bg-mountain-100 rounded-md p-4 text-mountain-500"
              >
                Enregistrer au brouillon
              </button> */}
              <button
                onClick={visualize}
                className="bg-[#6022ea] rounded-md p-4 text-white"
              >
                Visualiser
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateQuotationModal;
