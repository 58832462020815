import { DatePicker, Input, Modal, Select, message } from "antd";
import axios from "../../axios/AxiosInstance";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AjoutArret = ({ candidate, isAddArret, setIsAddArret }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [typeAbsence, setTypeAbsence] = useState("");
  const [otherTypeAbsence, setOtherTypeAbsence] = useState("");
  const navigate = useNavigate();
  const inputFileRef = useRef(null);

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const addArret = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("candidateId", candidate._id);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("typeAbsence", typeAbsence);
    formData.append("otherTypeAbsence", otherTypeAbsence);
    formData.append(
      "candidateName",
      candidate.firstname + " " + candidate.lastname,
    );
    axios
      .post("/arret", formData)
      .then((res) => {
        setSelectedFile(null);
        message.success("arret ajoute !");
        setIsAddArret(false);
      })
      .catch((e) => {
        setSelectedFile(null);
        console.log(e);
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <Modal
      open={isAddArret}
      footer={null}
      onCancel={() => setIsAddArret(false)}
    >
      <div className="flex flex-col items-center justify-center space-y-4">
        <div
          onClick={handleClick}
          className="border w-96 border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
        >
          <CloudUploadIcon />
          <p className="mt-2">Ajouter document</p>
          <p className="text-black">{selectedFile?.name}</p>
          <input
            type="file"
            onChange={(e) => handleFileChange(e)}
            className="hidden"
            ref={inputFileRef}
          />
        </div>

        <Select
          className=" mt-10 h-8 px-2 py-1  text-gray-600 font-light rounded-md placeholder-gray-400 w-96 "
          showSearch
          placeholder="Type absence"
          onChange={(e) => setTypeAbsence(e)}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "Arret maladie",
              label: "Arret maladie",
              key: "Arret maladie",
            },
            {
              value: "Accident de travail",
              label: "Accident de travail",
              key: "Accident de travail",
            },
            {
              value: "Maladie professionnelle",
              label: "Maladie professionnelle",
              key: "Maladie professionnelle",
            },
            {
              value: "Congé maternité",
              label: "Congé maternité",
              key: "Congé maternité",
            },
            {
              value: "Congé paternité",
              label: "Congé paternité",
              key: "Congé paternité",
            },
            {
              value: "Autres absences",
              label: "Autres absences (à preciser)",
              key: "Autres absences",
            },
          ]}
        />

        {typeAbsence === "Autres absences" && (
          <Input
            className="w-96  rounded-md border border-gray-200 focus:outline-none"
            placeholder="Autre"
            style={{ outline: "none" }}
            onChange={(e) => setOtherTypeAbsence(e.target.value)}
          />
        )}

        <div className="flex items-center justify-center space-x-2">
          <DatePicker
            onChange={(e) => setStartDate(e.$d)}
            value={startDate ? dayjs(startDate) : null}
            className="w-1/2 h-10"
            format="DD/MM/YYYY"
            placeholder="Date de debut"
          />

          <DatePicker
            onChange={(e) => setEndDate(e.$d)}
            value={endDate ? dayjs(endDate) : null}
            className="w-1/2 h-10"
            format="DD/MM/YYYY"
            placeholder="Date de fin"
          />
        </div>

        <button
          className="rounded-md bg-mountain-500 text-white p-2 "
          onClick={addArret}
        >
          Ajouter arret
        </button>
      </div>
    </Modal>
  );
};

export default AjoutArret;
