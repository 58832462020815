import { Dialog } from "@headlessui/react";
import qualificationsData from "../utils/qualifications";
import { useState, useRef, useEffect } from "react";
import AxiosInstance from "../../axios/AxiosInstance";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Alert from "@mui/material/Alert";
import { Icon } from "@iconify/react";
import { Modal, TreeSelect, message } from "antd";
import CandidateModal from "./CandidateModal";
import { useNavigate } from "react-router-dom";

const CreateCandidatModal = ({
  isCreateCandidate,
  setIsCreateCandidate,
  candidates,
  setCandidates,
}) => {
  const inputFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [qualifications, setQualifications] = useState([]);

  const [createdCandidate, setCreatedCandidate] = useState(null);
  const [isCreatedCandidate, setIsCreatedCandidate] = useState(false);

  const [isOpenCandidateModal, setIsOpenCandidateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Programmatically trigger the file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      setSelectedFile(event.target.files[0]);
    } else {
      message.error("Veuillez ajouter un fichier pdf !");
    }
  };
  const qualificationData = qualificationsData.map((qualification) => {
    return {
      title: qualification,
      value: qualification,
      key: qualification,
    };
  });

  const onChangeQualification = (newValue) => {
    setQualifications(newValue);
  };

  const qualifProps = {
    treeData: qualificationData,
    onChange: onChangeQualification,
    value: qualifications,
    treeCheckable: true,
    placeholder: "Qualification",
  };

  const openCandidateModal = (user) => {
    setIsOpenCandidateModal(true);
    setIsCreatedCandidate(true);
  };

  const addCandidate = () => {
    if (loading) return;
    setLoading(true);
    if (
      !phone.length ||
      !firstname.length ||
      !lastname.length ||
      !email.length ||
      !qualifications.length ||
      !selectedFile
    ) {
      message.error("Veuillez remplir tous les champs!");
      setLoading(false);
      return;
    }
    const newPhone = phone.replaceAll(" ", "").replaceAll(".", "");

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("phone", newPhone);
    formData.append("email", email);
    formData.append("qualifications", qualifications);

    AxiosInstance.post("tempworker-candidate", formData)
      .then((res) => {
        setShowErrorAlert(false);
        openCandidateModal(res.data);
        setCreatedCandidate(res.data);
        const newCandidates = [...candidates];
        newCandidates.push(res.data);
        setCandidates(newCandidates);
        resetFields();
        setIsCreateCandidate(false);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
          return;
        }
        const message = e.response.data.message;
        console.log(e);
        if (message === "candidate already exists")
          setShowErrorMessage("Le candidat existe deja !");
        else setShowErrorMessage("Veuillez remplir tous les champs !");
        setShowErrorAlert(true);
      });
  };

  const resetFields = () => {
    setQualifications([]);
    setFirstname("");
    setLastname("");
    setPhone("");
    setEmail("");
    setSelectedFile(null);
  };

  return (
    <div>
      {isOpenCandidateModal && (
        <CandidateModal
          isOpen={isOpenCandidateModal}
          setIsOpen={setIsOpenCandidateModal}
          selectedUser={createdCandidate}
        />
      )}

      {isCreateCandidate && (
        <Modal
          width={1000}
          open={isCreateCandidate}
          footer={null}
          onCancel={() => setIsCreateCandidate(false)}
        >
          <div className="flex w-3/5 flex-col justify-center text-center  mt-10 h-full mx-auto">
            <div className="flex items-center space-x-2 mb-6">
              <input
                onChange={(e) => setFirstname(e.target.value)}
                name="firstname"
                type="text"
                placeholder="Prenom"
                className="grow max-h-12  py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              />
              <input
                onChange={(e) => setLastname(e.target.value)}
                name="lastname"
                type="text"
                placeholder="Nom"
                className="grow max-h-12  py-2  focus:oultine-none border rounded-md border-[#00000040] border-mountain-00 placeholder-[#00000040]"
              />
            </div>
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              placeholder="Email"
              className=" mb-6 max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              type="text"
              placeholder="Telephone"
              className=" mb-6 max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            />
            <div className="mb-4">
              <TreeSelect
                className="placeholder-[#00000040] w-full text-start"
                size="large"
                {...qualifProps}
              />
            </div>
            <div
              onClick={handleClick}
              className="border border-[#00000040] py-3 text-[#00000040] rounded-md"
            >
              <CloudUploadIcon />
              <p className="mt-2">Ajouter CV</p>
              <p className="text-black">{selectedFile?.name}</p>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="hidden"
                ref={inputFileRef}
              />
            </div>

            {showErrorAlert && (
              <Alert severity="error" onClose={() => setShowErrorAlert(false)}>
                {showErrorMessage}
              </Alert>
            )}

            <div className="flex mb-2 items-center justify-center w-full">
              <button
                onClick={addCandidate}
                className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
              >
                {!loading && <span>Ajouter candidat</span>}
                {loading && <span>...Patientez</span>}
              </button>
            </div>

            {showSuccessAlert && (
              <Alert
                severity="success"
                onClose={() => setShowSuccessAlert(false)}
              >
                Candidat ajouté!
              </Alert>
            )}
          </div>
        </Modal>
      )}

      {/* <Dialog
        as="div"
        className="relative z-10 "
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          setSelectedFile(null)
        }}
      >
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full bg-[rgba(63,67,66,0.39)] items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  max-h-[80%] w-[60rem]">
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <Icon
                  icon={'ic:round-close'}
                  width={25}
                  onClick={() => {
                    setIsOpen(false)
                    setSelectedFile(null)
                  }}
                  className="hover:cursor-pointer"
                />
              </div>
              <div className="py-16 h-full bg-white">
                <div className="grow w-full flex flex-col justify-center h-full sm:flex sm:items-start gap-8 ">
                  <p className="text-3xl font-light uppercase mx-auto">
                    Créer un candidat
                  </p>
                  
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

export default CreateCandidatModal;
