import axios from "axios";

const instance = axios.create({
  baseURL: "https://admin.jm-interim.com:3000",
  //baseURL: 'http://localhost:3000',
});

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/* instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // Handle 403 Forbidden error by redirecting to the login page
      // Replace '/login' with the actual login page URL
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
); */

export default instance;
