import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import AxiosInstance from "../../axios/AxiosInstance";
import moment from "moment";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export default function ClientComment({ client, clients, setClients }) {
  const [commentaire, setCommentaire] = useState("");
  const [comments, setComments] = useState(client.comments);

  const navigate = useNavigate();

  const updateClient = () => {
    let newComment = {
      comment: commentaire,
      createdAt: Date.now(),
      commentator: localStorage.getItem("name"),
    };
    AxiosInstance.post(`client/${client._id}/update-comment`, {
      comments: [newComment, ...comments],
    })
      .then((res) => {
        message.success("Commentaire ajouté ! ");
        setComments([newComment, ...comments]);
        setCommentaire("");
        const clientIndex = clients.findIndex((cl) => cl._id === res.data._id);
        const newClients = [...clients];
        newClients[clientIndex] = res.data;
        setClients(newClients);
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <div className="bg-yellow-50 h-96 overflow-scroll p-2">
      <div className="w-full  p-4 mb-8  rounded-lg ">
        <div>
          <div className="flex flex-col justify-between grow mb-4">
            <TextArea
              showCount
              style={{ resize: "none", backgroundColor: "rgb(253,253,234)" }}
              className="mb-4 h-24 bg-yellow-50"
              placeholder="Commentaires"
              onChange={(e) => setCommentaire(e.target.value)}
              value={commentaire}
            />
            <div className="text-end">
              <button
                onClick={updateClient}
                type="button"
                className=" text-white bg-yellow-500   border-2 border-transparent outline-0 font-medium rounded-lg text-base px-5 py-2.5 text-center inline-flex items-center  mr-2 mb-2 gap-2"
              >
                <Icon
                  icon={"material-symbols:edit-square-outline"}
                  width={25}
                />
                <p>Ajouter le commentaire</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {comments?.map((comment, idx) => {
        return (
          <div
            key={idx}
            className="flex flex-col p-2 my-2 mx-2 text-yellow-500 border-b border-b-yellow-200 bg-yellow-50 rounded-md "
          >
            <div className="flex items-center space-x-1 text-sm">
              <p className="capitalize">{comment.commentator}</p>
              <p>{moment(comment.createdAt).format("DD/MM/YYYY HH:mm")}</p>
            </div>
            <div>
              {comment.comment.split("\n").map((comm) => {
                return <p>{comm}</p>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
