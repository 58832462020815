import { Input, message } from 'antd'
import axios from '../axios/AxiosInstance'
import { useState } from 'react'

const ForgotPassword = () => {
  const [username, setUsername] = useState('')
  const [askedPassword, setAskedPassword] = useState(false)

  const askPassword = () => {
    axios
      .post('/auth/ask-reset-password', { username })
      .then((res) => {
        setAskedPassword(true)
        setUsername('')
      })
      .catch((e) => {
        message.error("Le login n'existe pas ! ")
      })
  }

  return (
    <div className="h-screen w-full flex justify-center items-center">
      {!askedPassword && (
        <div className="flex w-1/2 flex-col h-1/2 items-center justify-center border space-y-6 rounded-md border-gray-300 p-4">
          <p className="text-2xl font-bold ">Reinitialiser le mot de passe</p>
          <p className="text-sm">
            Pour reinitialiser votre mot de passe veuillez entrer votre login
          </p>
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-3/5 border border-gray-300 p-2"
            placeholder="login"
          />
          <button
            onClick={askPassword}
            className="w-3/5 bg-mountain-500 text-white p-2 rounded-md "
          >
            Reinitialiser le mot de passe
          </button>
        </div>
      )}

      {askedPassword && (
        <div className="flex w-1/2 flex-col h-1/2 items-center justify-center border space-y-6 rounded-md border-green-300 p-4">
          <p className="text-green-400 font-semibold text-xl">
            Vous allez recevoir un mail avec un lien pour reinitialiser votre
            mot de passe
          </p>
        </div>
      )}
    </div>
  )
}

export default ForgotPassword
