import { useEffect, useState } from "react";
import axios from "../../axios/AxiosInstance";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";
import {
  DatePicker,
  Drawer,
  Input,
  Modal,
  Select,
  TimePicker,
  message,
} from "antd";
import { DesktopTimePicker } from "@mui/x-date-pickers";

const CreateTask = ({
  isOpenCreateTask,
  setIsOpenCreateTask,
  tasks,
  setTasks,
}) => {
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUserId(userId);
    getAgencyUsers();
  }, []);

  const [comment, setComment] = useState("");
  const [taskType, setTaskType] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [place, setPlace] = useState("");
  const [timer, setTimer] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [takers, setTakers] = useState([]);
  const [taker, setTaker] = useState("");

  const getAgencyUsers = () => {
    axios("user").then((res) => {
      const usersArray = res.data.map((user) => {
        return {
          value: user._id,
          label: `${user.firstname} ${user.lastname}`,
          key: user._id,
        };
      });
      setTakers(usersArray);
    });
  };

  const resetTaskFields = () => {
    setComment("");
    setTaskType("Taches");
    setDate(null);
    setTime(null);
    setPlace("");
    setSelectedClient(null);
    setSelectedCandidate(null);
    setSelectedCandidates([]);
    setSelectedClients([]);
  };

  const searchCandidate = (word) => {
    if (word.length === 0) {
      setSelectedCandidates([]);
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer);
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer);

      const timer2 = setTimeout(() => {
        axios
          .post("tempworker-candidate/freelance/find-by-text", { query: word })
          .then((res) => {
            setSelectedCandidates(res.data);
          })
          .catch((e) => console.log(e));
      }, 700);
      setTimer(timer2);
    }
  };

  const searchClient = (word) => {
    if (word.length === 0) {
      setSelectedClients([]);
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer);
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer);

      const timer2 = setTimeout(() => {
        axios
          .post("client/freelance/find-by-text", { query: word })
          .then((res) => {
            setSelectedClients(res.data);
          })
          .catch((e) => console.log(e));
      }, 700);
      setTimer(timer2);
    }
  };

  const createTask = () => {
    if (!time || !comment.length || !date || !taker.length) {
      message.error("Veuillez remplir tout les champs !");
      return;
    }
    const data = {};
    const newDate = date;
    newDate.setHours(10);
    data.taskCreator = userId;
    data.type = taskType;
    data.date = newDate;
    data.comment = comment;
    data.taskManager = taker;
    data.hour =
      time.getHours().toString().padStart(2, "0") +
      ":" +
      time.getMinutes().toString().padStart(2, "0");
    if (selectedClient) data.client = selectedClient._id;
    if (selectedCandidate) data.candidate = selectedCandidate._id;
    axios
      .post("task", data)
      .then((res) => {
        console.log(res.data);
        const newTasks = [res.data, ...tasks];
        setTasks(newTasks);
        message.success("Tâche créée ! ");
        resetTaskFields();
        setIsOpenCreateTask(false);
      })
      .catch((e) => {
        message.error("Une erreur s'est produite ! ");
        console.log(e);
      });
  };

  return (
    <Modal
      footer={null}
      width={600}
      open={isOpenCreateTask}
      onCancel={() => {
        setIsOpenCreateTask(false);
        resetTaskFields();
      }}
    >
      <div className="flex flex-col  justify-center space-y-2 mt-10">
        <Select
          className="w-full placeholder-black outline-none text-black  rounded-md"
          optionFilterProp="children"
          placeholder="Type"
          value={taskType ? taskType : null}
          onChange={(e) => {
            setTaskType(e);
            setComment("");
            setDate(null);
            setTime(null);
            setPlace("");
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              label: "Taches",
              value: "Taches",
              key: "Taches",
            },
            {
              label: "Appel",
              value: "Appel",
              key: "Appel",
            },
            {
              label: "Deplacement",
              value: "Deplacement",
              key: "Deplacement",
            },
            {
              label: "Autre",
              value: "Autre",
              key: "Autre",
            },
          ]}
        />
        {taskType === "Taches" && (
          <div className="flex flex-col space-y-1">
            <DatePicker
              onChange={(date, dateString) => setDate(date.$d)}
              value={date ? dayjs(date) : null}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Date de la tache"
              locale={locale}
              format={"DD/MM/YYYY"}
            />
            <DesktopTimePicker
              format={"HH:mm"}
              value={time ? dayjs(time) : null}
              onChange={(time, timeString) => setTime(time.$d)}
              className=" w-full  placeholder-gray-300  outline-none text-black border rounded-md border-gray-200 "
              placeholder="Heure de la tache"
            />
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Commentaire"
            />
          </div>
        )}

        {taskType === "Appel" && (
          <div className="flex flex-col space-y-1">
            <DatePicker
              value={date ? dayjs(date) : null}
              onChange={(date, dateString) => setDate(date.$d)}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Date de l'appel"
              locale={locale}
              format={"DD/MM/YYYY"}
            />
            <DesktopTimePicker
              format={"HH:mm"}
              value={time ? dayjs(time) : null}
              onChange={(time, timeString) => setTime(time.$d)}
              className="w-full  placeholder-gray-300 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Heure de l'appel"
            />
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Commentaire"
            />
          </div>
        )}

        {taskType === "Deplacement" && (
          <div className="flex flex-col space-y-1">
            <DatePicker
              value={date ? dayjs(date) : null}
              onChange={(date, dateString) => setDate(date.$d)}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Date du deplacement"
              locale={locale}
              format={"DD/MM/YYYY"}
            />
            <DesktopTimePicker
              format={"HH:mm"}
              value={time ? dayjs(time) : null}
              onChange={(time, timeString) => setTime(time.$d)}
              className="w-full  placeholder-gray-300 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Heure du deplacement"
            />
            <input
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Lieu du deplacement"
            />
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Commentaire"
            />
          </div>
        )}

        {taskType === "Autre" && (
          <div className="flex flex-col space-y-1">
            <DatePicker
              value={date ? dayjs(date) : null}
              onChange={(date, dateString) => setDate(date.$d)}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Date"
              locale={locale}
              format={"DD/MM/YYYY"}
            />
            <DesktopTimePicker
              format={"HH:mm"}
              value={time ? dayjs(time) : null}
              onChange={(time, timeString) => setTime(time.$d)}
              className="w-full  placeholder-gray-300 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Heure"
            />

            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              placeholder="Commentaire"
            />
          </div>
        )}

        {/*   <Select
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Client"
              showSearch="true"
              optionFilterProp="children"
              onChange={(e) => setClient(e)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={clients}
            />  */}

        <Input
          className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
          placeholder="Client"
          onChange={(e) => searchClient(e.target.value)}
        />
        {selectedClients.length > 0 && (
          <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
            {selectedClients.map((client) => {
              return (
                <p
                  onClick={() => setSelectedClient(client)}
                  className={`border-b flex items-center space-x-1 capitalize font-semibold border-b-gray-300 p-2 ${
                    selectedClient?._id === client._id ? "bg-purple-400" : ""
                  }`}
                >
                  <span>{client.companyName}</span>
                </p>
              );
            })}
          </div>
        )}

        <Input
          className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
          placeholder="Candidat"
          onChange={(e) => searchCandidate(e.target.value)}
        />
        {selectedCandidates.length > 0 && (
          <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
            {selectedCandidates.map((candidate) => {
              return (
                <p
                  onClick={() => setSelectedCandidate(candidate)}
                  className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                    selectedCandidate?._id === candidate._id
                      ? "bg-green-400"
                      : ""
                  }`}
                >
                  <span>{candidate.firstname} -</span>
                  <span>{candidate.lastname}-</span>
                  <span>{candidate.email} -</span>
                  <span>{candidate.phone}</span>
                </p>
              );
            })}
          </div>
        )}
        <Select
          className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
          placeholder="Attribuée à"
          optionFilterProp="children"
          onChange={(e) => setTaker(e)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={takers}
        />
        <button
          onClick={createTask}
          className="rounded-md p-2 bg-purple-500 w-2/4 mx-auto text-black mt-10 uppercase font-semibold"
        >
          Créer la tache
        </button>
      </div>
    </Modal>
  );
};

export default CreateTask;
