import DownloadingIcon from "@mui/icons-material/Downloading";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import cgvClient from "../../assets/img/cgv-client.jpg";
import myImage from "../../assets/img/logopdf.png";
import logotoulouse from "../../assets/img/logotoulouse.png";
import logobrive from "../../assets/img/logobrive.png";
import gmail from "../../assets/img/gmail.png";
import { message } from "antd";
import phone from "../../assets/img/phone.png";
import axios from "../../axios/AxiosInstance";
import { useEffect, useState } from "react";
const DevisSection = ({ client, createdQuotation }) => {
  const [quotations, setQuotations] = useState([]);
  const [originalQuotations, setOriginalQuotations] = useState([]);

  useEffect(() => {
    loadQuotations(client._id);
  }, [createdQuotation]);

  const formatJsDate2 = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${month}${year}`;
  };

  const formatJsDate = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  function splitStringWithoutCuttingWords(inputString, chunkSize) {
    const words = inputString.split(" ");
    const resultChunks = [];
    let currentChunk = "";

    for (const word of words) {
      if (currentChunk.length + word.length <= chunkSize) {
        currentChunk += (currentChunk ? " " : "") + word;
      } else {
        resultChunks.push(currentChunk);
        currentChunk = word;
      }
    }

    if (currentChunk) {
      resultChunks.push(currentChunk);
    }

    return resultChunks;
  }

  const loadQuotations = (clientId) => {
    axios
      .get(`quotation/client/${clientId}`)
      .then((res) => {
        const quotations = res.data.sort(
          (a, b) => b.quotationNumber - a.quotationNumber,
        );
        setQuotations(quotations);
        setOriginalQuotations(quotations);
        console.log(res.data);
      })
      .catch((e) => {
        message.error("erreur");
      });
  };

  const generatePDF = (quotation) => {
    console.log(quotation);

    const pdf = new jsPDF({
      unit: "mm",
      format: [210, 297],
    });

    const img = new Image();
    if (quotation.agency.name === "CAPENEO") {
      img.src = logotoulouse;
      pdf.addImage(img, "PNG", 14, 10, 40, 24);
    } else if (quotation.agency.name === "J'M CAPENEO") {
      img.src = logobrive;
      pdf.addImage(img, "PNG", 24, 14, 32, 24);
    } else {
      img.src = myImage;
      pdf.addImage(img, "PNG", 14, 10, 40, 24);
    }

    const gmailImg = new Image();
    gmailImg.src = gmail;

    const phoneImg = new Image();
    phoneImg.src = phone;

    pdf.setFontSize(14);
    pdf.setFont("Helvetica", "Bold");
    pdf.text(quotation.agency.name, 24, 45);
    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    pdf.text(quotation.agency.address, 15, 50);
    pdf.text(quotation.agency.postCode + " " + quotation.agency.town, 15, 55);
    pdf.text("SIRET  : " + quotation.agency.siret, 15, 60);
    pdf.text("TVA n° : " + quotation.agency.tva, 15, 65);
    pdf.addImage(gmailImg, "PNG", 15, 67, 3, 3);

    pdf.text(" : " + quotation.agency.email, 18, 69.5);
    pdf.addImage(phoneImg, "PNG", 15, 71.5, 3, 3);
    pdf.text(" : " + quotation.agency.phone, 18, 74);
    pdf.text(
      "Contact : " +
        quotation.creator.firstname +
        " " +
        quotation.creator.lastname,
      15,
      79.5,
    );

    pdf.setFontSize(14);
    pdf.setFont("Helvetica", "Bold");
    if (quotation.quotationNumber) {
      pdf.text(
        "Devis n° " +
          formatJsDate2(quotation.createdAt) +
          "-" +
          quotation.quotationNumber.toString().padStart(5, 0),
        110,
        45,
      );
    } else {
      pdf.text("Devis provisoirdddde", 110, 45);
    }

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");

    pdf.text("Date : ", 110, 50);
    pdf.text(formatJsDate(quotation.createdAt), 120, 50);
    pdf.text("Date d'expiration :", 110, 55);
    pdf.text(formatJsDate(quotation.expiryDate), 137, 55);

    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "Bold");
    pdf.text("Adresse de facturation :", 15, 95);

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    var [partieAvant, codePostal, partieApres] = quotation.addressFacturation
      .trim()
      .match(/^(.*?)\b(\d{5})\b(.*)$/)
      .slice(1)
      .map((str) => str.trim());
    try {
      pdf.text(partieAvant, 15, 100);
      pdf.text(codePostal + " " + partieApres, 15, 105);
    } catch (e) {
      console.log(e);
    }

    pdf.setFontSize(12);
    pdf.setFont("Helvetica", "Bold");
    pdf.text(quotation.client.companyName.toUpperCase(), 110, 80);

    pdf.setFontSize(9);
    pdf.setFont("Helvetica", "normal");
    pdf.text(
      `${quotation.contact.split(" ")[1].toUpperCase()} ${quotation.contact
        .split(" ")[2]
        .toUpperCase()}`,

      110.5,
      85,
    );
    pdf.text(partieAvant, 110.5, 90);
    pdf.text(codePostal + " " + partieApres, 110.5, 95);
    pdf.text("SIRET : " + quotation.client.siret, 110.5, 100);
    pdf.text("TVA : " + quotation.client.TVA, 110.5, 105);

    pdf.setFontSize(12);
    pdf.setFont("Helvetica", "bold");
    pdf.setTextColor(9, 132, 227);
    pdf.setDrawColor(9, 132, 227);

    if (quotation.products[0].product === "Placement" || quotation.products[0].product === "Vacation") {
      pdf.roundedRect(26, 115, 160, 30, 1, 1);
      pdf.text(quotation.products[0].product, 95, 120);
      pdf.line(95, 122, 122, 122);

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);

      if(quotation.products[0].product==="Placement"){
        pdf.text(
          "Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD ou CDI.",
          46,
          130,
        );
      }else{
        pdf.text(
          "Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD.",
          46,
          130,
        );
      }
      pdf.text(
        "Le contrat, la DPAE et la fiche de paie sont établis par vos soins. Vous êtes l'employeur.",
        46,
        135,
      );
      if(quotation.products[0].product === "Placement")
      pdf.text("Vous recevrez une seule facture pour le placement", 57, 140);
    else
      pdf.text("Vous recevrez une seule facture pour la vacation", 57, 140);
    }

    if (
      quotation.products[0].product === "Délégation" ||
      quotation.products[0].product === "Gestion"
    ) {
      pdf.roundedRect(15, 110, 92, 35, 1, 1);
      pdf.roundedRect(110, 110, 92, 35, 1, 1);
      pdf.text("DELEGATION", 47, 115);
      pdf.line(47, 117, 75, 117);
      pdf.text("GESTION", 145, 115);
      pdf.line(145, 117, 164, 117);

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);

      pdf.text("Vous nous mandatez pour", 17, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("recruter", 55, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text("   et", 65, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("  gérer", 70, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text(" l'administratif", 80, 125);
      pdf.text("de ", 17, 130);
      pdf.setFont("Helvetica", "bold");
      pdf.text("notre", 21, 130);
      pdf.setFont("Helvetica", "normal");
      pdf.text("recrue. Le contrat, la DPAE et la fiche de paie sont", 30, 130);
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        17,
        135,
      );
      pdf.text(
        "la sécurité du salarié. Vous recevrez une facture mensuelle.",
        17,
        140,
      );

      pdf.text("Vous nous mandatez pour", 112, 125);
      pdf.setFont("Helvetica", "bold");
      pdf.text("gérer", 150, 125);
      pdf.setFont("Helvetica", "normal");
      pdf.text("simplement l'administratif", 159, 125);
      pdf.text("de", 112, 130);
      pdf.setFont("Helvetica", "bold");
      pdf.text("votre", 116, 130);
      pdf.setFont("Helvetica", "normal");
      pdf.text(
        "recrue. Le contrat, la DPAE et la fiche de paie sont",
        125,
        130,
      );
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        112,
        135,
      );
      pdf.text(
        "la sécurité du salarié. Vous recevrez une facture mensuelle.",
        112,
        140,
      );
    }

    pdf.setTextColor(0, 0, 0);

    pdf.setFillColor(9, 132, 227);
    pdf.setDrawColor(9, 132, 227);
    pdf.rect(15, 155, 180, 10, "F");

    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "bold");

    if (
      quotation.products[0].product === "Délégation" ||
      quotation.products[0].product === "Gestion"
    ) {
      let height = 170;

      pdf.text("Designation", 25, 161);
      pdf.text("Taux horaire", 60, 161);
      pdf.text("Coefficient", 95, 161);
      pdf.text("Prix HT/heure", 130, 159);
      pdf.text("travaillée", 135, 163);
      pdf.text("Observation", 165, 161);

      pdf.setFontSize(10);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setDrawColor(0, 0, 0);

      quotation.products.map((product, idx) => {
        if (product.type === "Prime")
          pdf.text("Prime : " + product.product, 20, height);
        else if (product.type === "Indemnité")
          pdf.text("Indemnité : " + product.product, 20, height);
        else if (product.type === "Autre")
          pdf.text(product.product, 20, height);
        else {
          pdf.text(product.product + " H/F", 20, height);
          const poste = splitStringWithoutCuttingWords(product.poste, 24);
          for (let i = 0; i < poste.length; i++) {
            pdf.text(poste[i], 20, height + 5);
            height += 5;
          }
        }
        pdf.text(parseFloat(product.taux).toFixed(2) + "€", 65, height);
        pdf.text(product.coefficient, 105, height);
        pdf.text(
          (parseFloat(product.taux) * parseFloat(product.coefficient)).toFixed(
            2,
          ) + "€",
          140,
          height,
        );
        pdf.text(product.comment, 165, height);
        height += 5;
        pdf.setTextColor(128, 128, 128);
        pdf.setDrawColor(128, 128, 128);
        if (product.minimumHours) {
          pdf.text(
            "Nombre d'heures minimales avant embauche sans facturation : " +
              product.minimumHours +
              "h",
            50,
            height,
          );
          height += 3;
        }

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineWidth(0.5);
        }
        pdf.line(15, height, 195, height);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(0, 0, 0);
        height += 7;
        if (height > 260) {
          pdf.addPage();
          height = 10;
        }
      });

      height += 10;
      if (height > 250) {
        pdf.addPage();
        height = 10;
      }

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");

      pdf.text("Nom et qualité du signataire :", 15, height);
      pdf.text("Date : " + formatJsDate(new Date()), 15, height + 5);

      pdf.text(quotation.client.tvaRate, 120, height);
      pdf.text(
        "Conditions de paiement : " + quotation.client.facturation,
        120,
        height + 5,
      );
      pdf.text("Moyen de règlement : Virement/Chèque/CB", 120, height + 10);
    }

    if (quotation.products[0].product === "Placement" || quotation.products[0].product === "Vacation") {
      let height = 170;

      pdf.text("Designation", 25, 161);
      pdf.text("Quantité", 68, 161);
      pdf.text("PU (HT)", 110, 161);
      pdf.text("Montant (HT)", 160, 161);

      pdf.setFontSize(10);
      pdf.setFont("Helvetica", "normal");
      pdf.setTextColor(0, 0, 0);
      pdf.setDrawColor(0, 0, 0);

      quotation.products.map((product, idx) => {
        pdf.text(product.product + " H/F", 20, height);
        pdf.text(product.poste, 20, height + 5);
        height += 5;
        pdf.text(parseFloat(product.taux).toFixed(2) + "€", 110, height);
        pdf.text(product.coefficient, 75, height);
        pdf.text(
          (parseFloat(product.taux) * parseFloat(product.coefficient)).toFixed(
            2,
          ) + "€",
          175,
          height,
        );
        height += 5;
        pdf.setTextColor(128, 128, 128);
        pdf.setDrawColor(128, 128, 128);

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineWidth(0.5);
        }
        pdf.line(15, height, 195, height);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(0, 0, 0);
        height += 7;
        if (height > 260) {
          pdf.addPage();
          height = 10;
        }
      });

      if (height > 250) {
        pdf.addPage();
        height = 10;
      }

      pdf.setFillColor(135, 177, 255);
      pdf.setDrawColor(9, 132, 227);
      height += 6;
      pdf.setTextColor(255, 255, 255);
      pdf.setFont("Helvetica", "Bold");

      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Helvetica", "normal");

      pdf.text("Total HT", 110, height);
      pdf.text(
        quotation.products
          .reduce((total, item) => total + parseFloat(item.taux), 0)
          .toFixed(2) + " €",
        174,
        height,
      );

      height += 10;

      pdf.text(
        (
          quotation.products.reduce(
            (total, item) => total + parseFloat(item.taux),
            0,
          ) * 0.2
        ).toFixed(2) + " €",
        174,
        height,
      );
      height += 10;
      pdf.setFillColor(9, 132, 227);
      pdf.rect(105, height - 7, 90, 10, "F");
      pdf.setTextColor(255, 255, 255);
      pdf.text("Total TTC", 110, height);
      pdf.text(
        (
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ) * 0.2,
          ) +
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ),
          )
        ).toFixed(2) + " €",
        174,
        height,
      );

      pdf.setTextColor(0, 0, 0);

      height += 10;

      if (height >= 270) pdf.addPage();

      pdf.setFontSize(9);
      pdf.setFont("Helvetica", "normal");

      /* let hauteur=35
          for(let x of comment2){
            pdf.text(x,18,height)
            height+=5
          }
          pdf.text(
            'Conditions de paiement : '+quotation.products[0].comment,
            18,
            height,
          )
          pdf.text('Moyens de règlement : Virement/Chèque/CB', 18, height+5)
          pdf.text(
            'Coordonnées bancaires Asnières : FR76 1005 7190 0300 0208 2410146 - CMCIFRPP',
            18,
            height+10,
          )
          pdf.text(
            'Coordonnées bancaires Brive / Toulouse : FR76 1005 7190 0300 0207 4100 156 - CMCIFRPP',
            18,
            height+15,
          )
          pdf.text('Nom et qualité du signataire :', 18, height+20)
          pdf.text('Date : ' + formatJsDate(new Date()), 18,  height+25) */

      if (height > 280) {
        pdf.addPage();
        height = 10;
      }

     
      const paymentCondition = splitStringWithoutCuttingWords(
        "Conditions de paiement : " + quotation.products[0].comment,
        70,
      );
      
      
      pdf.text("Nom et qualité du signataire :", 15, height);
      pdf.text("Date : " + formatJsDate(new Date()), 15, height + 5);

      pdf.text("Moyen de règlement : Virement/Chèque/CB", 90, height);
      for (let x of paymentCondition) {
        pdf.text(x, 90, height + 5);
        height += 5;
        if (height >= 280) {
          pdf.addPage();
          height = 10;
        }
      }
      if(quotation.products[0].comment2){
        const replaceCondition = splitStringWithoutCuttingWords(
          "Conditions de remplacement : " + quotation.products[0].comment2,
          70,
        );
        for (let x of replaceCondition) {
          pdf.text(x, 90, height + 10);
          height += 10;
          if (height >= 280) {
            pdf.addPage();
            height = 10;
          }
        }
      }
      
    }

    pdf.text(
      `J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély, Hall A, 92600 Asnières sur Seine, France`,
      25,
      280,
    );
    pdf.text(
      "SIRET : 91912551800010 - Code APE : 7820z - N° de TVA : FR52919125518",
      50,
      285,
    );

    pdf.addPage();
    pdf.addImage(cgvClient, "PNG", 10, 10, 198, 282);

    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between  mb-6 mx-10 text-xs">
        <p className="w-1/6 text-center">NUMERO DE DEVIS</p>
        <p className="w-1/6 text-center">CLIENT</p>
        <p className="w-1/6 text-center">POSTE</p>
        <p className="w-1/6 text-center">COEFFICIENT</p>
        <p className="w-1/6 text-center">TAUX HORAIRE</p>
        <p className="w-1/6 text-center">STATUT</p>
      </div>
      <div className="flex flex-col space-y-2">
        {quotations.map((quotation, idx) => {
          return (
            <div
              key={idx}
              className=" py-4 flex items-center drop-shadow-2xl text-sm justify-between mx-10 bg-white border border-gray-200 rounded-md p-4 hover:scale-105 hover:cursor-pointer transition-all duration-300"
            >
              <div className="w-1/6">
                {quotation.isDraft && (
                  <p className="flex justify-center">
                    <span className="bg-gray-200 px-2 py-1 rounded-md text-center">
                      Brouillon
                    </span>
                  </p>
                )}
                {!quotation.isDraft && (
                  <p className="text-center">
                    {quotation.quotationNumber.toString().padStart(5, "0")}
                  </p>
                )}
              </div>

              <div className="w-1/6">
                <p className="font-bold text-center">
                  {quotation.client.companyName}
                </p>
              </div>

              <div className="w-1/6">
                {quotation.products.map((product, idx) => {
                  return (
                    <>
                      <div key={idx}>
                        {product.type === "Prime" && (
                          <p className="font-bold text-center">
                            Prime : {product.product}{" "}
                          </p>
                        )}
                      </div>

                      <div>
                        {product.type !== "Prime" && (
                          <p className="font-bold text-center">
                            {product.poste}{" "}
                          </p>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="w-1/6">
                {quotation.products.map((product, idx) => {
                  return (
                    <p key={idx} className="font-bold text-center">
                      {product.coefficient}
                    </p>
                  );
                })}
              </div>

              <div className="w-1/6">
                {quotation.products.map((product, idx) => {
                  return (
                    <p key={idx} className="font-bold text-center">
                      {product.taux}
                    </p>
                  );
                })}
              </div>

              <div className="w-1/6 flex justify-center items-center ">
                <p className="flex items-center space-x-2">
                  <SearchIcon
                    onClick={() => generatePDF(quotation)}
                    className="hover:text-green-500"
                  />
                  <DownloadingIcon className="hover:text-green-500" />
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DevisSection;
